import * as yup from "yup";

export const PersonalDetailsSchema = () => {
    const schema = yup.object().shape({
        name: yup.string().required(),
        workEmail: yup.string().email().required("Work email is a required field"),
        contactNumber: yup.string().required("Contect number is a required field"),
        dob: yup.string().required(),
    });
    return schema;
}