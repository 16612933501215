import Translate from "../../../translation/utils/translateFunction";

const OWNER = Translate("filter:ownerLabel"),
  PA = Translate("filter:personalAssistantLabel"),
  GM = Translate("filter:generalManagerLabel"),
  MANAGER = Translate("filter:managerLabel"),
  EMPLOYEE = Translate("filter:employeeLabel"),
  VENDOR = Translate("filter:vendorLabel");

export const roleType = {
  OWNER,
  PA,
  GM,
  MANAGER,
  EMPLOYEE,
  VENDOR,
};

export const multiSelectRoles = ["OWNER", "GM"];
