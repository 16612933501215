import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from "../Authentication/404";
import InternalServer from "../Authentication/500";
import ForgotPassword from "../Authentication/forgotpassword";
import Login from "../Authentication/login";
import SignUp from "../Authentication/signup";
// import TermsCondition from "../TermsCondition";
// import Privacy from "../Privacy";

const UnAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/" element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/notfound" element={<NotFound />} />
      <Route path="/internalserver" element={<InternalServer />} />
      <Route path="*" element={<Navigate to="/" replace />} />
      {/* <Route path="/termsConditions" element={<TermsCondition />} /> */}
      {/* <Route path="/privacy" element={<Privacy />} /> */}
    </Routes>
  );
};
export default UnAuthRoutes;
