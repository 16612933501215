import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";

export const AddClientSchema = () => {
  const schema = yup.object().shape({
    name: yup.string().trim().required(Translate("client:nameError")),
    designation: yup
      .string()
      .trim()
      .required(Translate("client:designationError")),
    mobile: yup.string().trim().required(Translate("client:mobileError")),
    email: yup
      .string()
      .email(Translate("client:emailErrorInvalid"))
      .trim()
      .required(Translate("client:emailError")),
    resAddress: yup.string().trim().required(Translate("client:addressError")),
    companyName: yup
      .string()
      .trim()
      .required(Translate("client:companyNameError")),
    website: yup.string().trim().required(Translate("client:websiteError")),
    domainUrl: yup.string().when("domains", {
      is: (domains) => !domains || domains.length === 0,
      then: yup.string().required(Translate("client:domainError")),
      otherwise: yup.string().notRequired(),
    }),
    domains: yup
      .array()
      .of(yup.string())
      .required(Translate("client:domainError")),
    companyAddress: yup
      .string()
      .trim()
      .required(Translate("client:companyAddressError")),
    startDate: yup.string().trim().required(Translate("client:startDateError")),
    subscriptionType: yup
      .string()
      .trim()
      .required(Translate("subscription:typeError")),
  });

  return schema;
};

export const validateAddressFields = (param) => {
  switch (param) {
    case "residenceAddress.address":
      return "resAddress";
    case "residenceAddress.country":
      return "resCountry";
    case "residenceAddress.state":
      return "resState";
    case "residenceAddress.zipCode":
      return "resZipCode";
    case "companyDetails.officeAddress.address":
      return "companyAddress";
    case "companyDetails.officeAddress.country":
      return "companyCountry";
    case "companyDetails.officeAddress.state":
      return "companyState";
    case "companyDetails.officeAddress.zipCode":
      return "companyZipCode";
    default:
      return param;
  }
};

export const AddClientDetailSchema = () => {
  const schema = yup.object().shape({
    accessKey: yup.string().trim().required(Translate("client:accesskeyError")),
    bucketName: yup
      .string()
      .trim()
      .required(Translate("client:bucketNameError")),
    region: yup.string().trim().required(Translate("client:regionError")),
    secretAccessKey: yup
      .string()
      .trim()
      .required(Translate("client:secretAccessKeyError")),
    dbName: yup.string().trim().required(Translate("client:dbNameError")),
    userName: yup.string().trim().required(Translate("client:dbUserNameError")),
    password: yup.string().trim().required(Translate("client:dbPasswordError")),
    port: yup.string().trim().required(Translate("client:dbPortError")),
    loginDb: yup.string().trim().required(Translate("client:dbLoginError")),
    uri: yup.string().trim().required(Translate("client:dbUriError")),
  });

  return schema;
};

export const validateClientDetailFields = (param) => {
  if (param.startsWith("s3Details") || param.startsWith("dbDetails")) {
    const field = param.split(".");

    return field[1];
  }

  return param;
};

export const UserImportSchema = (showCompanies) => {
  let schema = yup.object().shape({
    role: yup.string().trim().required(Translate("client:roleError")),
  });

  if (showCompanies) {
    schema = schema.shape({
      companies: yup
        .array()
        .required(Translate("client:companyError"))
        .min(1, Translate("common:selectCompanyMessage")),
    });
  } else {
    schema = schema.shape({
      companyId: yup.string().trim().required(Translate("client:companyError")),
    });
  }

  return schema;
};
