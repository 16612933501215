import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../assets/images/Location.svg";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import { FormikPhoneInput } from "../../FormikForm/component/FormikPhoneInput";
import { FormikPhoneInputWithExtField } from "../../FormikForm/component/FormikPhoneInputWithExt";
import FormikDatePicker from "../../FormikForm/component/FormikDatePicker";
import FormikAddTagInput from "../../FormikForm/component/FormikAddTagInput";
import Loader from "../../staff/Owner/LoadingComp";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import { useGetSubscriptionTypesMutation } from "../../../request/Client";
import { showToast } from "../../../common/Utils/ToastMessage";

const ClientForm = ({
  isAdd,
  isView,
  formikRef,
  values,
  setFieldValue,
  isLoading,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  const isRegAddressDisabled = true;
  const isCompanyAddressDisabled = true;

  const { validations } = useSelector((state) => state?.formanagement);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);

  const [
    getSubscriptionTypes,
    {
      data: subscriptionTypeData,
      isSuccess: isSubscriptionSuccess,
      isLoading: isSubscriptionLoading,
      isError: isSubscriptionError,
      error: subscriptionError,
    },
  ] = useGetSubscriptionTypesMutation();

  useEffect(() => {
    getSubscriptionTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSubscriptionError && subscriptionError) {
      showToast({
        message:
          subscriptionError?.data?.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }

    if (isSubscriptionSuccess && subscriptionTypeData?.data) {
      const subscriptionOptions = subscriptionTypeData?.data?.map((item) => ({
        label: `${item?.name} - ${item?.noOfDays} ${
          item?.noOfDays === 1 ? "day" : "days"
        }`,
        value: item?._id,
      }));
      setSubscriptionTypes(subscriptionOptions);
    }
  }, [
    subscriptionTypeData,
    isSubscriptionSuccess,
    isSubscriptionError,
    subscriptionError,
  ]);

  const currentLocationData = (places, addressType) => {
    if (addressType) {
      formikRef.current.setFieldValue("resAddress", places.formatted_address);
      formikRef.current.setFieldValue("resLat", places.geometry.location.lat());
      formikRef.current.setFieldValue("resLng", places.geometry.location.lng());

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("resCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("resState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("resCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("resZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("resZipCode", "");
        }
        return "";
      });
    } else {
      formikRef.current.setFieldValue(
        "companyAddress",
        places.formatted_address
      );
      formikRef.current.setFieldValue(
        "companyLat",
        places.geometry.location.lat()
      );
      formikRef.current.setFieldValue(
        "companyLng",
        places.geometry.location.lng()
      );

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("companyCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("companyState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("companyCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("companyZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("companyZipCode", "");
        }
        return "";
      });
    }
  };

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <div className="page-title formTitle p-3 row align-items-center">
            <i
              className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
              onClick={() => {
                navigate("/client");
              }}
              data-toggle="tooltip"
              title={Translate("common:backToolTip")}
            />
            {isAdd
              ? Translate("client:addClientHeading")
              : Translate("client:viewClientHeading")}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="name"
                  label={Translate("client:name")}
                  placeholder={Translate("client:name")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={isView}
                  required={isAdd}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="designation"
                  label={Translate("client:designation")}
                  placeholder={Translate("client:designation")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={isView}
                  required={isAdd}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="email"
                  label={Translate("client:email")}
                  placeholder={Translate("client:emailPlaceholder")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={isView}
                  required={isAdd}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12" id="paContact">
                {isAdd ? (
                  <FormikPhoneInput
                    label={Translate("client:contactNumber")}
                    name="mobile"
                    value={values.mobile}
                    onChange={(value, country) => {
                      setFieldValue("mobile", value);
                      setFieldValue(
                        "mobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue("countryCode", country?.dialCode);
                    }}
                    placeholder={Translate("client:contactNumberPlaceHolder")}
                    maxLength={validations?.contactPhoneLength.MAX}
                    shortCodeChange={(item) =>
                      setFieldValue("mobileShortCode", item)
                    }
                    currentCountryCode={values?.mobileShortCode}
                    disabled={isView}
                    required={isAdd}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="mobile"
                    value={values.mobile}
                    label={Translate("client:contactNumber")}
                    placeholder={Translate("client:contactNumberPlaceHolder")}
                    autoComplete={"off"}
                    maxLength={validations?.contactPhoneLength.MAX}
                    disabled={true}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography
                    variant="subtitle1"
                    className="labelStyling"
                  >
                    {Translate("client:address")}
                    {isAdd && <span style={{ color: "red" }}>{" *"}</span>}
                  </StyledTypography>
                </div>
                <FormikTextField
                  name="resAddress"
                  placeholder={Translate("client:addressPlaceholder_1")}
                  autoComplete={"off"}
                  isLocation={true}
                  RenderRightContainer={!isView && <LocationIcon />}
                  locationValue={(currentLocation) => {
                    currentLocationData(currentLocation, true);
                  }}
                  maxLength={validations?.address.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="resFlat"
                  placeholder={Translate("client:addressPlaceholder_2")}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resCountry"
                  placeholder={Translate("client:countryPlaceholder")}
                  disabled={isRegAddressDisabled}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resState"
                  placeholder={Translate("client:statePlaceholder")}
                  disabled={isRegAddressDisabled}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resCity"
                  placeholder={Translate("client:cityPlaceholder")}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="resZipCode"
                  placeholder={Translate("client:zipcodePlaceholder")}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <StyledTypography sx={{ fontSize: "18px" }}>
                  {Translate("client:subscriptionDetails")}
                </StyledTypography>
                <div className="boxLine"></div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikDropdownPicker
                  options={subscriptionTypes}
                  value={values?.subscriptionType}
                  name="subscriptionType"
                  label={Translate("subscription:type")}
                  placeholder={Translate("subscription:typePlaceholder")}
                  disabled={isView}
                  required={isAdd}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikDatePicker
                  label={Translate("client:startDateLabel")}
                  containerStyle="datePickerContainer"
                  className="form-control bw-0"
                  format="yyyy/MM/dd"
                  name="startDate"
                  minDate={isAdd ? new Date() : null}
                  value={values.startDate}
                  onChange={(value) => {
                    setFieldValue("startDate", value);
                  }}
                  disabled={isView}
                  required={isAdd}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <StyledTypography sx={{ fontSize: "18px" }}>
                  {Translate("client:companyDetails")}
                </StyledTypography>
                <div className="boxLine"></div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="companyName"
                  label={Translate("client:companyName")}
                  placeholder={Translate("client:companyName")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={isView}
                  required={isAdd}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                {isAdd ? (
                  <FormikPhoneInput
                    label={Translate("client:hrContact")}
                    name="hrMobile"
                    value={values.hrMobile}
                    onChange={(value, country) => {
                      setFieldValue("hrMobile", value);
                      setFieldValue(
                        "hrMobileShortCode",
                        country?.countryCode?.toUpperCase()
                      );
                      setFieldValue("hrMobileCountryCode", country?.dialCode);
                    }}
                    placeholder={Translate("client:hrContactPlaceholder")}
                    maxLength={validations?.contactPhoneLength.MAX}
                    shortCodeChange={(item) =>
                      setFieldValue("hrMobileShortCode", item)
                    }
                    currentCountryCode={values?.hrMobileShortCode}
                    disabled={isView}
                    isLogin
                  />
                ) : (
                  <FormikTextField
                    name="hrMobile"
                    value={values.hrMobile}
                    label={Translate("client:hrContact")}
                    placeholder={Translate("client:hrContactPlaceholder")}
                    autoComplete={"off"}
                    maxLength={validations?.contactPhoneLength.MAX}
                    disabled={true}
                  />
                )}
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikPhoneInputWithExtField
                  name="companyNumber"
                  extName="companyExtension"
                  label={Translate("client:companyExtensionNumber")}
                  placeholder={Translate("client:extensionNumberPlaceholder")}
                  extPlaceholder={Translate(
                    "client:companyPhoneExtensionPlaceholder"
                  )}
                  autoComplete={"off"}
                  maxLength={validations?.telephoneLength.MAX}
                  extMaxLength={validations?.companyExtension.MAX}
                  disabled={isView}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="website"
                  label={Translate("client:URL")}
                  placeholder={Translate("client:URL")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={isView}
                  required={isAdd}
                />
              </div>
              <div className="form-group col-md-8 col-sm-12">
                {isView ? (
                  <FormikTextField
                    name="domainUrl"
                    label={Translate("client:companyDomains")}
                    placeholder={Translate("client:companyDomains")}
                    autoComplete={"off"}
                    disabled
                    title={values.domainUrl}
                  />
                ) : (
                  <FormikAddTagInput
                    name="domainUrl"
                    label={Translate("client:companyDomains")}
                    placeholder={Translate("client:companyDomains")}
                    autoComplete={"off"}
                    onAdd={(domains) => {
                      setFieldValue("domains", domains);
                    }}
                    maxLength={validations?.nameLength.MAX}
                    disabled={isView}
                    required={isAdd}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <div className="AddressLabel">
                  <StyledTypography
                    variant="subtitle1"
                    className="labelStyling"
                  >
                    {Translate("client:companyAddress")}
                    {isAdd && <span style={{ color: "red" }}>{" *"}</span>}
                  </StyledTypography>
                </div>
                <FormikTextField
                  name="companyAddress"
                  placeholder={Translate("client:companyAddress")}
                  autoComplete={"off"}
                  isLocation={true}
                  RenderRightContainer={
                    !isView && <LocationIcon className="formikLocationIcon" />
                  }
                  locationValue={(currentLocation) => {
                    currentLocationData(currentLocation, false);
                  }}
                  maxLength={validations?.address.MAX}
                  disabled={isView}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                <FormikTextField
                  name="companyFlat"
                  placeholder={Translate("client:landmarkPlaceholder")}
                  autoComplete={"off"}
                  maxLength={validations?.landmarkLimit.MAX}
                  disabled={isView}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="companyCountry"
                  disabled={isCompanyAddressDisabled}
                  placeholder={Translate("client:countryPlaceholder")}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="companyState"
                  disabled={isCompanyAddressDisabled}
                  placeholder={Translate("client:statePlaceholder")}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="companyCity"
                  placeholder={Translate("client:cityPlaceholder")}
                  maxLength={validations?.cityLimit.MAX}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-8">
                <FormikTextField
                  name="companyZipCode"
                  placeholder={Translate("client:zipcodePlaceholder")}
                  disabled={isView}
                />
              </div>
            </div>

            {!isView && (
              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={() => handleSubmit()}
                >
                  {Translate("common:save")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader loading={isLoading || isSubscriptionLoading} />
    </>
  );
};

export default memo(ClientForm);
