export default {
  dashboard: 'Dashboard',
  contactRepository: 'Contact repository',
  documentRepository: 'Document repository',
  report: 'Report',
  taskReport: 'Task report',
  performanceReport: 'Performance report',
  workloadReport: 'Workload report',
  staffReport: 'Staff report',
  vendorsSuppliers: 'Vendors/suppliers',
  aboutUs: 'About us',
  privacy: 'Privacy',
  termsConditions: 'Terms & condition',
  logout: 'Logout',
  staff: 'Staff',
  owner: 'Owner',
  manager: 'Manager',
  employee: 'Employee',
  vendor: 'Vendor',
  attendanceReport: 'Attendance report',
  voiceNote: 'Voice note',
  activityLogs: 'Activity logs',
  renewals: 'Renewals',
  organizationStructure: 'Organization Structure',
  companyStructure: 'Company Structure',
  reportingStructure: 'Reporting Structure',
};
