export const InitialValues = {
  email: "",
  password: "",
  phoneNumber: "",
};

export const ChangePassInitialValues = {
  oldPass: "",
  newPass: "",
  confirmPass: "",
};

export const termsAndPrivacyLink = {
  terms: `${process.env.REACT_APP_UAT_URL}/terms.html`,
  privacy: `${process.env.REACT_APP_UAT_URL}/privacy.html`,
}

export const passwordMaxLength = 15;