import { useFormikContext } from "formik";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../assets/images/Location.svg";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { showToast } from "../../../common/Utils/ToastMessage";
import { useGetCompanyUrlQuery } from "../../../request/company";
import Translate from "../../../translation/utils/translateFunction";
import Dropzone from "../../CommonFileUpload/Dropzone";
import { FormikDropdownPicker } from "../../FormikForm/component/FormikDropDownPicker";
import { FormikNumberField } from "../../FormikForm/component/FormikNumberField";
import { FormikPhoneInput } from "../../FormikForm/component/FormikPhoneInput";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import CommonImage from "../../ImageGallery/CommonImage";
import Loader from "../../staff/Owner/LoadingComp";

const CompanyForm = ({
  isSharedDocument,
  isEdit,
  isView,
  filesAdded,
  setfilesAdded,
  setImages,
  images,
  isLoading,
  values,
  handleSubmit,
  setDeletedAttachments,
  companyDataState,

  onAddMore,
}) => {
  const navigate = useNavigate();

  const { validations } = useSelector((state) => state?.formanagement);
  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg", ".webp", ".heic"],
  };

  const [companyurl, setCompanyUrl] = useState([]);
  const { setFieldValue } = useFormikContext();
  const { data: companyUrl } = useGetCompanyUrlQuery();
  useEffect(() => {
    if (companyUrl && !isEdit) {
      let companyData = [];
      companyUrl.map((item) =>
        companyData.push({
          label: item,
          value: item,
        })
      );
      setCompanyUrl(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUrl]);

  useEffect(() => {
    if (companyDataState) {
      if (companyDataState?.viewCompanyData?.website?.length) {
        let companyData = [companyDataState?.viewCompanyData?.website];

        let dropDownData = companyData?.map((item) => ({
          label: item,
          value: item,
        }));
        setCompanyUrl(...companyurl, dropDownData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDataState]);

  const addFiles = (file) => {
    const { type } = file;
    const fileTypes = ["image/png", "image/png", "image/jpeg"];

    const url = fileTypes.includes(type) ? URL.createObjectURL(file) : null;

    if (url) file.preview = url;

    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
      const newImages = filesAdded.map((imageElement) => imageElement.preview);
      setImages(newImages);
    } else {
      showToast({ message: Translate("document:fileLimit"), type: "error" });
    }
  };

  const removeFile = (file) => {
    const deletedFiles = filesAdded.filter(
      (fileName) => fileName.name !== file.name
    );
    if (isEdit) {
      setDeletedAttachments(file);
    }
    setfilesAdded(deletedFiles);
  };
  const address = true;

  const currentLocationData = (places, addressType) => {
    if (addressType) {
      setFieldValue("country", "");
      setFieldValue("state", "");
      setFieldValue("city", "");
      setFieldValue("address", places.formatted_address);
      setFieldValue("lat", places.geometry.location.lat());
      setFieldValue("lng", places.geometry.location.lng());

      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          setFieldValue("country", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          setFieldValue("state", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          setFieldValue("city", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          setFieldValue("zipcode", ele.long_name);
        } else {
          setFieldValue("zipcode", "");
        }
        return null;
      });
    }
  };
  return (
    <div className="row container_spacing">
      <div className="card card_spacing">
        <div className="page-title formTitle p-3 row align-items-center">
          {isEdit && (
            <i
              className="icon fe fe-arrow-left mr-3  icon-size backnavigation-iconColor cursor-pointer"
              style={{ marginLeft: 10 }}
              onClick={() => navigate(-1)}
              title="icon fe fa-arrow-down-left"
            />
          )}

          {isEdit
            ? Translate("addCompany:editCompany")
            : Translate("addCompany:addCompanyHeading")}
        </div>
        <div className="card-body">
          <div className="row">
            <div className="form-group col-md-4 col-sm 12">
              <FormikTextField
                name="name"
                label={Translate("addCompany:name")}
                placeholder={Translate("addCompany:name")}
                autoComplete={"off"}
                maxLength={validations?.companyNameLimit.MAX}
                required
              />
            </div>

            <div className="form-group col-md-4 col-sm 12">
              <FormikDropdownPicker
                options={companyurl}
                name="website"
                value={
                  companyDataState?.viewCompanyData?.website
                    ? companyurl[0]?.value
                    : values.website
                }
                label={Translate("addCompany:URL")}
                placeholder={Translate("addCompany:URL")}
                onSelect={(value) => {}}
                disabled={isEdit}
                required
              />
            </div>
            <div className="form-group col-md-4 col-sm 12">
              <FormikPhoneInput
                name="contact"
                value={values.contact}
                onChange={(value) => setFieldValue("contact", value)}
                onCountryChange={(country) =>
                  setFieldValue("countryCode", country.slice(1))
                }
                label={Translate("addCompany:contact")}
                placeholder={Translate("addCompany:contactPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.contactPhoneLength.MAX}
                className={"form-control textFieldHeight"}
                shortCodeChange={(item) =>
                  setFieldValue("mobileShortCode", item)
                }
                currentCountryCode={values?.mobileShortCode}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4 col-sm 12">
              <FormikNumberField
                name="officeTelephone"
                label={Translate("addCompany:officeTelephone")}
                placeholder={Translate("addCompany:officeTelephonePlaceHolder")}
                autoComplete={"off"}
                maxLength={validations?.telephoneLimit.MAX}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12 col-sm-12">
              <div className="AddressLabel">
                <StyledTypography variant="subtitle1">
                  {Translate("addCompany:address")}
                  <StyledTypography sx={{ color: "red" }} component="span">
                    {" *"}
                  </StyledTypography>
                </StyledTypography>
              </div>
              <FormikTextField
                name="address"
                placeholder={Translate("addCompany:address")}
                autoComplete={"off"}
                isLocation={true}
                RenderRightContainer={<LocationIcon />}
                locationValue={(currentLocation) => {
                  currentLocationData(currentLocation, true);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12 col-sm-12">
              <FormikTextField
                name="landmark"
                placeholder={Translate("addCompany:landmarkPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.landmarkLimit.MAX}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4 col-sm-12">
              <FormikTextField
                name="country"
                placeholder={Translate("addCompany:country")}
                autoComplete={"off"}
                disabled={address}
              />
            </div>

            <div className="form-group col-md-4 col-sm 12">
              <FormikTextField
                name="state"
                placeholder={Translate("addCompany:state")}
                autoComplete={"off"}
                disabled={address}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4 col-sm-12">
              <FormikTextField
                name="city"
                placeholder={Translate("addCompany:city")}
                autoComplete={"off"}
                maxLength={validations?.cityLimit.MAX}
              />
            </div>

            <div className="form-group col-md-4 col-sm 12">
              <FormikTextField
                name="zipcode"
                placeholder={Translate("addCompany:zipcode")}
                autoComplete={"off"}
                maxLength={6}
              />
            </div>
          </div>
          <div className="row imageDisplay">
            <div className="form-group col-md-4 col-sm 8">
              <Dropzone
                translate={Translate("addCompany:addLogo")}
                onfileSelect={(filesArray) => addFiles(filesArray)}
                acceptedImages={acceptedFileType}
                maximumFiles={1}
                fileLength={filesAdded.length}
              />
            </div>
            <div style={{ paddingLeft: 7.5 }}>
              {filesAdded && filesAdded.length > 0 ? (
                <CommonImage
                  images={images}
                  files={filesAdded}
                  removeFile={removeFile}
                  isCompany={true}
                />
              ) : null}
            </div>
          </div>
          <div className="card-footer text-right line_spacing">
            <button
              type="submit"
              className="btn btn-primary saveButton"
              onClick={() => {
                handleSubmit();
              }}
            >
              {Translate("addCompany:save")}
            </button>
            {!isEdit && (
              <button
                type="submit"
                className="btn btn-white addButton"
                onClick={() => {
                  onAddMore(true);
                  handleSubmit();
                }}
              >
                {Translate("addCompany:addMore")}
              </button>
            )}
          </div>
        </div>
        <Loader loading={isLoading} />
      </div>
    </div>
  );
};

export default memo(CompanyForm);
