import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  StyledCard,
  StyledTypography,
} from "../../common/Utils/StyledComponents";
import Translate from "../../translation/utils/translateFunction";
import ListView from "../ListView";
import {
  useGetClientsMutation,
  useImportCompanyDataMutation,
  useImportUserDataApiMutation,
} from "../../request/Client";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../common/Theme/Colors";
import { getClientColumns } from "./Components/column.data";
import IconifyIcon from "../Icon";
import ImportData from "../ImportData";
import { showToast } from "../../common/Utils/ToastMessage";
import Searchbar from "../common/Searchbar";

const ClientListing = ({ fixNavbar }) => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);
  const [showCompanyImport, setShowCompanyImport] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [showUserImport, setShowUserImport] = useState(false);

  const [
    getClientsApi,
    {
      data: clientData,
      isLoading: isClientLoading,
      isError: isClientError,
      error: clientError,
    },
  ] = useGetClientsMutation();

  const [
    importUserDataApi,
    {
      data: importedUserData,
      isSuccess: isImportUserSuccess,
      isLoading: isImportUserLoading,
      isError: isImportUserError,
      error: importUserError,
    },
  ] = useImportUserDataApiMutation();

  const [
    importCompanyDataApi,
    {
      data: importedCompanyData,
      isSuccess: isImportCompanySuccess,
      isLoading: isImportCompanyLoading,
      isError: isImportCompanyError,
      error: importCompanyError,
    },
  ] = useImportCompanyDataMutation();

  const requestBody = useMemo(() => {
    const payload = {
      searchValue,
      pageNo: currentPage,
    };

    return payload;
  }, [searchValue, currentPage]);

  const debouncedSearch = useDebouncedCallback(() => {
    getClientsApi(requestBody);
  }, 500);

  useEffect(() => {
    setClients([]);

    if (requestBody) {
      setIsLoading(true);

      if (requestBody?.searchValue?.length) {
        debouncedSearch();
      } else {
        getClientsApi(requestBody);
      }
    }
    // eslint-disable-next-line
  }, [requestBody]);

  useEffect(() => {
    if (clientData?.data?.nodes) {
      const { nodes, pageInfo } = clientData.data;
      setClients(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if ((isClientError, clientError)) {
      showToast({
        message:
          clientError?.data?.message || Translate("common:unknownServerError"),
        type: "error",
      });
      setIsLoading(false);
    }
  }, [clientData, isClientError, clientError]);

  useEffect(() => {
    if (isImportCompanySuccess && importedCompanyData) {
      showToast({
        message: importedCompanyData?.message,
        type: "success",
      });
    }

    if (isImportCompanyError && importCompanyError) {
      showToast({
        message:
          importCompanyError?.data?.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
  }, [
    importedCompanyData,
    isImportCompanySuccess,
    isImportCompanyError,
    importCompanyError,
  ]);

  useEffect(() => {
    if (isImportUserSuccess && importedUserData) {
      showToast({
        message: importedUserData?.message,
        type: "success",
      });
      setSelectedClient("");
    }

    if (isImportUserError && importUserError) {
      showToast({
        message:
          importUserError?.data?.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }

    if (importedUserData?.success === "false") {
      showToast({ message: importedUserData?.message, type: "error" });
    }
  }, [
    importedUserData,
    isImportUserSuccess,
    isImportUserError,
    importUserError,
  ]);

  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearchValue(value);
  };

  const closeCompanyImport = () => setShowCompanyImport(false);

  const uploadCompanyData = (file) => {
    importCompanyDataApi({ webAttachment: file, clientId: selectedClient });
    setSelectedClient("");
  };

  const closeUserImport = () => setShowUserImport(false);

  const uploadUserData = (file, companyId, userRole) => {
    importUserDataApi({
      webAttachment: file,
      clientId: selectedClient,
      companyId,
      userRole,
    });
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    const { hasClientDetails, clientId } = params?.row;

    return (
      <div className="headerMargin clientListMenu">
        <a
          href="/#"
          className="nav-link dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v manageIconColor" />
        </a>
        <div className="dropdown-menu dropdownAlignment">
          {!hasClientDetails && (
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() =>
                navigate("/client/details/add", {
                  state: clientId,
                })
              }
            >
              <IconifyIcon
                className="m-2"
                icon="mdi:database-add"
                color={colors?.primary}
                width="19"
              />
              {Translate("client:addClientDetailsToolTip")}
            </div>
          )}
          <div
            className="manageIconColor ml-3 cursor-pointer dropdown-item"
            onClick={() => {
              setShowCompanyImport(true);
            }}
          >
            <IconifyIcon
              className="m-2"
              icon="material-symbols:domain-add"
              color={colors?.primary}
              width="19"
            />
            {Translate("client:importCompanyToolTip")}
          </div>
          <div
            className="manageIconColor ml-3 cursor-pointer dropdown-item"
            onClick={() => {
              setShowUserImport(true);
            }}
          >
            <IconifyIcon
              className="m-2"
              icon="mdi:user-arrow-right-outline"
              color={colors?.primary}
              width="19"
            />
            {Translate("client:userImportData")}
          </div>
          <div
            className="manageIconColor ml-3 cursor-pointer dropdown-item"
            onClick={() => {
              navigate("/client/view", {
                state: clientId,
              });
            }}
          >
            <IconifyIcon
              className="m-2"
              icon="vaadin:eye"
              color={colors?.primary}
              width="19"
            />
            {Translate("client:viewClientHeading")}
          </div>
          {hasClientDetails && (
            <div
              className="manageIconColor ml-3 cursor-pointer dropdown-item"
              onClick={() => {
                navigate("/client/details/view", {
                  state: clientId,
                });
              }}
            >
              <IconifyIcon
                className="m-2"
                icon="mdi:database-view"
                color={colors?.primary}
                width="19"
              />
              {Translate("client:viewClientDetailHeading")}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Grid
      item
      xs={12}
      className={`section-body ${fixNavbar ? "marginTop" : ""} `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearchValue}
                      />
                    </div>

                    <div className="header-action pull-right ml-2">
                      <button
                        type="button"
                        className="btn btn-primary py-2"
                        onClick={() => navigate("/client/add")}
                      >
                        <i className="fe fe-plus" />
                        <StyledTypography className="mx-1" component="span">
                          {Translate("common:add")}
                        </StyledTypography>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getClientColumns(
                      CustomSrCellRender,
                      CustomActionCellRender
                    )}
                    rows={clients}
                    autoHeight
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={isLoading || isClientLoading}
                    showLoader={
                      isLoading || isImportCompanyLoading || isImportUserLoading
                    }
                    totalPageCount={lastPageNo}
                    onCellClick={(params) => {
                      setSelectedClient(params?.row?._id);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
      {showCompanyImport && (
        <ImportData
          title={Translate("client:importCompanyToolTip")}
          showModal={showCompanyImport}
          closeModal={closeCompanyImport}
          onSubmit={uploadCompanyData}
        />
      )}
      {showUserImport && (
        <ImportData
          title={Translate("client:userImportData")}
          showModal={showUserImport}
          closeModal={closeUserImport}
          onSubmit={uploadUserData}
          selectedClient={selectedClient}
          isCompanyVisible
        />
      )}
    </Grid>
  );
};

export default ClientListing;
