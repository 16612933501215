export const AppFonts = {
  global: "Montserrat, sans-serif",
  bold: "Jost-Bold",
  medium: "Jost-Medium",
  regular: "Jost-Regular",
  semibold: "Jost-SemiBold",
  light: "Jost-Light",
};

export const FontSizes = {
  xxSmall: 10,
  xSmall: 12,
  small: 14,
  regular: 16,
  medium: 18,
  xMedium: 20,
  large: 22,
  xlarge: 28,
  mediumHeader: 42,
  largeHeader: 52,
};

