import {
  countryCode,
  countryShortCode,
} from "../../../common/Utils/ApiConstants";
import { getMoment } from "../../../common/Utils/commonFunction";

export const InitialValues = {
  name: "",
  designation: "",
  mobile: "",
  mobileShortCode: countryShortCode?.JORDAN,
  countryCode: countryCode?.JORDAN,
  email: "",
  resAddress: "",
  resFlat: "",
  resCountry: "",
  resState: "",
  resCity: "",
  resZipCode: "",
  resLat: "",
  resLng: "",
  startDate: new Date(),
  companyName: "",
  website: "",
  domainUrl: "",
  domains: [],
  companyNumber: "",
  companyExtension: "",
  hrMobile: "",
  hrMobileCountryCode: countryCode?.JORDAN,
  hrMobileShortCode: countryShortCode?.JORDAN,
  companyAddress: "",
  companyFlat: "",
  companyLat: "",
  companyLng: "",
  companyCountry: "",
  companyState: "",
  companyCity: "",
  companyZipCode: "",
  subscriptionType: "",
};

export const ClientDetailsInitialValues = {
  bucketName: "",
  region: "",
  accessKey: "",
  secretAccessKey: "",
  dbName: "",
  userName: "",
  password: "",
  port: "",
  loginDb: "",
  uri: "",
};

export const UserImportInitialValues = {
  companyId: "",
  companies: [],
  role: "",
};
