export default {
  changePassword: "Change password",
  oldPass: "Old password",
  newPass: "New password",
  confirmPass: "Confirm password",
  oldPassReq: "Old password is required",
  newPassReq: "New password is required",
  confirmPassReq: "Confirm password is required",
  passNotMatch: "Password does not match",
  passValidationError:
    "Must contain at least 8 Characters, 1 Uppercase, 1 Lowercase, 1 Special Character, and 1 Number",
  save: "Save",
  cancel: "Cancel",
};
