import React from "react";
import { styles } from "../TextField/TextField/index.Style";

import { StyledTypography } from "../../common/Utils/StyledComponents";

const RadioButtons = ({
  radioOptions,
  onPress,
  label,
  isCheckBox,
  defaultCheck = undefined,
  defaultChecked = isCheckBox ? undefined : 0,
  disabled = false,
  name,
  required = false,
  icons,
}) => {
  return (
    <>
      {label !== undefined && label?.length > 0 && (
        <StyledTypography sx={styles.label} component="span">
          {label}
          {required && (
            <StyledTypography sx={{ color: "red" }} component="span">
              {" *"}
            </StyledTypography>
          )}
        </StyledTypography>
      )}
      {/* {(
        <div>
          <span style={styles.label}>{label}</span>
        </div>
      )} */}

      <div>
        {radioOptions.map((radioItems, index) => (
          <label
            className={
              isCheckBox
                ? "custom-control custom-checkbox"
                : "custom-control custom-radio"
            }
            key={`${radioItems}_${index}`}
          >
            {isCheckBox ? (
              <input
                type="checkbox"
                className="custom-control-input"
                /* name="example-checkbox1" */
                name={name || "example-checkbox1"}
                defaultValue={radioItems}
                // defaultChecked={}
                checked={
                  defaultCheck !== undefined
                    ? defaultCheck
                    : defaultChecked === index
                }
                onClick={onPress}
                disabled={disabled}
              />
            ) : (
              <input
                type="radio"
                className="custom-control-input"
                /* name="example-radios" */
                name={name || "example-radios"}
                defaultValue={radioItems}
                defaultChecked={defaultChecked === index}
                onChange={onPress}
                disabled={disabled}
              />
            )}
            {icons ? (
              <div className="custom-control-label custom-radioButton">
                <i className={icons[index] || ""} /> {radioItems}
              </div>
            ) : (
              <div className="custom-control-label">{radioItems}</div>
            )}
          </label>
        ))}
      </div>
    </>
  );
};

export default RadioButtons;
