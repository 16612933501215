export default {
  name: "Client name",
  designation: "Designation",
  contactNumber: "Contact number",
  contactNumberPlaceHolder: "0000 0000 00",
  email: "Email",
  emailPlaceholder: "Email address",
  startDateLabel: "Start date",
  companyName: "Company name",
  hrContact: "HR contact",
  hrContactPlaceholder: "0000 0000 00",
  companyExtensionNumber: "Company phone extension number",
  extensionNumberPlaceholder: "0000 0000 00",
  companyPhoneExtensionPlaceholder: "000",
  address: "Residence address",
  companyAddress: "Company address",
  addressPlaceholder_1: "House/flat/block no.",
  addressPlaceholder_2: "Apartment/road/area",
  addressPlaceholder_3: "Flat/block no.",
  addressPlaceholder_4: "Building/road/area",
  countryPlaceholder: "Country",
  statePlaceholder: "State/region",
  cityPlaceholder: "City",
  zipcodePlaceholder: "Zip code ",
  landmarkPlaceholder: "Landmark",
  companyDomains: "Company domains",
  companyDetails: "Company details",
  nameError: "Client name is required",
  designationError: "Designation is required",
  mobileError: "Contact number is required",
  mobileInvalidError: "Contact number is invalid",
  emailErrorInvalid: "Email is not valid",
  emailError: "Email is required",
  addressError: "Residence address is required",
  companyNameError: "Company name is required",
  companyAddressError: "Company address is required",
  websiteError: "Website is required",
  websiteInvalidError: "Website must be a valid url",
  domainError: "Domain is required",
  domainInvalidError: "Domain must be a valid url",
  addClientHeading: "Add client",
  clientCodeTitle: "Client code",
  nameTitle: "Name",
  designationTitle: "Designation",
  contactTitle: "Contact no.",
  companyNameTitle: "Company name",
  emailTitle: "Email",
  addClientDetailHeading: "Add client details",
  s3DetailsTitle: "S3 details",
  accessKey: "Access key",
  accesskeyError: "Access key is required",
  bucketName: "Bucket name",
  bucketNameError: "Bucket name is required",
  region: "Region",
  regionError: "Region is required",
  secretAccessKey: "Secret access key",
  secretAccessKeyError: "Secret access key is required",
  dbDetailsTitle: "DB details",
  dbName: "DB name",
  dbNameError: "DB name is required",
  dbNamePlaceholder: "DB name",
  dbUserName: "User name",
  dbUserNameError: "User name is required",
  dbPassword: "Password",
  dbPasswordError: "Password is required",
  dbPort: "Port",
  dbPortError: "Port is required",
  dbLogin: "Login DB",
  dbLoginError: "Login DB is required",
  dbUri: "URI",
  dbUriError: "URI is required",
  clientIdInvalidError: "Cliend Id is invalid",
  addClientDetailsToolTip: "Add Client details",
  importCompanyToolTip: "Import company",
  importCompanyDataTitle: "Import company data",
  userImportData: "Import user",
  selectCompany: "Select company",
  dropdownPlaceholder: "Select company",
  companyError: "Company is required",
  viewClientHeading: "View client",
  viewClientDetailHeading: "View client details",
  URL: "Company URL",
  selectRole: "Select role",
  roleError: "Role is required",
  subscriptionDetails: "Subscription details"
};
