import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyCAuvo_l-egs78Y2DEvuH0_LwEiepXEUnk",
  authDomain: "for-management.firebaseapp.com",
  projectId: "for-management",
  storageBucket: "for-management.appspot.com",
  messagingSenderId: "341120959278",
  appId: "1:341120959278:web:d00fd15afcbb43371ef0bf",
  measurementId: "G-BSYWHCYMX0",
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const _getToken = (setTokenFound, setFcmToken) => {
  return getToken(messaging, {
    vapidKey:
      "BIJojsFxHdP1wCuq2wP3HZ05pr2Dm6g-ZeUFxPXAFPo5-OpNlkVQ0ZYWdkSdcj7YPqL2aFyFo7CI3GjXvhTlfOc",
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        setTokenFound(true);
        setFcmToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        // console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

initializeApp(firebaseConfig);
