import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const companycollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanyList: builder.query({
      query: () => ({
        url: Urls.getCompanyList,
        method: Methods.get,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getCompanyUrl: builder.query({
      query: () => ({
        url: Urls.getCompanyUrl,
        method: Methods.get,
      }),
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getCompanyData: builder.mutation({
      query: (body) => ({
        url: Urls.getCompanyData,
        method: Methods.post,
        body: body,
      }),
    }),
  }),
});

export const {
  useLazyGetCompanyListQuery,
  useGetCompanyUrlQuery,
  useGetCompanyDataMutation,
} = companycollection;
