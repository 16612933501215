import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { colors } from "../../common/Theme/Colors";
import NoDataFound from "../ManageTask/NoDataFound";
import Translate from "../../translation/utils/translateFunction";
const Columnchart = ({
  optionsList,
  legends,
  selectedFilter,
  seriesData,
  optionsData,
  strokeWidth,
  barWidth,
  graphHeight,
  xValues,
}) => {
  const [options, setOptions] = useState();
  const [series, setSeries] = useState();
 
  useEffect(() => {
    setSeries(seriesData);
    setOptions({
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: true,
          tools: {
            download: false, // <== line to add
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: barWidth,
          endingShape: "rounded",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return parseFloat(val).toFixed(2) + "%";
        },
        legend: {
          markers: {
            fillColors: legends
              ? Object.values(legends)
              : ["#7372B8", "#D7D7D7"],
          },
        },
        stroke: {
          show: true,
          width: 55,
          colors: ["transparent"],
        },
      },
      offsetX: 2,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: [colors.black_002],
      },
      legend: {
        markers: {
          fillColors: legends ? Object.values(legends) : ["#7372B8", "#D7D7D7"],
        },
        itemMargin: { vertical: 30 },
      },
      stroke: {
        show: true,
        width: strokeWidth,
        colors: ["transparent"],
      },

      xaxis: {
        categories: xValues ? xValues : [""],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: (val) => {
            return parseFloat(val).toFixed(2) + "%";
          },
        },
      },
      fill: {
        opacity: 1,
        colors: legends ? Object.values(legends) : ["#7372B8", "#D7D7D7"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " %";
          },
        },
      },
    });
  }, [
    selectedFilter,
    optionsList,
    optionsData,
    seriesData,
    legends,
    strokeWidth,
    barWidth,
    xValues,
  ]);

  return (
    <div>
      {options &&
        series &&
        (optionsList?.length ? (
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={graphHeight ? graphHeight : "auto"}
            />
          </div>
        ) : (
          <NoDataFound message={Translate("dashboard:noDataMsg")} isCenter />
        ))}
    </div>
  );
};

export default Columnchart;
