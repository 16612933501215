import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const MasterDataCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getMasterData: builder.query({
      query: () => ({
        url: Urls.masterDefault,
        method: Methods.get,
      }),
    }),
    getAllTimezoneCollection: builder.query({
      query: () => ({
        url: Urls.timeZone,
        method: Methods.get,
      }),
    }),
  }),
});
export const { useLazyGetMasterDataQuery, useGetAllTimezoneCollectionQuery } =
  MasterDataCollection;
