import React, { useEffect, useRef, useState } from "react";
import ClientForm from "../Components/ClientForm";
import { InitialValues } from "../Components/constants";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  AddClientSchema,
  validateAddressFields,
} from "../Components/validators";
import { useAddClientMutation } from "../../../request/Client";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";
import { countryCode } from "../../../common/Utils/ApiConstants";
import moment from "moment";
import { DateTimeFormats } from "../../../common/Utils/DateFormater";

const AddClient = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const [
    addClientApi,
    { data: clientData, isSuccess: isAddSuccess, isLoading, isError, error },
  ] = useAddClientMutation();

  useEffect(() => {
    if (isError && error) {
      const clientError = error?.data;

      if (!clientError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        clientError?.error?.length
          ? clientError.error.map((errorItem) => {
              if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                const tempParam = validateAddressFields(errorItem?.param);

                if (tempParam) {
                  return formikRef.current.setFieldError(
                    tempParam,
                    errorItem?.msg
                  );
                }

                return showToast({ message: errorItem?.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem?.param,
                errorItem?.msg
              );
            })
          : showToast({
              message:
                clientError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }

    if (isAddSuccess && clientData) {
      if (clientData?.success) {
        showToast({ message: clientData?.message, type: "success" });
        formikRef.current.resetForm();
        navigate("/client");
      } else {
        showToast({ message: clientData?.message, type: "error" });
      }
    }
    // eslint-disable-next-line
  }, [isAddSuccess, clientData, isError, error]);

  const onSubmit = (formValues) => {
    const { domains, hrMobile, hrMobileCountryCode } = formValues;

    if (!domains?.length) {
      return formikRef.current.setFieldError(
        "domainUrl",
        Translate("client:domainError")
      );
    }

    const selectedTime = moment(formValues?.startDate).utc();
    const startDateUtc = selectedTime.format(
      DateTimeFormats?.tzFormatWithoutSec
    );

    const reqBody = {
      name: formValues.name,
      designation: formValues.designation,
      countryCode: formValues?.countryCode || countryCode?.JORDAN,
      mobile: formValues?.mobile?.split("-")[1],
      mobileShortCode: formValues.mobileShortCode,
      email: formValues.email,
      residenceAddress: {
        latitude: formValues.resLat,
        longitude: formValues.resLng,
        latlong: {
          latitude: formValues.resLat,
          longitude: formValues.resLng,
        },
        flat: formValues.resFlat,
        address: formValues.resAddress,
        country: formValues.resCountry,
        state: formValues.resState,
        city: formValues.resCity,
        zipCode: formValues.resZipCode,
      },
      companyDetails: {
        companyName: formValues?.companyName,
        website: formValues?.website,
        hrMobileCountryCode: hrMobile
          ? hrMobileCountryCode
            ? hrMobileCountryCode
            : countryCode?.JORDAN
          : "",
        hrMobile: hrMobile ? hrMobile?.split("-")[1] : "",
        companyExtension: formValues.companyExtension,
        companyNumber: formValues.companyNumber,
        officeAddress: {
          latitude: formValues.companyLat,
          longitude: formValues.companyLng,
          latlong: {
            latitude: formValues.companyLat,
            longitude: formValues.companyLng,
          },
          flat: formValues.companyFlat,
          address: formValues.companyAddress,
          country: formValues.companyCountry,
          state: formValues.companyState,
          city: formValues.companyCity,
          zipcode: formValues.companyZipCode,
        },
        domains: formValues?.domains,
        totalCompanies: formValues?.domains?.length,
      },
      mobileVerified: "YES",
      emailVerified: "YES",
      timezone: "Asia/Kolkata",
      startDate: startDateUtc,
      subscriptionId: formValues?.subscriptionType,
    };

    addClientApi(reqBody);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddClientSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <ClientForm
            isAdd
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      }}
    </Formik>
  );
};

export default AddClient;
