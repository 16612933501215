import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // validations: undefined,
  isDarkMode: false,
  isDarkHeader: false,
  isFixNavbar: false,
  isMinSidebar: false,
  isDarkSidebar: false,
  isIconColor: false,
  isGradientColor: false,
  isRtl: false,
  isFont: "font-montserrat",
  isSubMenuIcon: "list-a",
  isMenuIcon: "list-c",
  isBoxLayout: false,
  isStatistics: true,
  isFriendList: true,
  isbox: true,
  isbox2: true,
  isbox3: true,
  isbox4: true,
  isbox5: true,
  isbox6: true,
  isbox7: true,
  isbox8: true,
  isbox9: true,
  isbox10: true,
  isBoxClose: true,
  isBox2Close: true,
  isBox3Close: true,
  isBox4Close: true,
  isBox5Close: true,
  isBox6Close: true,
  isBox7Close: true,
  isBox8Close: true,
  isBox9Close: true,
  isBox10Close: true,
  isStatisticsClose: true,
  isFriendListClose: true,
  isToggleLeftMenu: false,
  isLoggedIn: false,
  userData: undefined,
  userType: undefined,
  validations: [],
  selectedCompanyList: [],
  fcmToken: "",
  activeChatId: undefined,
  eventRecurrence: undefined,
  taskStatusColor: [],
};

export const ForManagementSlice = createSlice({
  name: "formanagement",
  initialState,
  reducers: {
    userDataAction: (state, action) => {
      state.userData = action.payload;
    },
    setValidations: (state, action) => {
      state.validations = action.payload;
    },
    darkModeAction: (state, action) => {
      state.isDarkMode = action.payload;
    },
    darkHeaderAction: (state, action) => {
      state.isDarkHeader = action.payload;
    },
    fixNavbarAction: (state, action) => {
      state.isFixNavbar = action.payload;
    },
    darkMinSidebarAction: (state, action) => {
      state.isMinSidebar = action.payload;
    },
    darkSidebarAction: (state, action) => {
      state.isDarkSidebar = action.payload;
    },
    iconColorAction: (state, action) => {
      state.isIconColor = action.payload;
    },
    gradientColorAction: (state, action) => {
      state.isGradientColor = action.payload;
    },
    rtlAction: (state, action) => {
      state.isRtl = action.payload;
    },
    fontAction: (state, action) => {
      state.isFont = action.payload;
    },
    subMenuIconAction: (state, action) => {
      state.isSubMenuIcon = action.payload;
    },
    menuIconAction: (state, action) => {
      state.isMenuIcon = action.payload;
    },
    boxLayoutAction: (state, action) => {
      state.isBoxLayout = action.payload;
    },
    statisticsAction: (state, action) => {
      state.isStatistics = action.payload;
    },
    friendListAction: (state, action) => {
      state.isFriendList = action.payload;
    },
    statisticsCloseAction: (state, action) => {
      state.isStatisticsClose = action.payload;
    },
    accountCloseAction: (state, action) => {
      state.isFriendListClose = action.payload;
    },
    friendListCloseAction: (state, action) => {
      state.isFriendListClose = action.payload;
    },
    toggleLeftMenuAction: (state, action) => {
      state.isToggleLeftMenu = action.payload;
    },
    boxAction: (state, action) => {
      state.isbox = action.payload;
    },
    box2Action: (state, action) => {
      state.isbox2 = action.payload;
    },
    box3Action: (state, action) => {
      state.isbox3 = action.payload;
    },
    box4Action: (state, action) => {
      state.isbox4 = action.payload;
    },
    box5Action: (state, action) => {
      state.isbox5 = action.payload;
    },
    box6Action: (state, action) => {
      state.isbox6 = action.payload;
    },
    box7Action: (state, action) => {
      state.isbox7 = action.payload;
    },
    box8Action: (state, action) => {
      state.isbox8 = action.payload;
    },
    box9Action: (state, action) => {
      state.isbox9 = action.payload;
    },
    box10Action: (state, action) => {
      state.isbox10 = action.payload;
    },
    boxCloseAction: (state, action) => {
      state.isBoxClose = action.payload;
    },
    box2CloseAction: (state, action) => {
      state.isBox2Close = action.payload;
    },
    box3CloseAction: (state, action) => {
      state.isBox3Close = action.payload;
    },
    box4CloseAction: (state, action) => {
      state.isBox4Close = action.payload;
    },
    box5CloseAction: (state, action) => {
      state.isBox5Close = action.payload;
    },
    box6CloseAction: (state, action) => {
      state.isBox6Close = action.payload;
    },
    box7CloseAction: (state, action) => {
      state.isBox7Close = action.payload;
    },
    box8CloseAction: (state, action) => {
      state.isBox8Close = action.payload;
    },
    box9CloseAction: (state, action) => {
      state.isBox9Close = action.payload;
    },
    box10CloseAction: (state, action) => {
      state.isBox10Close = action.payload;
    },
    logIn_OutAction: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    userTypeAction: (state, action) => {
      state.userType = action.payload;
    },
    masterDataAction: (state, action) => {
      state.validations = action.payload;
    },
    selectCompanyAction: (state, action) => {
      state.selectedCompanyList = action.payload;
    },
    setFcmTokenAction: (state, action) => {
      state.fcmToken = action.payload;
    },
    setActiveChatIdAction: (state, action) => {
      state.activeChatId = action.payload;
    },
    setEventRecurrence: (state, action) => {
      state.eventRecurrence = action.payload;
    },
    setTaskStatusColorAction: (state, action) => {
      state.taskStatusColor = action.payload;
    },
  },
});

export const {
  setValidations,
  darkModeAction,
  darkHeaderAction,
  fixNavbarAction,
  darkMinSidebarAction,
  darkSidebarAction,
  iconColorAction,
  gradientColorAction,
  rtlAction,
  fontAction,
  subMenuIconAction,
  menuIconAction,
  boxLayoutAction,
  statisticsAction,
  friendListAction,
  statisticsCloseAction,
  accountCloseAction,
  friendListCloseAction,
  box10Action,
  box10CloseAction,
  box2Action,
  box2CloseAction,
  box3Action,
  box3CloseAction,
  box4Action,
  box4CloseAction,
  box5Action,
  box5CloseAction,
  box6Action,
  box6CloseAction,
  box7Action,
  box7CloseAction,
  box8Action,
  box8CloseAction,
  box9Action,
  box9CloseAction,
  boxAction,
  boxCloseAction,
  toggleLeftMenuAction,
  logIn_OutAction,
  userDataAction,
  userTypeAction,
  masterDataAction,
  selectCompanyAction,
  setFcmTokenAction,
  setActiveChatIdAction,
  setEventRecurrence,
  setTaskStatusColorAction,
} = ForManagementSlice.actions;
export default ForManagementSlice.reducer;
