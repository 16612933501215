// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { userDataAction, userTypeAction } from "../../store/Reducer";

// Define a service using a base URL and expected endpoints

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  // baseUrl: "",
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.formanagement?.userData?.accessToken;
    const timezone = getState()?.formanagement?.userData?.login?.timezone;
    // const token = "token";
    if (token && timezone) {
      headers.append("token", token);
      headers.append("Content-Type", "application/json");
      headers.append("timezone", timezone);
    }
    return headers;
  },
  // credentials: "include",
});

// const errorStatusArray = [
//   "Task is rejected, can not perform operation",
//   "Task is accepted, can not perform operation",
//   "Task is inprogress, can not perform operation",
//   "Task is awaitingapproval, can not perform operation",
//   "Task is completed, can not perform operation",
//   "Task is reopened, can not perform operation",
//   "Task name is invalid!",
// ];

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error && result.error.status === 401) {
    if (result.error.data.error.name === "TokenExpiredError") {
      // send refresh token to get new access token
      const refreshResult = await baseQuery(
        {
          url: Urls.refreshToken,
          method: Methods.post,
          body: {
            token: api.getState().formanagement.userData?.refreshToken,
            // token: "token",
            loginFor: "WEB",
            device: {
              deviceId: "6797279a44145cf2",
              osType: "WEB",
              osVersion: "",
              deviceUniqueId: "",
              deviceBrand: "",
              deviceModel: "",
              deviceType: "",
              appVersion: "",
              fcmToken: api.getState().formanagement.fcmToken,
            },
          },
        },
        api,
        extraOptions
      );
      if (refreshResult?.data) {
        // const user = api.getState().formanagement.userData;
        // store the new token
        // AsyncStorage.setItem(
        //   STR_KEYS.LOGINUSERDATA,
        //   JSON.stringify({
        //     ...user,
        //     accessToken: refreshResult?.data.data.token,
        //     refreshToken: refreshResult?.data.data.refreshToken,
        //   }),
        // );
        api.dispatch(userDataAction(refreshResult?.data));
        // api.dispatch(
        //   userDataAction({
        //     ...user,
        //     accessToken: refreshResult?.data.data.token,
        //     refreshToken: refreshResult?.data.data.refreshToken,
        //   }),
        // );
        // retry the original query with new access token
        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      // await AsyncStorage.removeItem('UserType');
      api.dispatch(userTypeAction(undefined));
      api.dispatch(userDataAction(undefined));
      // await AsyncStorage.getItem('UserType');
      // AsyncStorage.setItem(STR_KEYS.LOGINUSERDATA, '');
      // api.dispatch(userDataAction(undefined));
      // await AsyncStorage.removeItem(STR_KEYS.USERTOKEN);
      // await AsyncStorage.removeItem(STR_KEYS.USERTYPE);
      // showToast(result.error?.data.error.message);
      // api.dispatch(logOut());
    }
  } else if (result.error && result.error.status === "FETCH_ERROR") {
  } else if (result.error) {
  }

  return result;
};

export const CreateApi = createApi({
  reducerPath: "CreateApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ["profile"],
});
