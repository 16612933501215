import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Translate from "../../../translation/utils/translateFunction";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import Loader from "../../staff/Owner/LoadingComp";

const SubscriptionForm = ({
  isAdd,
  isView,
  isEdit,
  values,
  isLoading,
  handleSubmit,
  subscriptionData,
}) => {
  const navigate = useNavigate();
  const { validations } = useSelector((state) => state?.formanagement);

  const handleTitle = () => {
    if (isView) return Translate("subscription:viewSubscriptionHeading");

    if (isEdit) return Translate("subscription:editSubscriptionHeading");

    return Translate("subscription:addSubscriptionHeading");
  };

  const handleBackNavigation = () => {
    if (isEdit)
      return navigate("/subscription/view", { state: subscriptionData?._id });

    return navigate("/subscription");
  };

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <div className="page-title formTitle p-3 row align-items-center">
            <i
              className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
              onClick={handleBackNavigation}
              data-toggle="tooltip"
              title={Translate("common:backToolTip")}
            />
            {handleTitle()}
            {isView && (
              <div className="card-options">
                <i
                  className="icon-color ml-3 fa fa-pencil-square-o cursor-pointer"
                  onClick={() =>
                    navigate("/subscription/edit", {
                      state: subscriptionData,
                    })
                  }
                  data-toggle="tooltip"
                  title={Translate("common:editToolTip")}
                ></i>
              </div>
            )}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-8 col-sm-12">
                <FormikTextField
                  name="name"
                  label={Translate("subscription:name")}
                  placeholder={Translate("subscription:name")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-8 col-sm-12">
                <FormikTextField
                  name="description"
                  label={Translate("subscription:description")}
                  placeholder={Translate("subscription:descriptionPlaceholder")}
                  autoComplete={"off"}
                  multiline
                  noOfRows={3}
                  maxLength={validations?.taskDescription.MAX}
                  disabled={isView}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  value={values?.noOfDays}
                  name="noOfDays"
                  label={Translate("subscription:noOfDays")}
                  placeholder={Translate("subscription:noOfDays")}
                  type="number"
                  autoComplete={"off"}
                  disabled={isView}
                  required={isAdd || isEdit}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="noOfCompanies"
                  label={Translate("subscription:noOfCompanies")}
                  placeholder={Translate("subscription:noOfCompanies")}
                  autoComplete={"off"}
                  required={isAdd || isEdit}
                  disabled={isView}
                  type="number"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="amount"
                  label={Translate("subscription:amount")}
                  placeholder={Translate("subscription:amount")}
                  autoComplete={"off"}
                  required={isAdd || isEdit}
                  disabled={isView}
                  type="number"
                />
              </div>
            </div>
            {!isView && (
              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={() => handleSubmit()}
                >
                  {Translate("common:save")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader loading={isLoading} />
    </>
  );
};

export default memo(SubscriptionForm);
