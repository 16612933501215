export default {
  name: "Name",
  description: "Description",
  descriptionPlaceholder: "Add description",
  type: "Subscription",
  typePlaceholder: "Select subscription",
  noOfDays: "No of days",
  startDate: "Start date",
  endDate: "End date",
  noOfCompanies: "No of companies",
  amount: "Amount",
  nameError: "Name is required",
  descriptionError: "Description is required",
  typeError: "Subscription is required",
  startDateError: "Start date is required",
  endDateError: "End date is required",
  noOfCompaniesError: "No of companies is required",
  amountError: "Amount is required",
  noOfDaysError: "No of days is required",
  addSubscriptionHeading: "Add subscription",
  viewSubscriptionHeading: "View subscription",
  editSubscriptionHeading: "Edit subscription",
};
