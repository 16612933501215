import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LocationIcon } from "../../../../assets/images/Location.svg";
import {
  formatDateYYYYMMDD,
  formatDiffDuration,
  formatGetMonth,
  formatGetMonthWithValue,
  formatGetTime,
  formatGetTimeWithValue,
  formatGetYearsDiff,
} from "../../../../common/Utils/DateFormater";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { convertToB64 } from "../../../../common/Utils/commonFunction";
import {
  useAddStaffOwnerMutation,
  useDeleteStaffOwnerMutation,
  useEditStaffOwnerMutation,
} from "../../../../request/Staff/Owner";
import { useLazyGetCompanyListQuery } from "../../../../request/company";
import Translate from "../../../../translation/utils/translateFunction";
import AlertPermission from "../../../AlertPermission";
import Dropzone from "../../../CommonFileUpload/Dropzone";
import FormikDatePicker from "../../../FormikForm/component/FormikDatePicker";
import FormikMultiSelectDropDown from "../../../FormikForm/component/FormikMultiSelectDropDown";
import { FormikPhoneInput } from "../../../FormikForm/component/FormikPhoneInput";
import { FormikTextField } from "../../../FormikForm/component/FormikTextField";
import { Persona } from "../../../Persona";
import RadioButtons from "../../../RadioButtons";
import Loader from "../LoadingComp";
import { InitialValues, radioOptions } from "./constant";
import { AddOwnerSchema } from "./utils";

const AddOwner = () => {
  const { validations, userData } = useSelector((state) => state.formanagement);

  const [filesAdded, setfilesAdded] = useState([]);
  const [show, setshow] = useState(false);
  const [images, setImages] = useState([]);
  const [allCompanyData, setAllCompanyData] = useState([]);
  const { state } = useLocation();
  const registerAddress = true;
  const workAddressDisabled = true;
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [addMoreManager, setAddMoreManager] = useState(false);
  const [reportTo, setReportTo] = useState({});
  const initialImage = [state?.profileImageDetails];
  const [showRemoveProfileAlert, setShowRemoveProfileAlert] = useState(false);
  const [base64Img, setBase64Imge] = useState("");

  const [getCompanyList, { data: companyResponse }] =
    useLazyGetCompanyListQuery();
  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (companyResponse && !state) {
      let companyData = [];
      companyResponse.map((item) =>
        companyData.push({
          label: item.name,
          value: `${item._id}`,
        })
      );
      setAllCompanyData(companyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyResponse]);

  const [
    addOwner,
    {
      data: addOwnerData,
      isSuccess: successfullyAddedOwner,
      isError: errorInAddingOwner,
      error: errorOwner,
      isLoading: isAdding,
    },
  ] = useAddStaffOwnerMutation();

  const [
    eidtStaffOwnerApi,
    {
      data: editOwner,
      isSuccess: successfulEditOwner,
      isError: errorInEdit,
      error: errorStaffOwner,
      isLoading: isEditing,
    },
  ] = useEditStaffOwnerMutation();

  const [
    deleteStaffOwnerApi,
    {
      data: deleteStaffOwnerData,
      isSuccess: successfullyDeleteStaffOwner,
      isLoading: isOwnerDeleteLoading,
      isError: isErrorDeleteStaffOwner,
      error: errorDeleteStaffOwner,
    },
  ] = useDeleteStaffOwnerMutation();

  const onDobChange = (value) => {
    const todaysDate = new Date();
    const diff = formatDiffDuration(todaysDate, value);
    const getDate = formatGetTime();
    const getSelectedDate = formatGetTimeWithValue(value);
    const getMonth = formatGetMonth();
    const getSelectedMonth = formatGetMonthWithValue(value);

    const getYearsDiff = formatGetYearsDiff(value);
    if (
      diff >= 18 ||
      (getYearsDiff >= 18 &&
        getDate === getSelectedDate &&
        getMonth === getSelectedMonth)
    ) {
      formikRef.current.setFieldValue("dob", new Date(value));
      InitialValues.dob = formatDateYYYYMMDD(value);
    } else {
      showToast({ message: Translate("addOwner:ageAlert"), type: "error" });
      formikRef.current.setFieldValue("dob", "");
      InitialValues.dob = "";
    }
  };

  useEffect(() => {
    return () => {
      InitialValues.dob = "";
    };
  }, []);

  useEffect(() => {
    if (state) {
      formikRef.current.setFieldValue(
        "companyId",
        state?.company.map((ele) => ele._id)
      );

      formikRef.current.setFieldValue("name", state.name);
      formikRef.current.setFieldValue("alternateMobile", state.alternateMobile);
      formikRef.current.setFieldValue(
        "alternateMobileShortCode",
        state.alternateMobileShortCode ? state?.alternateMobileShortCode : "IN"
      );
      formikRef.current.setFieldValue(
        "alternateMobileCountryCode",
        state?.alternateMobileCountryCode
          ? state?.alternateMobileCountryCode
          : "91"
      );

      formikRef.current.setFieldValue("dob", new Date(state?.dob));
      formikRef.current.setFieldValue("designation", state.designation);
      formikRef.current.setFieldValue("department", state.department);
      formikRef.current.setFieldValue("mobile", state?.login?.mobile);
      formikRef.current.setFieldValue("countryCode", state?.login.countryCode);
      formikRef.current.setFieldValue("companyNumber", state.companyNumber);
      formikRef.current.setFieldValue(
        "companyExtension",
        state.companyExtension
      );
      formikRef.current.setFieldValue("hrMobile", state.hrMobile);
      formikRef.current.setFieldValue(
        "hrMobileCountryCode",

        state?.hrMobileCountryCode ? state?.hrMobileCountryCode : "91"
      );
      formikRef.current.setFieldValue(
        "hrMobileShortCode",
        state.hrMobileShortCode ? state?.hrMobileShortCode : "IN"
      );
      formikRef.current.setFieldValue(
        "mobileShortCode",
        state?.login?.mobileShortCode
      );
      formikRef.current.setFieldValue("email", state?.login?.email);
      formikRef.current.setFieldValue(
        "registerAddress",
        state?.residenceAddress?.address
      );
      formikRef.current.setFieldValue(
        "resApartment",
        state?.residenceAddress?.flat
      );
      formikRef.current.setFieldValue(
        "resCountry",
        state?.residenceAddress?.country
      );
      formikRef.current.setFieldValue(
        "resState",
        state?.residenceAddress?.state
      );
      formikRef.current.setFieldValue("resCity", state?.residenceAddress?.city);
      formikRef.current.setFieldValue(
        "resZipCode",
        state?.residenceAddress?.zipCode
      );
      formikRef.current.setFieldValue(
        "workAddress",
        state?.workAddress?.address
      );
      formikRef.current.setFieldValue(
        "workApartment",
        state?.workAddress?.flat
      );
      formikRef.current.setFieldValue(
        "workCountry",
        state?.workAddress?.country
      );
      formikRef.current.setFieldValue("workState", state?.workAddress?.state);
      formikRef.current.setFieldValue("workCity", state?.workAddress?.city);
      formikRef.current.setFieldValue(
        "workZipCode",
        state?.workAddress?.zipCode
      );
      formikRef.current.setFieldValue("gender", state.gender);

      if (
        state?.profileImageDetails &&
        Object.keys(state?.profileImageDetails).includes("url")
      ) {
        setfilesAdded([state?.profileImageDetails]);
        setImages([state?.profileImageDetails?.url]);
      }
      if (state?.company.length) {
        let companyData = [...state?.company];

        let dropDownData = companyData?.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        setAllCompanyData(dropDownData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (state) {
      const { id, name, role, profileUrl } = state?.reportTo;
      setReportTo({
        id: id,
        name,
        role: role,
        profileUrl,
      });

      InitialValues.reportTo = name;
      formikRef.current.setFieldValue("reportTo", name);
    } else {
      const { _id, name, role, profileUrl } = userData;

      setReportTo({
        id: _id,
        name,
        role: role?.type,
        profileUrl,
      });

      InitialValues.reportTo = name;
      formikRef.current.setFieldValue("reportTo", name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, userData]);

  useEffect(() => {
    if (errorInAddingOwner && errorOwner) {
      errorOwner.data.error
        ? errorOwner.data.error.length > 0 &&
          errorOwner.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({ message: errorOwner?.data?.message, type: "error" });
    }

    if (successfullyAddedOwner && addOwnerData) {
      showToast({ message: addOwnerData.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      if (addMoreManager) {
        formikRef.current.resetForm();
        formikRef.current.setFieldValue("dob", "");
      } else {
        navigate("/ownerListing");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOwnerData, errorOwner]);

  useEffect(() => {
    if (errorStaffOwner && errorInEdit) {
      errorStaffOwner.data.error
        ? errorStaffOwner.data.error.length > 0 &&
          errorStaffOwner.data.error.map((errorItem) =>
            errorItem.param === "residenceAddress.zipCode"
              ? formikRef.current?.setFieldError(
                  "resZipCode",
                  Translate("addOwner:zipcodeError_2")
                )
              : errorItem.param === "workAddress.zipCode"
              ? formikRef.current?.setFieldError(
                  "workZipCode",
                  Translate("addOwner:zipcodeError_2")
                )
              : formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: errorStaffOwner?.data?.message,
            type: "error",
          });
    }

    if (successfulEditOwner && editOwner) {
      showToast({ message: editOwner.message, type: "success" });
      formikRef.current.resetForm();
      setfilesAdded([]);
      setImages([]);
      formikRef.current.resetForm();
      navigate("/ownerListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editOwner, errorInEdit, errorStaffOwner]);

  useEffect(() => {
    if (errorDeleteStaffOwner && isErrorDeleteStaffOwner) {
      errorDeleteStaffOwner.data.error
        ? errorDeleteStaffOwner.data.error.length > 0 &&
          errorDeleteStaffOwner.data.error.map((errorItem) =>
            formikRef.current?.setFieldError(errorItem.param, errorItem.msg)
          )
        : showToast({
            message: errorDeleteStaffOwner?.data?.message,
            type: "error",
          });
    }

    if (successfullyDeleteStaffOwner && deleteStaffOwnerData) {
      showToast({ message: deleteStaffOwnerData.message, type: "success" });
      navigate("/ownerListing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorDeleteStaffOwner, deleteStaffOwnerData]);

  const onSubmit = async (companyFormValues) => {
    let requestObjectEditOwner = {
      companyId: state
        ? state?.company.map((ele) => ele._id)
        : companyFormValues?.companyId,
      name: companyFormValues?.name,

      dob:
        moment(companyFormValues.dob).creationData().format === "YYYY-MM-DD"
          ? companyFormValues.dob
          : formatDateYYYYMMDD(companyFormValues.dob),
      designation: companyFormValues?.designation,
      department: companyFormValues?.department,
      mobile: companyFormValues?.mobile,
      countryCode: companyFormValues?.countryCode,

      mobileShortCode: companyFormValues?.mobileShortCode,

      email: companyFormValues?.email,
      emailVerified: "YES",
      mobileVerified: "YES",
      residenceAddress: {
        latitude: companyFormValues?.resLat,
        longitude: companyFormValues?.resLng,
        latlong: {
          latitude: companyFormValues?.resLat,
          longitude: companyFormValues?.resLng,
        },
        flat: companyFormValues?.resApartment,
        address: companyFormValues?.registerAddress,
        country: companyFormValues?.resCountry,
        state: companyFormValues?.resState,
        city: companyFormValues?.resCity,
        zipCode: companyFormValues?.resZipCode,
      },
      workAddress: {
        latitude: companyFormValues?.workLat,
        longitude: companyFormValues?.workLng,
        latlong: {
          latitude: companyFormValues?.workLat,
          longitude: companyFormValues?.workLng,
        },

        flat: companyFormValues?.workApartment,
        address: companyFormValues?.workAddress,
        country: companyFormValues?.workCountry,
        state: companyFormValues?.workState,
        countryISO: "IN",
        city: companyFormValues?.workCity,
        zipCode: companyFormValues?.workZipCode,
      },
      gender: companyFormValues?.gender?.toUpperCase(),

      role: {
        type: "OWNER",
        roleId: "6316f5116c22f223fd3f759e",
      },
      primary: "NO",
      system: "NO",

      businessCard: {
        type: "",
        fileURL: "",
        desc: "",
      },

      reportTo,
    };

    if (filesAdded.length > 0) {
      if (filesAdded.every((value) => initialImage?.includes(value))) {
        requestObjectEditOwner = {
          ...requestObjectEditOwner,
          profileUrl: state?.profileUrl,
          profileImageDetails: state?.profileImageDetails,
        };
      } else {
        const b64Object = {
          ...filesAdded.map((ele) => ({
            url: ele?.b64,
            type: ele?.type,
            profileFileName: ele?.name,
            profileFileExt: ele?.name.split(".").pop(),
          }))[0],
        };

        requestObjectEditOwner = {
          ...requestObjectEditOwner,
          webProfileUrl: b64Object,
        };
      }
    } else {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,
      };
    }

    if (companyFormValues?.companyNumber?.length > 0) {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,
        companyNumber: companyFormValues.companyNumber,
        companyExtension: companyFormValues?.companyExtension,
      };
    }
    if (companyFormValues?.alternateMobile?.length > 0) {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,
        alternateMobile: companyFormValues?.alternateMobile,
        alternateMobileCountryCode:
          companyFormValues?.alternateMobileCountryCode,
        alternateMobileShortCode: companyFormValues.alternateMobileShortCode,
      };
    }
    if (companyFormValues?.hrMobile?.length > 0) {
      requestObjectEditOwner = {
        ...requestObjectEditOwner,
        hrMobile: companyFormValues?.hrMobile,

        hrMobileCountryCode: companyFormValues?.hrMobileCountryCode,

        hrMobileShortCode: companyFormValues?.hrMobileShortCode,
      };
    }

    if (state) {
      await eidtStaffOwnerApi({
        body: requestObjectEditOwner,
        id: state._id,
      });
    } else {
      await addOwner(requestObjectEditOwner);
    }
  };

  const addFiles = async (file) => {
    if (file) {
      const base64 = await convertToB64(file);
      setBase64Imge(base64);
    }

    const url =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
        ? URL.createObjectURL(file)
        : null;
    if (url) {
      file.preview = url;
    }
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setfilesAdded([...filesAdded]);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (filesAdded.length > 0) {
      const newImages = filesAdded.map((imageElement) =>
        imageElement.preview ? imageElement.preview : imageElement?.url
      );
      setImages(newImages);
    }
  }, [filesAdded]);

  const acceptedFileType = {
    "image/png": [".png", ".jpg", ".jpeg"],
  };

  const removeFile = () => {
    setfilesAdded([]);
    setBase64Imge("");
    onCancelProfile();
  };

  const onCancelProfile = () => setShowRemoveProfileAlert(false);

  const currentLocationData = (places, addressType) => {
    if (addressType) {
      formikRef.current.setFieldValue("resLat", places.geometry.location.lat());
      formikRef.current.setFieldValue("resLng", places.geometry.location.lng());
      formikRef.current.setFieldValue(
        "registerAddress",
        places.formatted_address
      );
      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("resCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("resState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("resCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("resZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("resZipCode", "");
        }
        return null;
      });
    } else {
      formikRef.current.setFieldValue("workAddress", places.formatted_address);
      formikRef.current.setFieldValue(
        "workLat",
        places.geometry.location.lat()
      );
      formikRef.current.setFieldValue(
        "workLng",
        places.geometry.location.lng()
      );
      places.address_components.map((ele) => {
        if (ele.types[0] === "country") {
          formikRef.current.setFieldValue("workCountry", ele.long_name);
        }
        if (ele.types[0] === "administrative_area_level_1") {
          formikRef.current.setFieldValue("workState", ele.long_name);
        }
        if (ele.types[0] === "locality") {
          formikRef.current.setFieldValue("workCity", ele.long_name);
        }
        if (ele.types[0] === "postal_code") {
          formikRef.current.setFieldValue("workZipCode", ele.long_name);
        } else {
          formikRef.current.setFieldValue("workZipCode", "");
        }
        return null;
      });
    }
  };
  const deleteStaffOwner = () => {
    setshow(false);
    deleteStaffOwnerApi(state._id);
  };
  const onCancel = () => setshow(false);

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddOwnerSchema}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <div className="row container_spacing">
            <div className="card card_spacing">
              <div className="page-title formTitle p-3 row align-items-center">
                <i
                  className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
                  onClick={() => {
                    state
                      ? navigate("/viewOwner", { state: state._id })
                      : navigate("/ownerListing");
                  }}
                  data-toggle="tooltip"
                  title={Translate("common:backToolTip")}
                />
                {state
                  ? Translate("addOwner:editOwner")
                  : Translate("homePage:addOwner")}
                {state?.isEditable && (
                  <div className="card-options">
                    <i
                      className="fe fe-trash-2 cursor-pointer"
                      onClick={() => setshow(true)}
                      data-toggle="tooltip"
                      title={Translate("common:deleteToolTip")}
                    ></i>
                  </div>
                )}
              </div>
              <div className="card-body">
                <div className="row imageDisplay mb-2">
                  <div className="form-group col-md-4 col-sm-8">
                    <Persona
                      isProfile={!state}
                      name={state?.name}
                      isBase64={base64Img ? true : false}
                      image={
                        filesAdded?.length && images?.length
                          ? images[0].includes(
                              "https://formanagement.s3.amazonaws.com/"
                            )
                            ? images[0]
                            : base64Img
                          : ""
                      }
                      size={96}
                    />
                    <Dropzone
                      onfileSelect={(filesArray) => addFiles(filesArray)}
                      acceptedImages={acceptedFileType}
                      maximumFiles={1}
                      fileLength={filesAdded.length}
                      maxFileSize={50 * 1000000}
                      className="staffProfileIcon"
                      isProfile
                      deleteAlert={() => {
                        setShowRemoveProfileAlert(true);
                      }}
                      isShowCameraDeleteIcon={filesAdded?.length}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikMultiSelectDropDown
                      options={allCompanyData}
                      name="companyId"
                      label={Translate("addOwner:companyDropdownPlaceholder")}
                      placeholder={Translate(
                        "addOwner:companyDropdownPlaceholder"
                      )}
                      onSelect={(value) => {
                        setFieldValue("companyId", value);
                      }}
                      disabled={state?.isEditable}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="name"
                      label={Translate("addOwner:name")}
                      placeholder={Translate("addOwner:name")}
                      autoComplete={"off"}
                      maxLength={50}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm 12">
                    <FormikDatePicker
                      label={Translate("addOwner:dateOfBirth")}
                      containerStyle="datePickerContainer"
                      className="form-control bw-0 "
                      format="yyyy-MM-dd"
                      name="dob"
                      value={values.dob}
                      onChange={onDobChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="designation"
                      label={Translate("addOwner:designation")}
                      placeholder={Translate("addOwner:designation")}
                      autoComplete={"off"}
                      maxLength={100}
                      required
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="department"
                      label={Translate("addOwner:department")}
                      placeholder={Translate("addOwner:department")}
                      autoComplete={"off"}
                      required
                      maxLength={100}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addOwner:contactNumber")}
                      name="mobile"
                      value={values.mobile}
                      onChange={(value) => setFieldValue("mobile", value)}
                      onCountryChange={(country) => {
                        setFieldValue("countryCode", country.slice(1));
                      }}
                      placeholder={Translate(
                        "addOwner:contactNumberPlaceholder"
                      )}
                      maxLength={validations?.contactPhoneLength.MAX}
                      shortCodeChange={(item) =>
                        setFieldValue("mobileShortCode", item)
                      }
                      currentCountryCode={values?.mobileShortCode}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      name="alternateMobile"
                      value={values.alternateMobile}
                      onChange={(value) =>
                        setFieldValue("alternateMobile", value)
                      }
                      onCountryChange={(country) =>
                        setFieldValue(
                          "alternateMobileCountryCode",
                          country.slice(1)
                        )
                      }
                      label={Translate("addOwner:alternateMobile")}
                      placeholder={Translate(
                        "addOwner:contactNumberPlaceholder"
                      )}
                      autoComplete={"off"}
                      maxLength={validations?.contactPhoneLength.MAX}
                      className={"form-control textFieldHeight"}
                      shortCodeChange={(item) =>
                        setFieldValue("alternateMobileShortCode", item)
                      }
                      currentCountryCode={values?.alternateMobileShortCode}
                    />
                  </div>

                  <div className="form-group col-md-4 col-sm-12 PhoneExtensionInput">
                    <div className="PhoneExtensionInputStyle">
                      <FormikTextField
                        name="companyNumber"
                        label={Translate("addOwner:extensionNumber")}
                        placeholder={Translate(
                          "addOwner:extensionNumberPlaceholder"
                        )}
                        autoComplete={"off"}
                        maxLength={12}
                      />
                    </div>
                    <div className="phoneExtensionStyle">
                      <FormikTextField
                        name="companyExtension"
                        label={" "}
                        placeholder={Translate(
                          "addOwner:companyPhoneExtensionPlaceholder"
                        )}
                        maxLength={3}
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-4 col-sm-12">
                    <FormikPhoneInput
                      label={Translate("addOwner:hrContact")}
                      name="hrMobile"
                      value={values.hrMobile}
                      onChange={(value) => setFieldValue("hrMobile", value)}
                      onCountryChange={(hrCountry) =>
                        setFieldValue("hrMobileCountryCode", hrCountry.slice(1))
                      }
                      placeholder={Translate("addOwner:hrContactPlaceholder")}
                      maxLength={validations?.contactPhoneLength.MAX}
                      shortCodeChange={(item) =>
                        setFieldValue("hrMobileShortCode", item)
                      }
                      currentCountryCode={values?.hrMobileShortCode}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="reportTo"
                      value={state ? values?.reportTo : userData?.name}
                      label={Translate("addOwner:reportTo")}
                      placeholder={Translate("addOwner:reportTo")}
                      autoComplete={"off"}
                      disabled={true}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-12">
                    <FormikTextField
                      name="email"
                      label={Translate("addOwner:workEmail")}
                      placeholder={Translate("addOwner:workEmail")}
                      autoComplete={"off"}
                      required
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <div className="AddressLabel">
                      <StyledTypography variant="subtitle1">
                        {Translate("addOwner:address")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span"
                        >
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>

                    <FormikTextField
                      name="registerAddress"
                      placeholder={Translate(
                        "addOwner:residenceAddressPlaceholder"
                      )}
                      autoComplete={"off"}
                      isLocation={true}
                      RenderRightContainer={<LocationIcon />}
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, true);
                      }}
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="resApartment"
                      placeholder={Translate(
                        "addOwner:residenceAddressPlaceholder2"
                      )}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="resCountry"
                      placeholder={Translate("addOwner:country")}
                      disabled={registerAddress}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="resState"
                      placeholder={Translate("addOwner:state")}
                      disabled={registerAddress}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="resCity"
                      placeholder={Translate("addOwner:city")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="resZipCode"
                      maxLength={6}
                      placeholder={Translate("addOwner:zipcode")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <div className="AddressLabel">
                      <StyledTypography variant="subtitle1">
                        {Translate("addOwner:workAddress")}
                        <StyledTypography
                          sx={{ color: "red" }}
                          component="span"
                        >
                          {" *"}
                        </StyledTypography>
                      </StyledTypography>
                    </div>
                    <FormikTextField
                      name="workAddress"
                      placeholder={Translate(
                        "addOwner:workAddressPlaceholder2"
                      )}
                      isLocation={true}
                      RenderRightContainer={<LocationIcon />}
                      locationValue={(currentLocation) => {
                        currentLocationData(currentLocation, false);
                      }}
                      autoComplete={"off"}
                      maxLength={100}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 col-sm-12">
                    <FormikTextField
                      name="workApartment"
                      placeholder={Translate("addOwner:workAddressPlaceholder")}
                      autoComplete={"off"}
                      maxLength={validations?.landmarkLimit.MAX}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="workCountry"
                      disabled={workAddressDisabled}
                      placeholder={Translate("addOwner:country")}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="workState"
                      disabled={workAddressDisabled}
                      placeholder={Translate("addOwner:state")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="workCity"
                      placeholder={Translate("addOwner:city")}
                      maxLength={validations?.cityLimit.MAX}
                    />
                  </div>
                  <div className="form-group col-md-4 col-sm-8">
                    <FormikTextField
                      name="workZipCode"
                      maxLength={6}
                      placeholder={Translate("addOwner:zipcode")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-4 col-sm-8">
                    <RadioButtons
                      radioOptions={radioOptions}
                      defaultChecked={
                        state ? (state.gender === "MALE" ? 0 : 1) : 0
                      }
                      label={Translate("addOwner:gender")}
                      onPress={(value) => {
                        setFieldValue("gender", value.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button
                    type="submit"
                    className="btn btn-primary saveButton"
                    onClick={async () => {
                      await submitForm();

                      setAddMoreManager(false);
                    }}
                  >
                    {Translate("addOwner:save")}
                  </button>
                  {!state && (
                    <button
                      type="submit"
                      className="btn btn-white addButton"
                      onClick={() => {
                        submitForm();
                        setAddMoreManager(true);
                      }}
                    >
                      {Translate("addOwner:addMore")}
                    </button>
                  )}
                </div>
              </div>
              <Loader loading={isAdding || isEditing || isOwnerDeleteLoading} />
              <AlertPermission
                show={show}
                subTitle={Translate("addOwner:deleteAlertMsg")}
                onOk={deleteStaffOwner}
                onCanel={onCancel}
              />
              <AlertPermission
                show={showRemoveProfileAlert}
                subTitle={Translate("common:deleteProfileAlert")}
                onOk={removeFile}
                onCanel={onCancelProfile}
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddOwner;
