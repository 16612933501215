import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Translate from "../../../translation/utils/translateFunction";
import { FormikTextField } from "../../FormikForm/component/FormikTextField";
import Loader from "../../staff/Owner/LoadingComp";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import { FormikNumberField } from "../../FormikForm/component/FormikNumberField";

const ClientDetailForm = ({ isAdd, isView, isLoading, handleSubmit }) => {
  const navigate = useNavigate();
  const { validations } = useSelector((state) => state?.formanagement);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="row container_spacing">
        <div className="card card_spacing">
          <div className="page-title formTitle p-3 row align-items-center">
            <i
              className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
              onClick={() => {
                navigate("/client");
              }}
              data-toggle="tooltip"
              title={Translate("common:backToolTip")}
            />
            {isAdd
              ? Translate("client:addClientDetailHeading")
              : Translate("client:viewClientDetailHeading")}
          </div>
          <div className="card-body">
            <div className="row mt-3">
              <div className="col-md-12">
                <StyledTypography sx={{ fontSize: "18px" }}>
                  {Translate("client:s3DetailsTitle")}
                </StyledTypography>
                <div className="boxLine"></div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="bucketName"
                  label={Translate("client:bucketName")}
                  placeholder={Translate("client:bucketName")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  required={isAdd}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="region"
                  label={Translate("client:region")}
                  placeholder={Translate("client:region")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  required={isAdd}
                  disabled={isView}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="accessKey"
                  label={Translate("client:accessKey")}
                  placeholder={Translate("client:accessKey")}
                  autoComplete={"off"}
                  maxLength={250}
                  required={isAdd}
                  disabled={isView}
                />
              </div>

              <div className="form-group col-md-6 col-sm-12">
                <FormikTextField
                  name="secretAccessKey"
                  label={Translate("client:secretAccessKey")}
                  placeholder={Translate("client:secretAccessKey")}
                  autoComplete={"off"}
                  maxLength={250}
                  required={isAdd}
                  disabled={isView}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <StyledTypography sx={{ fontSize: "18px" }}>
                  {Translate("client:dbDetailsTitle")}
                </StyledTypography>
                <div className="boxLine"></div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="dbName"
                  label={Translate("client:dbName")}
                  placeholder={Translate("client:dbName")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  required={isAdd}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="userName"
                  label={Translate("client:dbUserName")}
                  placeholder={Translate("client:dbUserName")}
                  autoComplete={"off"}
                  maxLength={validations?.nameLength.MAX}
                  required={isAdd}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                {isAdd ? (
                  <FormikTextField
                    name="password"
                    label={Translate("client:dbPassword")}
                    placeholder={Translate("client:dbPassword")}
                    autoComplete={"off"}
                    type={showPassword ? "text" : "password"}
                    RenderRightContainer={
                      showPassword ? (
                        <i
                          className="fe fe-eye cursor-pointer passwordIcon-alignement"
                          onClick={() => setShowPassword(false)}
                        />
                      ) : (
                        <i
                          className="fe fe-eye-off cursor-pointer passwordIcon-alignement"
                          onClick={() => setShowPassword(true)}
                        />
                      )
                    }
                    showPassword={showPassword}
                    required={isAdd}
                  />
                ) : (
                  <FormikTextField
                    name="password"
                    label={Translate("client:dbPassword")}
                    placeholder={Translate("client:dbPassword")}
                    autoComplete={"off"}
                    maxLength={validations?.nameLength.MAX}
                    required={isAdd}
                    disabled={isView}
                    type="password"
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-4 col-sm-12">
                <FormikNumberField
                  name="port"
                  label={Translate("client:dbPort")}
                  placeholder={Translate("client:dbPort")}
                  autoComplete={"off"}
                  maxLength={5}
                  required={isAdd}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="loginDb"
                  label={Translate("client:dbLogin")}
                  placeholder={Translate("client:dbLogin")}
                  autoComplete={"off"}
                  maxLength={250}
                  required={isAdd}
                  disabled={isView}
                />
              </div>
              <div className="form-group col-md-4 col-sm-12">
                <FormikTextField
                  name="uri"
                  label={Translate("client:dbUri")}
                  placeholder={Translate("client:dbUri")}
                  autoComplete={"off"}
                  maxLength={250}
                  required={isAdd}
                  disabled={isView}
                />
              </div>
            </div>
            {!isView && (
              <div className="card-footer text-right">
                <button
                  type="submit"
                  className="btn btn-primary saveButton"
                  onClick={() => handleSubmit()}
                >
                  {Translate("common:save")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader loading={isLoading} />
    </>
  );
};

export default memo(ClientDetailForm);
