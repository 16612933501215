import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Translate from "../../translation/utils/translateFunction";
import { FormikTextField } from "../FormikForm/component/FormikTextField";
import { ChangePassInitialValues, passwordMaxLength } from "./constants";
import { ChangePasswordSchema } from "./utils";
import {
  useChangePasswordMutation,
  useLazyLogoutQuery,
} from "../../request/Authentication";
import { showToast } from "../../common/Utils/ToastMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompanyAction,
  setFcmTokenAction,
  userDataAction,
  userTypeAction,
} from "../../store/Reducer";
import Loader from "../staff/Owner/LoadingComp";

export const ChangePassword = () => {
  const { userData } = useSelector((state) => state.formanagement);
  const dispatch = useDispatch();
  const [logout] = useLazyLogoutQuery();

  const logOut = async () => {
    logout({ _id: userData?._id }).then(async (res) => {
      if (res.isSuccess) {
        dispatch(selectCompanyAction([]));
        localStorage.removeItem("userData");
        dispatch(userDataAction(undefined));
        dispatch(userTypeAction(undefined));
        dispatch(setFcmTokenAction(""));
      }
    });
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [
    changePassword,
    {
      data: changePasswordData,
      isLoading,
      isSuccess: successChangePassword,
      isError: isErrorChangePassword,
      error: errorChangePassword,
    },
  ] = useChangePasswordMutation();

  const onSubmit = async (changePasswordValues) => {
    let requestObject = {
      oldPassword: changePasswordValues?.oldPass,
      newPassword: changePasswordValues?.newPass,
      confirmPassword: changePasswordValues?.confirmPass,
    };
    changePassword(requestObject);
  };

  useEffect(() => {
    if (successChangePassword) {
      if (changePasswordData?.success) {
        showToast({ message: changePasswordData.message, type: "success" });
        setTimeout(() => {
          logOut();
        }, 3000);
      } else {
        showToast({ message: changePasswordData.message, type: "error" });
      }
    }

    if (isErrorChangePassword) {
      showToast({
        message:
          errorChangePassword?.data?.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    successChangePassword,
    changePasswordData,
    isErrorChangePassword,
    errorChangePassword,
  ]);

  return (
    <>
      <Formik
        initialValues={ChangePassInitialValues}
        validateOnMount
        validationSchema={ChangePasswordSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <div className="row container_spacing">
              <div className="card card_spacing">
                <div className="page-title formTitle p-3 row align-items-center mb-3">
                  {Translate("changePassword:changePassword")}
                </div>
                <div className="card-body" id="changePassword">
                  <div className="row">
                    <div className="form-group col-md-4 col-sm 12">
                      <FormikTextField
                        name="oldPass"
                        label={Translate("changePassword:oldPass")}
                        placeholder={Translate("changePassword:oldPass")}
                        autoComplete={"off"}
                        type={showOldPassword ? "text" : "password"}
                        RenderRightContainer={
                          showOldPassword ? (
                            <i
                              className="fe fe-eye cursor-pointer passwordIcon-alignement"
                              onClick={() => setShowOldPassword(false)}
                            />
                          ) : (
                            <i
                              className="fe fe-eye-off cursor-pointer passwordIcon-alignement"
                              onClick={() => setShowOldPassword(true)}
                            />
                          )
                        }
                        maxLength={passwordMaxLength}
                        showPassword={showOldPassword}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4 col-sm 12">
                      <FormikTextField
                        name="newPass"
                        label={Translate("changePassword:newPass")}
                        placeholder={Translate("changePassword:newPass")}
                        autoComplete={"off"}
                        type={showNewPassword ? "text" : "password"}
                        RenderRightContainer={
                          showNewPassword ? (
                            <i
                              className="fe fe-eye cursor-pointer passwordIcon-alignement"
                              onClick={() => setShowNewPassword(false)}
                            />
                          ) : (
                            <i
                              className="fe fe-eye-off cursor-pointer passwordIcon-alignement"
                              onClick={() => setShowNewPassword(true)}
                            />
                          )
                        }
                        maxLength={passwordMaxLength}
                        showPassword={showNewPassword}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4 col-sm 12">
                      <FormikTextField
                        name="confirmPass"
                        label={Translate("changePassword:confirmPass")}
                        placeholder={Translate("changePassword:confirmPass")}
                        autoComplete={"off"}
                        type={showConfirmPassword ? "text" : "password"}
                        RenderRightContainer={
                          showConfirmPassword ? (
                            <i
                              className="fe fe-eye cursor-pointer passwordIcon-alignement"
                              onClick={() => setShowConfirmPassword(false)}
                            />
                          ) : (
                            <i
                              className="fe fe-eye-off cursor-pointer passwordIcon-alignement"
                              onClick={() => setShowConfirmPassword(true)}
                            />
                          )
                        }
                        maxLength={passwordMaxLength}
                        showPassword={showConfirmPassword}
                      />
                    </div>
                  </div>

                  <div className="card-footer text-right line_spacing">
                    <button
                      type="submit"
                      className="btn btn-primary saveButton"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {Translate("changePassword:save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
      <Loader loading={isLoading} />
    </>
  );
};

export default ChangePassword;
