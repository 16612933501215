// ** MUI Imports
import {
  GridColumnHeaders,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import React from "react";

import { LinearProgress } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import { Box } from "@mui/system";
import {
  StyledDataGrid,
  StyledGridPagination,
} from "../../common/Utils/StyledComponents";
import ListShimmer from "../ListShimmer";
import NoDataFound from "../ManageTask/NoDataFound";

const ListView = ({
  columns,
  rows,
  total,
  onPaginationChange,
  currentPage = 1,
  totalPageCount = 0,
  isExpandedTable = false,
  rowsPerPage = 10,
  showColumnShowHide = false,
  hideColumnHeaders = false,
  showDynamicRowHeight = false,
  initialState,
  autoHeight,
  showShimmer,
  showLoader,
  hidePagination,
  sortBy = false,

  ...props
}) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ py: 5, justifyContent: "end" }}>
        <GridToolbarColumnsButton sx={{ paddingRight: 0, marginRight: -3 }} />
      </GridToolbarContainer>
    );
  }

  function Pagination({ className, onPageChange }) {
    return (
      <MuiPagination
        color="primary"
        className={className}
        count={totalPageCount}
        page={currentPage}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
          onPaginationChange(newPage);
        }}
      />
    );
  }

  function CustomPagination() {
    return (
      <StyledGridPagination
        rowsPerPage={rowsPerPage}
        ActionsComponent={Pagination}
      />
    );
  }

  return (
    <>
      {showLoader && (
        <Box sx={{ width: "100%", padding: "20px 10px 0px 10px" }}>
          <LinearProgress style={{ height: 2 }} />
        </Box>
      )}
      {showShimmer ? (
        <ListShimmer />
      ) : (
        rows && (
          <StyledDataGrid
            localeText={{
              toolbarColumns: (
                <span style={{ fontSize: 10, textTransform: "lowercase" }}>
                  {""}
                </span>
              ),
              noRowsLabel: <NoDataFound isCenter />,
            }}
            sortingOrder={sortBy ? ["desc"] : ["asc"]}
            autoHeight={autoHeight}
            slots={{
              toolbar: showColumnShowHide ? CustomToolbar : null,
              pagination: hidePagination ? null : CustomPagination,
              columnHeaders: hideColumnHeaders ? () => null : GridColumnHeaders,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: rowsPerPage } },
              ...initialState,
            }}
            pageSizeOptions={[rowsPerPage]}
            rows={rows}
            paginationMode="client"
            style={{ height: isExpandedTable ? "350px" : "600px" }}
            componentsProps={{
              panel: {
                placement: "bottom-end",
              },
            }}
            getRowClassName={(param) =>
              param?.indexRelativeToCurrentPage % 2 !== 0 ? "tableStrip" : null
            }
            columns={columns}
            getRowId={(row) => row?._id || row?.userId}
            getRowHeight={() => (showDynamicRowHeight ? "auto" : 50)}
            {...props}
          />
        )
      )}
    </>
  );
};

export default ListView;
