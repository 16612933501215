export const InitialValues = {
  companyId: [],
  name: "",
  dob: "",
  designation: "",
  department: "",
  mobile: "",
  countryCode: "91",
  companyNumber: "",
  hrMobile: "",
  hrMobileCountryCode: "91",
  email: "",
  registerAddress: "",
  resApartment: "",
  resState: "",
  resCountry: "",
  resCity: "",
  resZipCode: "",
  workCity: "",
  workZipcode: "",
  workState: "",
  workCountry: "",
  zipCode: "",
  workAddress: "",
  workApartment: "",
  Male: false,
  Female: false,
  gender: "MALE",
  mobileShortCode: "IN",
  hrMobileShortCode: "IN",
  alternateMobile: "",
  alternateMobileCountryCode: "91",
  alternateMobileShortCode: "IN",
};
export const options = [
  {
    label: "www.flipkart.com",
    value: "www.flipkart.com",
  },
  {
    label: "www.google.com",
    value: "www.google.com",
  },
  {
    label: "www.amazon.in",
    value: "www.amazon.in",
  },
  {
    label: "www.Myntra.com",
    value: "www.Myntra.com",
  },
];

export const radioOptions = ["Male", "Female"];
