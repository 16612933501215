import moment from "moment-timezone";

export const convertToB64 = (acceptedFile) => {
  return new Promise((resolve) => {
    const file = acceptedFile;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
};

const units = ["B", "KB", "MB"];

export const toConvertKB = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const getSentenseCase = (str) => {
  if (!str) return "";

  const parsedString = str.replace(/[A-Z]/g, (char) => ` ${char}`).split(" ");
  const word1 = parsedString[1];
  const word2 = parsedString[2].replace(
    /[A-Z]/,
    (char) => `${char.toLowerCase()}`
  );
  return `${word1} ${word2}`;
};

export const getNameAndExtFromFile = (str) => {
  if (!str) return [];

  return str.split(/.(?=[^.]+$)/);
};
export const isObjectEmpty = (objectName) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

export const getSentenseCaseforSingleWord = (str) => {
  if (!str) return "";

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const convertToSentenceCase = (input) => {
  const words = input.split(" ");
  const formattedWords = words.map((word, index) => {
    if (index === 0) {
      // Capitalize the first letter of the first word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      // Convert the rest of the words to lowercase
      return word.toLowerCase();
    }
  });
  return formattedWords.join(" ");
};

export const getMoment = (data) => {
  return moment();

  /* const userData = JSON.parse(localStorage.getItem("userData"));
  return userData?.login
    ? moment(data).tz(userData?.login?.timezone)
    : moment(); */
};
