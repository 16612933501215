import * as yup from "yup";
import Translate from "../../translation/utils/translateFunction";
import { Regex } from "../../common/Utils/ApiConstants/index";

const phoneReg = /^[0-9]{6,12}$/;

export const LoginOTPSchema = () => {
  const schema = yup.object().shape({
    phoneNumber: yup
      .string()
      .trim()
      .matches(phoneReg, Translate("login:mobileError2"))
      .required(Translate("login:mobileError1")),
  });

  return schema;
};

export const LoginSchema = () => {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(Translate("login:emailError1"))
      .trim()
      .required(Translate("login:emailError")),
    password: yup.string().trim().required(Translate("login:passwordError")),
  });

  return schema;
};

export const ChangePasswordSchema = () => {
  const schema = yup.object().shape({
    oldPass: yup
      .string()
      .required(Translate("changePassword:oldPassReq"))
      .matches(
        Regex.passwordRegexWithoutSequence,
        Translate("changePassword:passValidationError")
      ),

    newPass: yup
      .string()
      .required(Translate("changePassword:newPassReq"))
      .matches(
        Regex.passwordRegexWithoutSequence,
        Translate("changePassword:passValidationError")
      ),

    confirmPass: yup
      .string()
      .required(Translate("changePassword:confirmPassReq"))
      .oneOf([yup.ref("newPass")], Translate("changePassword:passNotMatch")),
  });

  return schema;
};
