import React from "react";
import Translate from "../../translation/utils/translateFunction";

const Searchbar = ({
  handleSearchText,
  setSearch,
  disabled = false,
  isShareModal = false,
  isMessageModule = false,
}) => {
  const handleChange = (e) => {
    let inputValue = e.target.value.trim();

    handleSearchText(inputValue);
    setSearch(inputValue);
  };

  const handleKeyPress = (e) => {
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (!regex.test(e.key)) {
      e.preventDefault();
    }
    if (e) {
      e.code === "Enter" && e.preventDefault();
    }
  };
  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const regex = /^[a-zA-Z0-9\s]*$/;
    if (!regex.test(pastedText)) {
      e.preventDefault();
    }
    if (e) {
      e.code === "Enter" && e.preventDefault();
    }
  };

  return (
    <input
      type="text"
      autoComplete="off"
      className={`form-control 
        ${
          !isShareModal && !isMessageModule && "form-control-sm searchBarWidth"
        } ${isMessageModule && "messageSearch search"}`}
      placeholder={Translate("common:searchPlaceholder")}
      name="search"
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      onPaste={handlePaste}
      disabled={disabled}
    />
  );
};

export default Searchbar;
