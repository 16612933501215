export default {
  head: 'Task report',
  assigned: 'Assigned',
  complete: 'Completed',
  inProgress: 'In-Progress',
  resolved: 'Resolved',
  reOpened: 'Re-opened',
  overdue: 'Overdue',
  completedBefore: 'Completed (Before)',
  completedAfter: 'Completed (After)',
  priorityPlaceHolder: "Select priority",
  sortByPlaceHolder: "Select sort by",
  staffPlaceHolder: "Select staff",
  sortByLabel: "Sort by",
  staffLabel: "Staff",
  priorityLabel: "Priority",
  graphDataNotFound: "No data available for the chart."
};
