/* eslint-disable no-unused-vars */
import NotFound from "./Authentication/404";
import InternalServer from "./Authentication/500";
import ForgotPassword from "./Authentication/forgotpassword";
import Signup from "./Authentication/signup";
import GetForms from "./FormikForm";
import Dashboard from "./Dashboard/Dashboard";
import Profile from "./Profile/Profile";
import OwnerListing from "./staff/Owner";
import AddOwner from "./staff/Owner/Components/AddOwner";
import ViewOwner from "./staff/Owner/Components/ViewOwner";
import ChangePassword from "./Authentication/ChangePassword";
import AddCompany from "./Company/AddCompany";
import EditCompany from "./Company/EditCompany";
import ClientListing from "./Client";
import AddClient from "./Client/AddClient";
import AddClientDetails from "./Client/AddClientDetails";
import ViewClient from "./Client/ViewClient"
import ViewClientDetails from "./Client/ViewClientDetails";
import SubscriptionListing from "./Subscription";
import AddSubscription from "./Subscription/AddSubscription";
import ViewSubscription from "./Subscription/ViewSubscription";
import EditSubscription from "./Subscription/EditSubscription";

const RoutesPath = [
  {
    path: "/",
    name: "dashboard",
    exact: true,
    pageTitle: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/signup",
    name: "signup",
    exact: true,
    pageTitle: "Tables",
    component: Signup,
  },
  {
    path: "/forgotpassword",
    name: "forgotpassword",
    exact: true,
    pageTitle: "Tables",
    component: ForgotPassword,
  },
  {
    path: "/notfound",
    name: "notfound",
    exact: true,
    pageTitle: "Tables",
    component: NotFound,
  },
  {
    path: "/internalserver",
    name: "internalserver",
    exact: true,
    pageTitle: "Tables",
    component: InternalServer,
  },

  {
    path: "/profile",
    name: "profile",
    exact: true,
    pageTitle: "Account",
    component: Profile,
  },

  {
    path: "/getForms",
    name: "getForms",
    exact: true,
    pageTitle: "Get Forms",
    component: GetForms,
  },

  {
    path: "/ownerListing",
    name: "ownerListing",
    exact: true,
    pageTitle: "Owner",
    component: OwnerListing,
  },
  {
    path: "/addOwner",
    name: "addOwner",
    exact: true,
    pageTitle: "Owner",
    component: AddOwner,
  },

  {
    path: "/viewOwner",
    name: "view owner",
    exact: true,
    pageTitle: "View Owner",
    component: ViewOwner,
  },
  {
    path: "/addComany",
    name: "company",
    exact: true,
    pageTitle: "Company",
    component: AddCompany,
  },
  {
    path: "/editOfficeLocation",
    name: "company",
    exact: true,
    pageTitle: "Edit office location",
    component: EditCompany,
  },
  {
    path: "/settings/changePassword",
    name: "changePassword",
    exact: true,
    pageTitle: "Change password",
    component: ChangePassword,
  },
  {
    path: "/client",
    name: "clientListing",
    exact: true,
    pageTitle: "Client",
    component: ClientListing,
  },
  {
    path: "/client/add",
    name: "addClient",
    exact: true,
    pageTitle: "Client",
    component: AddClient,
  },
  {
    path: "/client/view",
    name: "viewClient",
    exact: true,
    pageTitle: "Client",
    component: ViewClient,
  },
  {
    path: "/client/details/add",
    name: "addClientDetails",
    exact: true,
    pageTitle: "Client",
    component: AddClientDetails,
  },
  {
    path: "/client/details/view",
    name: "viewClientDetails",
    exact: true,
    pageTitle: "Client",
    component: ViewClientDetails,
  },
  {
    path: "/subscription",
    name: "subscriptionListing",
    exact: true,
    pageTitle: "Subscription",
    component: SubscriptionListing,
  },
  {
    path: "/subscription/add",
    name: "addSubscription",
    exact: true,
    pageTitle: "Subscription",
    component: AddSubscription,
  },
  {
    path: "/subscription/view",
    name: "viewSubscription",
    exact: true,
    pageTitle: "Subscription",
    component: ViewSubscription,
  },
  {
    path: "/subscription/edit",
    name: "editSubscription",
    exact: true,
    pageTitle: "Subscription",
    component: EditSubscription,
  },
];

export default RoutesPath;
