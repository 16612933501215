export default {
  employeeTitle: "Add employee",
  employee: "Employee",
  companyError: "Company is required",
  name: "Employee name",
  nameError: "Employee name is required",
  company: "Select company",
  selectCompanyMessage: "Please select at least one company",
  selectedCompanyMessage: "All company selected",
  address1: "Company address",
  email: "Email address",
  nameOfEmployee: "Name of employee",
  employeeName: "Employee name",
  numberPlaceholder: "+00 0000 0000 00",
  dob: "Date of birth",
  dobError: "Date of birth is required",
  designation: "Designation",
  designationError: "Designation is required",
  departmentError: "Department is required",
  department: "Department",
  gender: "Gender",
  workEmail: "Work email address",
  contactNumber: "Contact number",
  contactError: "Contact number is required",
  workError: "Work email is required",
  registerAddError: "Register address is required",
  contactNumberPlaceholder: "00 0000 0000",
  resCountryError: "Country is required",
  workCountryError: "Country is required",
  resStateError: "State/Region is required",
  workStateError: "State/Region is required",
  workAddressError: "Work address is required",
  hrContact: "HR contact number",
  alternateNumber: "Alternate contact number",
  address: "Register address",
  residenceAddressPlaceholder: "House/flat/block no",
  residenceAddressPlaceholder2: "Apartment/road/area",
  countryPlaceholder: "Country",
  statePlaceholder: "State/Region",
  city: "City",
  zipcode: "Zip-Code",
  workAddress: "Work Address",
  workAddressPlaceholder: "Address/block no",
  workAddressPlaceholder1: "Building/road/area",
  companyPhoneExtensionPlaceholder: "000",
  extensionNumber: "Company phone extension number",
  extensionNumberPlaceholder: "00 0000 0000",
  uploadPhoto: "Upload Photo",
  save: "Save",
  addMore: "Add more",
  ageAlert: "Age should be greater than 18",
  alertProfileDel: "Are you sure you want to delete this profile image?",
  delete: "Delete",
  UpdateProfile: "Update profile",
  WorkEmail: "Work email",
  srNoTableHeading: "Sr.no",
  actionTableHeading: "Action",
  nameTableHeading: "Name",
  mobileTableHeading: "Mobile no",
  companyNameTableHeading: "Company name",
  emailTableHeading: "Email",
  searchPlaceholder: "Search something...",
  addButtonLabel: "Add",
  viewEmployee: "View employee",
  editEmployee: "Edit employee",
  deleteErrorMsg: "Are you sure you want to delete this employee?",
};
