import React from "react";
import { colors } from "../../common/Theme/Colors";
import { getInitials } from "./utils";
import IconifyIcon from "../Icon";

export const Persona = ({
  name,
  image,
  size,
  className,
  isGroup = false,
  isBase64,
  isProfile,
}) => {
  const PersonaSize = size ? size : 48;

  const containerStyle = {
    backgroundColor: colors.primary,
    borderRadius: PersonaSize / 2,
    height: PersonaSize,
    width: PersonaSize,
    minWidth: PersonaSize,
    borderColor: "transparent",
    marginRight: 3,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
  };

  const imageStyle = {
    height: PersonaSize,
    width: PersonaSize,
    borderRadius: PersonaSize / 2,
  };

  const fontStyles = {
    color: colors.white,
    fontSize: PersonaSize * 0.38,
    textAlign: "center",
    height: PersonaSize,
    width: PersonaSize,
    borderRadius: PersonaSize / 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const groupStyle = {
    color: colors.white,
    textAlign: "center",
    height: PersonaSize - 12,
    width: PersonaSize - 12,
    borderRadius: PersonaSize / 3,
  };
  return (
    <div style={containerStyle} className={className}>
      {image ? (
        image?.includes("https://formanagement.s3.amazonaws.com/") ||
        isBase64 ? (
          <img src={image || ""} style={imageStyle} alt="" />
        ) : (
          <img src={{ uri: image || "" }} style={imageStyle} alt="" />
        )
      ) : isGroup ? (
        <IconifyIcon icon="grommet-icons:group" style={groupStyle} />
      ) : isProfile ? (
        <IconifyIcon
          icon="mdi:user-outline"
          style={{
            ...groupStyle,
            position: "absolute",
            top: "4px",
            left: "6px",
          }}
        />
      ) : (
        <div style={fontStyles}>{getInitials(name || "")}</div>
      )}
    </div>
  );
};
