export default {
  name: "Owner name",
  workEmail: "Work email",
  dateOfBirth: "Date of birth",
  dateOfBirthError: "Date of birth is required",
  department: "Department",
  companyDropdownPlaceholder: "Select company",
  companyDropdownError: "Company is required",
  workEmailPlaceholder: "Work email address",
  workEmailError: "Work email is required",
  contactNumber: "Contact number",
  alternate_contactNumber: "Alternate contact number",
  address: "Register address ",
  // addressError:'Register address is required',
  residenceAddressPlaceholder: "House/Flat/block no",
  residenceAddressPlaceholder2: "Apartment/road/area",
  resCountryError: "Country is required",
  resStateError: "State/Region is required",
  workCountryError: "Country is required",
  workStateError: "State/Region is required",
  workAddressError: "Work Address is required",
  departmentError: "Department is required",
  designation: "Designation",
  uploadPhoto: "Upload photo",
  gender: "Gender",
  workAddress: "Work Address",
  country: "Country",
  state: "State/Region",
  city: "City",
  zipcode: "Zip-Code",
  add: "Add",
  save: "Save",
  hrContactError: "Hr contact is required",
  companyPhoneExtensionPlaceholder: "000",
  addMore: "Add more",
  workAddressPlaceholder: "Building/road/area",
  workAddressPlaceholder2: "Address/block no",
  nameError: "Owner name is required",
  nameError_2: "Owner name is not valid",
  nameError_3: "Owner name must be at least 3 characters",
  nameError_4: "Owner name must be at most 100 characters",
  genderError: "Gender is required",
  emailError: "Email is required",
  numberError_1: "Contact number is not valid",
  numberError_2: "Contact number is required",
  alternate_numberError_1: "Alternate Contact number is not valid",
  alternate_numberError_2: "Alternate Contact number is required",
  addressError: "Address is required",
  designationError: "Designation is required",
  edit: "Edit details",
  alert: "Are you sure want to delete this owner?",
  extensionNumber: "Company phone extension number",
  contactNumberPlaceholder: "0000 0000 00",
  hrContact: "HR contact",
  hrContactPlaceholder: "0000 0000 00",
  extensionNumberPlaceholder: "0000 0000 00",
  extensionNumberError_1: "Extension number is not valid",
  extensionNumberError_2: "Extension number is required",
  resCityError: "It should only have alphabets",
  resZipCodeError: "Maximum length should be 6",
  workCityError: "It should only have alphabets",
  workZipCodeError: "Maximum length should  6",
  viewOwner: "View owner",
  alternateMobile: "Alternate contact number",
  editOwner: "Edit owner",
  deleteAlertMsg: "Are you sure you want to delete this owner?",
  ageAlert: "Age should be greater than 18",
  zipcodeError_2: "Zipcode is invalid!",
  companyError: "Company is required",
  reportTo: "Report to",
  selectComapnyMessage: "Please select at least one company",
  image: "Image",
  file: "File",
};
