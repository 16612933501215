/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Home } from "../../../src/assets/images/drawer-menu-svg/home.svg";
import { ReactComponent as Staff } from "../../../src/assets/images/drawer-menu-svg/person.svg";
import { ReactComponent as ChangePassword } from "../../../src/assets/images/drawer-menu-svg/password_change.svg";
import { ReactComponent as Settings } from "../../../src/assets/images/drawer-menu-svg/configure.svg";
import { colors } from "../../common/Theme/Colors";
import IconifyIcon from "../Icon";

const SidebarDrawer = () => {
  const content = [
    {
      id: 1,
      icon: <Home fill={colors?.primary} />,
      label: "Dashboard",
      to: "/",
      show: true,
    },
    {
      id: 2,
      icon: <Staff fill={colors?.primary} />,
      label: "Client",
      to: "/client",
      show: true,
      additionalPath: [
        "/client/add",
        "/client/details/add",
        "/client/view",
        "/client/details/view",
      ],
    },
    {
      id: 3,
      icon: (
        <IconifyIcon
          icon="eos-icons:product-subscriptions-outlined"
          color={colors?.primary}
          width="20"
        />
      ),
      label: "Subscription",
      to: "/subscription",
      show: true,
      additionalPath: [
        "/subscription/add",
        "/subscription/view",
        "/subscription/edit",
      ],
    },
    {
      id: 4,
      icon: <Settings fill={colors?.primary} />,
      label: "Settings",
      to: "/settings",
      show: true,
      content: [
        {
          id: 55,
          label: "Change password",
          to: "/settings/changePassword",
          icon: <ChangePassword fill={colors?.primary} />,
          show: true,
        },
      ],
    },
  ];

  const location = useLocation();
  const { isRtl } = useSelector((state) => state.formanagement);
  
  return (
    <Sidebar rtl={isRtl}>
      <Menu
        menuItemStyles={{
          label: ({ level, active }) => {
            return {
              color: active ? colors.white : colors.primary_009,
              fontWeight: active ? "500" : "400",
            };
          },
          subMenuContent: ({ level, active }) => {
            return {
              color: active ? colors.white : colors.primary_009,
              fontWeight: active ? "500" : "400",
            };
          },
          icon: ({ level, active }) => {
            return {
              width: 10,
              height: 10,
              // color: active ? colors.white : colors.primary_009,
              filter: !active
                ? "none"
                : "invert(94%) sepia(0%) saturate(24%) hue-rotate(24deg) brightness(105%) contrast(108%)",
            };
          },
          button: ({ level, active }) => {
            return {
              background: active ? colors.primary : colors.white,
              "&:hover": {
                color: active ? colors.white : colors.primary_009,
                backgroundColor: active ? colors.primary : colors.grey_001,
              },
            };
          },
        }}
      >
        {content.map((route) => {
          return route.content
            ? route?.show && (
                <SubMenu
                  key={route.label}
                  label={route.label}
                  active={location.pathname === route.to}
                  icon={route.icon}
                >
                  {route.content.map(
                    (submenu) =>
                      submenu?.show && (
                        <MenuItem
                          key={submenu.label}
                          component={<Link to={submenu.to} />}
                          active={
                            location.pathname === submenu.to ||
                            submenu?.additionalPath?.find(
                              (routeName) => routeName === location.pathname
                            )
                          }
                          icon={submenu.icon}
                          onClick={submenu.onclick}
                        >
                          {submenu.label}
                        </MenuItem>
                      )
                  )}
                </SubMenu>
              )
            : route?.show && (
                <MenuItem
                  key={route.label}
                  component={<Link to={route.to} />}
                  active={
                    location.pathname === route.to ||
                    route?.additionalPath?.find(
                      (routeName) => routeName === location.pathname
                    )
                  }
                  icon={route.icon}
                >
                  {route.label}
                </MenuItem>
              );
        })}
      </Menu>
    </Sidebar>
  );
};
export default SidebarDrawer;
