import React from "react";
const NoDataFound = ({
  message = "No data found",
  isCenter = false,
  className = "NoComment",
}) => {
  return isCenter ? (
    <div className={className}>{message}</div>
  ) : (
    <h4 className="mt-3">{message}</h4>
  );
};
export default NoDataFound;
