import { Stack } from "@mui/material";
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import Translate from "../../translation/utils/translateFunction";

export const UnAuth = () => {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{ height: "100vh" }}
      justifyContent={"center"}
      alignItems={"center"}>
      <div className="display-5 text-muted mb-3">
        {Translate("common:unAuthTitle")}
      </div>
      <StyledTypography
        className="h6 text-muted font-weight-normal mb-3"
        sx={{ fontWeight: "500" }}>
        {Translate("common:unAuthsubtitle")}
      </StyledTypography>

      <Button
        onClick={() => navigate("/")}
        size="lg"
        style={{
          width: "20%",
          display: "flex",
          justifyContent: "center",
          fontWeight: "500",
        }}>
        <i className="icon fe fe-arrow-left mr-2 icon-size cursor-pointer" />
        {Translate("common:unAuthBtn")}
      </Button>
    </Stack>
  );
};
