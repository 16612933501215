import React, { useEffect, useState } from "react";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import RoutesPath from "../Route";
import Header from "./Header";
import { ReactComponent as BrandLogo } from "../../assets/images/LogoApp_FOR.svg";
import usePrevLocation from "./UsePrevLocation";
import SidebarDrawer from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { toggleLeftMenuAction } from "../../store/Reducer";
import { UnAuth } from "./UnAuthorised";
import Translate from "../../translation/utils/translateFunction";
import IconifyIcon from "../Icon";
// import { ReactComponent as Chat } from "../../../src/assets/images/drawer-menu-svg/message.svg";

const AuthRoutes = (props) => {
  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  const dispatch = useDispatch();
  const pageHeading = RoutesPath.filter(
    (route) => route.path === location.pathname
  );
  const [parentlink, setParentLink] = useState(null);

  const { isMinSidebar, isToggleLeftMenu } = useSelector(
    (state) => state.formanagement
  );
  const masterNone = {
    display: "none",
  };

  const masterBlock = {
    display: "block",
  };
  useEffect(() => {
    const links = location.pathname.substring(1).split(/-(.+)/);
    const parentlink = links[0];
    const nochildlink = links[1];
    if (prevLocation.pathname !== location.pathname) {
      if (parentlink && nochildlink && nochildlink === "dashboard") {
        handler(parentlink, `${parentlink}${nochildlink}`);
      } else if (parentlink && nochildlink && nochildlink !== "dashboard") {
        handler(parentlink, nochildlink);
      } else if (parentlink) {
        handler(parentlink, "");
      } else {
        handler("hr", "dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const handler = (parentlink, nochildlink) => {
    setParentLink(parentlink);
  };
  const toggleLeftMenu = (e) => {
    dispatch(toggleLeftMenuAction(e));
  };
  return (
    <>
      <div className={`${isToggleLeftMenu ? "offcanvas-active" : ""}`}>
        <div style={parentlink === "login" ? masterNone : masterBlock}>
          <div
            id="header_top"
            className={`header_top ${isMinSidebar && "dark"}`}
          >
            <div className="container">
              <div
                className="hleft"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <NavLink
                  to="/"
                  onClick={() => this.handler("hr", "dashboard")}
                  className="header-brand"
                >
                  <BrandLogo className="for-brand-logo" />
                </NavLink>
                {/* <NavLink to="/message" className="header-brand"> */}
                {/* <i
                    className={`fa fa-comments-o ${
                      location.pathname === "/message" ? "active-icon" : ""
                    }`}
                  /> */}
                {/* <Chat
                    fill={
                      location.pathname.includes("/message")
                        ? "white"
                        : "#02005B"
                    }
                    className={`fa fa-comments-o ${
                      location.pathname.includes("/message")
                        ? "active-icon"
                        : ""
                    }`}
                  /> */}
                {/* </NavLink> */}
                {/* <NavLink to="/inbox/inboxList" className="header-brand">
                  <i
                    className={`fa fa-envelope ${
                      location.pathname.includes("/inbox") ? "active-icon" : ""
                    }`}
                    style={{ fontSize: "19px" }}
                  />
                </NavLink> */}
                {/* <NavLink to="/calendar" className="header-brand">
                  <i
                    className={`fa fa-calendar ${
                      location.pathname.includes("/calendar")
                        ? "active-icon"
                        : ""
                    }`}
                    style={{ fontSize: "19px" }}
                  />
                </NavLink> */}
              </div>
              <div className="hright">
                <div className="dropdown">
                  <p
                    className="nav-link icon menu_toggle"
                    onClick={() => toggleLeftMenu(!isToggleLeftMenu)}
                  >
                    {/* <i className="fa  fa-align-left" /> */}
                    <IconifyIcon
                      className="toogleIconSize"
                      icon="bi:arrow-right-circle-fill"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="left-sidebar" className="sidebar ">
            <h5 className="brand-name mb-4">
              {Translate("common:forManagemnet")}
            </h5>
            <nav id="left-sidebar-nav" className="sidebar-nav">
              <SidebarDrawer />
            </nav>
          </div>
        </div>
        <div className="page">
          <Header
            dataFromParent={props.dataFromParent}
            dataFromSubParent={pageHeading[0]?.pageTitle}
          />
          {props?.isAuthorised ? (
            <Routes>
              {RoutesPath.map((layout) => {
                return (
                  <Route
                    path={layout.path}
                    element={<layout.component />}
                    key={layout.name}
                  />
                );
              })}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            <UnAuth />
          )}
        </div>
      </div>
    </>
  );
};
export default AuthRoutes;
