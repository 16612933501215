import React, { useState, memo, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { showToast } from "../../common/Utils/ToastMessage";
import Translate from "../../translation/utils/translateFunction";
import Dropzone from "../CommonFileUpload/Dropzone";
import CommonImage from "../ImageGallery/CommonImage";
import { FormikDropdownPicker } from "../FormikForm/component/FormikDropDownPicker";
import { Formik } from "formik";
import { UserImportSchema } from "../Client/Components/validators";
import { UserImportInitialValues } from "../Client/Components/constants";
import { useGetCompanyListByClientMutation } from "../../request/Client";
import { useSelector } from "react-redux";
import { roleType, multiSelectRoles } from "./components/constants";
import FormikMultiSelectDropDown from "../FormikForm/component/FormikMultiSelectDropDown";
import { LinearProgress } from "@mui/material";

const ImportData = ({
  title,
  showModal,
  closeModal,
  onSubmit,
  isCompanyVisible,
  selectedClient,
}) => {
  const { userData } = useSelector((state) => state.formanagement);

  const formikRef = useRef(false);
  const [filesAdded, setFilesAdded] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [showMultiCompany, setShowMultiCompany] = useState(false);

  const acceptedFileType = {
    "text/csv": [".csv"],
  };

  const [
    getCompanyList,
    {
      data: companyData,
      isSuccess: isCompanyListSuccess,
      isLoading: isCompanyListLoading,
      isError: isCompanyListError,
      error: companyListError,
    },
  ] = useGetCompanyListByClientMutation();

  useEffect(() => {
    if (userData?.staff) {
      const accessRoles = userData?.staff?.map((role) => ({
        label: role in roleType && roleType[role],
        value: role,
      }));

      setRoleOptions(accessRoles);
    }
  }, [userData]);

  useEffect(() => {
    selectedClient && getCompanyList(selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    if (companyData?.data && isCompanyListSuccess) {
      const { data } = companyData;
      const companies = [];

      data.map((item) =>
        companies.push({
          label: item.name,
          value: `${item._id}`,
        })
      );

      setCompanyOptions(companies);
    }

    if (isCompanyListError && companyListError) {
      showToast({
        message:
          companyListError?.data?.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
    }
  }, [companyData, isCompanyListSuccess, isCompanyListError, companyListError]);

  const addFiles = (file) => {
    if (filesAdded.length < 1) {
      filesAdded.push(file);
      setFilesAdded([file]);
    } else {
      showToast({
        message: Translate("common:fileAddLimitMsg"),
        type: "error",
      });
    }
  };

  const removeFile = (file) => {
    const deletedFiles = filesAdded.filter(
      (fileName) => fileName.name !== file.name
    );

    setFilesAdded(deletedFiles);
  };

  const handleFormSubmit = () => {
    onSubmit(filesAdded[0]?.b64, selectedCompany, selectedRole);
    closeModal();
  };

  const resetRoleChange = () => {
    formikRef.current.setFieldValue("companyId", "");
    formikRef.current.setFieldValue("companies", []);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        className="col-span-3 col-md-12"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {isCompanyListLoading && <LinearProgress style={{ height: 2 }} />}
        <Modal.Body>
          <Formik
            initialValues={UserImportInitialValues}
            innerRef={formikRef}
            validateOnMount
            onSubmit={handleFormSubmit}
            validationSchema={() => UserImportSchema(showMultiCompany)}
          >
            {({ values, handleSubmit }) => {
              return (
                <>
                  {isCompanyVisible && (
                    <>
                      <div className="row">
                        <div className="form-group col-md-12 col-sm 12">
                          <FormikDropdownPicker
                            options={roleOptions}
                            value={values?.role}
                            name="role"
                            label={Translate("client:selectRole")}
                            placeholder={Translate("client:selectRole")}
                            onSelect={(role) => {
                              multiSelectRoles.includes(role)
                                ? setShowMultiCompany(true)
                                : setShowMultiCompany(false);
                              setSelectedRole(role);
                              selectedRole !== role && resetRoleChange();
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-12 col-sm 12">
                          {showMultiCompany ? (
                            <FormikMultiSelectDropDown
                              options={companyOptions}
                              name="companies"
                              label={Translate("common:selectCompany")}
                              placeholder={Translate("common:selectCompany")}
                              onSelectChange={(value) => {
                                setSelectedCompany(value);
                              }}
                              required
                            />
                          ) : (
                            <FormikDropdownPicker
                              options={companyOptions}
                              value={values?.companyId}
                              name="companyId"
                              label={Translate("common:selectCompany")}
                              placeholder={Translate("common:selectCompany")}
                              onSelect={(value) => {
                                setSelectedCompany([value]);
                              }}
                              required
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <Dropzone
                    translate={Translate("common:uploadFileLabel")}
                    onfileSelect={addFiles}
                    acceptedImages={acceptedFileType}
                    maximumFiles={1}
                    fileLength={filesAdded?.length}
                    maxFileSize={50 * 1000000}
                  />
                  {filesAdded && filesAdded?.length > 0 && (
                    <CommonImage
                      files={filesAdded}
                      removeFile={removeFile}
                      disableViewer
                    />
                  )}
                  <Modal.Footer className="importDateFooter">
                    <Button
                      variant="btn btn-white addButton"
                      onClick={closeModal}
                    >
                      {Translate("common:close")}
                    </Button>
                    <Button
                      variant={"btn btn-primary saveButton"}
                      disabled={!filesAdded?.length}
                      onClick={
                        isCompanyVisible
                          ? handleSubmit
                          : () => {
                              onSubmit(filesAdded[0]?.b64);
                              closeModal();
                            }
                      }
                      type="submit"
                    >
                      {Translate("common:importLabel")}
                    </Button>
                  </Modal.Footer>
                </>
              );
            }}
          </Formik>
          {/*  <Loader loading={isCompanyListLoading} /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ImportData);
