import { colors } from "../../../common/Theme/Colors";
import { AppFonts, FontSizes } from "../../../common/Theme/Fonts";

export const styles = {
  label: {
    color: colors.primary_010,
  },
  name: {
    color: colors.black,
    fontFamily: AppFonts.global,
    fontSize: FontSizes.regular,
  },
  TaskDetails: {
    color: colors.black,
    fontFamily: AppFonts.bold,
    fontSize: FontSizes.medium,
  },
  dateTime: {
    color: colors.black_001,
    fontSize: FontSizes.xSmall,
  },
  message: {
    fontSize: FontSizes.small,
  },
  taskDateStyle: {
    fontSize: FontSizes.small,
  },
  textInputContainer: {
    backgroundColor: colors.white,
  },
  textInput: {
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: FontSizes.medium,
    fontFamily: AppFonts.regular,
    borderRadius: 3,
    color: colors.black,
  },
  iconContainer: {
    // backgroundColor: 'wheat',
  },
  input: {
    flexGrow: 1,
    backgroundColor: colors.white,
    fontFamily: AppFonts.regular,
    fontSize: FontSizes.medium,
    color: colors.black,
    borderRadius: 3,
  },
};
