import Translate from "../../../translation/utils/translateFunction";

export const getClientColumns = (
  CustomSrCellRender,
  CustomActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "clientCode",
      headerName: Translate("client:clientCodeTitle"),
      flex: 0.4,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => `${params.row.clientCode}`,
    },
    {
      field: "name",
      headerName: Translate("client:nameTitle"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => `${params.row.name}`,
    },
    {
      field: "designation",
      headerName: Translate("client:designationTitle"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => `${params.row.designation}`,
    },
    {
      field: "mobileWithCountryCode",
      headerName: Translate("client:contactTitle"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.mobileWithCountryCode,
    },
    {
      field: "companyName",
      headerName: Translate("client:companyNameTitle"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.companyName,
    },
    {
      field: "email",
      headerName: Translate("client:emailTitle"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.email,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: CustomActionCellRender,
    },
  ];
  return columns;
};
