import homePage from "./Home/homePage";
import common from "./common";
import drawer from "./drawer";
import addCompany from "./Home/addCompany";
import addOwner from "./Home/addOwner";
import addManager from "./Home/addManager";
import addTask from "./Home/addTask";
import addEmployee from "./Home/addEmployee";
import manageTask from "./ManageTask/manageTask";
import taskDetails from "./ManageTask/taskDetails";
import notificationPage from "./Notification/notificationPage";
import login from "./Auth/login";
import logout from "./Auth/logout";
import selectCompany from "./Auth/selectCompany";
import forgotPassword from "./Auth/forgotPassword";
import filter from "./Filters/filter";
import taskReport from "./TaskReport/taskReport";
import staffReport from "./StaffReport/staffReport";
import permissions from "./Permissions/permissions";
import managersHomePage from "./Home/Manager/managersHomePage";
import pointsEarned from "./PointsEarned/pointsEarned";
import addPersonalAssistant from "./Home/addPersonalAssistant";
import changePassword from "./Auth/changePassword";
import dashboard from "./Dashboard/dashboard";
import client from "./Client/client";
import account from "./Account";
import subscription from "./Subscription";

export default {
  homePage,
  addCompany,
  addOwner,
  addManager,
  addTask,
  manageTask,
  taskDetails,
  notificationPage,
  addEmployee,
  login,
  selectCompany,
  forgotPassword,
  filter,
  taskReport,
  staffReport,
  permissions,
  managersHomePage,
  pointsEarned,
  drawer,
  logout,
  common,
  addPersonalAssistant,
  changePassword,
  dashboard,
  client,
  account,
  subscription
};
