import React from "react";
import Select, { components } from "react-select";
import { StyledTypography } from "../../../common/Utils/StyledComponents";
import Translate from "../../../translation/utils/translateFunction";

const SearchableDropDown = ({
  options,
  selected,
  onSelect,
  onChange,
  label,
  touched,
  error,
  isError,
  className,
  disabled = false,
  disableOption,
  required,
  placeholder,
  isSearchable = true,
  ...props
}) => {
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {Translate("common:noDataFoundMessage")}
        </span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <div>
      {label && (
        <StyledTypography className="labelStyling" component="span">
          {label}
          {required && (
            <StyledTypography sx={{ color: "red" }} component="span">
              {" *"}
            </StyledTypography>
          )}
        </StyledTypography>
      )}

      <Select
        options={options}
        value={selected}
        onChange={onSelect}
        onInputChange={onChange}
        placeholder={placeholder || label}
        className={className}
        classNamePrefix={"css-1s2u09g-control"}
        isOptionDisabled={disableOption}
        isDisabled={disabled}
        components={{ NoOptionsMessage }}
        isSearchable={isSearchable}
        styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
      />

      {touched && error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};
export default SearchableDropDown;
