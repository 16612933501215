import { Grid, Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  StyledCard,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
/* import { userTypes } from "../../../common/users/userTypes"; */
import Translate from "../../../translation/utils/translateFunction";

const OwnerListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const { selectedCompanyList, userData } = useSelector(
    (state) => state.formanagement
  );
  // const [loading, setLoading] = useState(true);
  // const [sortBy, setSortBy] = useState(null);
  // const [selectedColumn, setSelectedColumn] = useState("");
  // const [currentPageNo, setCurrentPageNo] = useState(1);
  // const [search, setSearch] = useState();
  // // const [ownerDataFetched, setownerDataFetched] = useState();
  // // const [showModal, setShowModal] = useState(false);
  // const lastPage = useRef(0);

  // const [
  //   getownerListApi,
  //   {
  //     data: ownerDataList,
  //     isSuccess: successOwnerList,
  //     error: Error,
  //     isError: isOwnerError,
  //     isLoading: loadingOwnerData,
  //   },
  // ] = useGetStaffOwnerListingMutation();

  // let requestObject = useMemo(() => {
  //   const companies = selectedCompanyList?.length
  //     ? selectedCompanyList?.map((item) => item?.value)
  //     : [];
  //   return sortBy !== null && sortBy !== undefined
  //     ? {
  //         companies,
  //         searchValue: search,
  //         sortCoulmn: selectedColumn,
  //         sortDirection: sortBy ? 1 : -1,
  //       }
  //     : {
  //         companies,
  //         searchValue: search,
  //       };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search, sortBy, currentPageNo, selectedCompanyList]);

  // useEffect(() => {
  //   if (successOwnerList && ownerDataList) {
  //     const { nodes, pageInfo } = ownerDataList.data;
  //     setownerDataFetched(nodes);
  //     lastPage.current = pageInfo.lastPageNo;
  //     setLoading(false);
  //   }
  //   if (isOwnerError && Error) {
  //     showToast({ message: "Failed to fetch the data", type: "error" });
  //     setLoading(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [successOwnerList, isOwnerError]);
  // const debouncedSearch = useDebouncedCallback(() => {
  //   sortBy !== undefined &&
  //     getownerListApi({ requestBody: requestObject, pageNo: currentPageNo });
  // }, 500);

  // useEffect(() => {
  //   if (selectedCompanyList?.length) {
  //     sortBy === null && setLoading(true);
  //     if (requestObject?.searchValue?.length) {
  //       debouncedSearch();
  //     } else {
  //       const finalRequestObject = {
  //         requestBody: requestObject,
  //         pageNo: currentPageNo,
  //       };
  //       sortBy !== undefined && getownerListApi(finalRequestObject);
  //     }
  //   } else {
  //     showToast({
  //       type: "info",
  //       message: Translate("addOwner:selectComapnyMessage"),
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [requestObject]);

  // const getSort = (columnName = "") => {
  //   setLoading(true);
  //   selectedColumn !== columnName && setSortBy(undefined);
  //   setTimeout(() => {
  //     selectedColumn !== columnName ? setSortBy(true) : setSortBy(!sortBy);
  //   }, 500);
  //   setSelectedColumn(columnName);
  // };
  // const CustomCellRender = (params) => {
  //   return (
  //     <td>
  //       <i
  //         className="m-2 fe fe-eye cursor-pointer"
  //         onClick={() => navigate("/viewOwner", { state: params?.row?.userId })}
  //       ></i>
  //     </td>
  //   );
  // };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${fixNavbar ? "marginTop" : ""} `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content mt-3">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <Stack flexDirection={"row"} alignItems={"center"}>
                    <div
                      // onClick={() => setShowModal(true)}
                      className="cursor-pointer"
                    >
                      <StyledTypography
                        variant="subtitle1"
                        fontWeight={"500"}
                        className="font-16 mb-0"
                      >
                        {selectedCompanyList?.length > 1
                          ? Translate("common:allCompanySelect")
                          : selectedCompanyList?.length > 0
                          ? selectedCompanyList[0]?.label
                          : Translate("common:selectCompany")}
                      </StyledTypography>
                    </div>
                    <div
                      className="m-2 cursor-pointer"
                      // onClick={() => setShowModal(true)}
                    >
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </Stack>
                  <div className="card-options">
                    <div className="input-group">
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control form-control-sm searchBarWidth"
                        placeholder="Search something..."
                        name="search"
                        // onChange={(e) => {
                        //   setCurrentPageNo(1);
                        //   setSearch(e.target.value);
                        // }}
                      />
                    </div>
                   {/*  {userData?.add?.find(
                      (option) =>
                        option?.user === userTypes?.Owner?.toUpperCase()
                    ) && (
                      <div className="header-action pull-right ml-2">
                        <button
                          type="button"
                          className="btn btn-primary py-2"
                          onClick={() => navigate("/addOwner")}
                        >
                          <i className="fe fe-plus" />
                          <StyledTypography className="mx-1" component="span">
                            {Translate("common:add")}
                          </StyledTypography>
                        </button>
                      </div>
                    )} */}
                  </div>
                </div>
                {/* <ListView
                  columns={getOwnerColumns(CustomCellRender)}
                  rows={ownerDataFetched}
                  autoHeight
                  sortingMode="server"
                  sortBy={sortBy}
                  onPaginationChange={setCurrentPageNo}
                  currentPage={currentPageNo}
                  showShimmer={loadingOwnerData}
                  showLoader={loading}
                  totalPageCount={lastPage.current}
                  onSortModelChange={(data) =>
                    data?.length && getSort(data[0]?.field)
                  }
                /> */}
              </div>
            </div>
          </div>
        </div>
        {/* <CompanyListing
          handleModal={(value) => {
            setCurrentPageNo(1);
            setShowModal(value);
          }}
          showModal={showModal}
          selectedCompanyList={selectedCompanyList}
        /> */}
      </StyledCard>
    </Grid>
  );
};

export default OwnerListing;
