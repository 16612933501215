import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const clientCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addClient: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addClient,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getClients: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getClients,
          method: Methods.post,
          body: body,
        };
      },
    }),
    addClientDetails: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addClientDetails,
          method: Methods.post,
          body: body,
        };
      },
    }),
    updateClientStatus: builder.mutation({
      query: (body) => {
        return {
          url: Urls.updateClientStatus,
          method: Methods.put,
          body: body,
        };
      },
    }),
    importCompanyData: builder.mutation({
      query: (body) => ({
        url: Urls.importCompanyData,
        method: Methods.post,
        body: body,
      }),
    }),
    importUserDataApi: builder.mutation({
      query: (body) => {
        return {
          url: Urls.importUserData,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getCompanyListByClient: builder.mutation({
      query: (clientId) => {
        return {
          url: `${Urls.getCompanyListByClient}${clientId}`,
          method: Methods.get,
        };
      },
    }),
    getClientData: builder.mutation({
      query: (clientId) => {
        return {
          url: `${Urls.getClientData}${clientId}`,
          method: Methods.get,
        };
      },
    }),
    getClientDetailsData: builder.mutation({
      query: (clientId) => {
        return {
          url: `${Urls.getClientDetailsData}${clientId}`,
          method: Methods.get,
        };
      },
    }),
    getSubscriptionTypes: builder.mutation({
      query: () => {
        return {
          url: Urls.getSubscriptionTypes,
          method: Methods.get,
        };
      },
    }),
  }),
});

export const {
  useAddClientMutation,
  useGetClientsMutation,
  useAddClientDetailsMutation,
  useUpdateClientStatusMutation,
  useImportCompanyDataMutation,
  useImportUserDataApiMutation,
  useGetCompanyListByClientMutation,
  useGetClientDataMutation,
  useGetClientDetailsDataMutation,
  useGetSubscriptionTypesMutation,
} = clientCollection;
