import { Methods, Urls } from "../../../common/Utils/ApiConstants";

import { CreateApi } from "../../CreateApi";
const staffownercollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaffOwnerListing: builder.mutation({
      query: ({ requestBody, pageNo }) => {
        return {
          url: Urls.getStaffOwnerList,
          method: Methods.post,
          body: requestBody,
          params: { pageNo },
        };
      },
    }),
    addStaffOwner: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addStaffOwner,
          method: Methods.post,
          body: body,
        };
      },
    }),
    viewStaffOwner: builder.mutation({
      query: (requestBody) => {
        return {
          url: `${Urls.viewStaffOwner}${requestBody}`,

          method: Methods.get,
        };
      },
    }),
    editStaffOwner: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.editStaffOwner}${body.id}`,
          method: Methods.put,
          body: body.body,
        };
      },
    }),
    deleteStaffOwner: builder.mutation({
      query: (body) => {
        return {
          url: `${Urls.deleteStaffOwner}${body}`,
          method: Methods.delete,
        };
      },
    }),
  }),
});
export const {
  useGetStaffOwnerListingMutation,
  useViewStaffOwnerMutation,
  useEditStaffOwnerMutation,
  useAddStaffOwnerMutation,
  useDeleteStaffOwnerMutation,
} = staffownercollection;
