import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { StyledTypography } from "../../../../common/Utils/StyledComponents";
import { showToast } from "../../../../common/Utils/ToastMessage";
import { useViewStaffOwnerMutation } from "../../../../request/Staff/Owner";
import Translate from "../../../../translation/utils/translateFunction";
import RadioButtons from "../../../RadioButtons";
import { TextField } from "../../../TextField/TextField";
import { radioOptions } from "./constant";
import Loader from "../LoadingComp";
import { Persona } from "../../../Persona";

const ViewOwner = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { validations } = useSelector((state) => state?.formanagement);
  const [ownerData, setViewOwnerData] = useState({});
  const [
    viewStaffOwnerData,
    {
      data: viewOwnerData,
      isSuccess: successfullyViewOwner,
      isError: errorInViewOwner,
      error: errorViewOwner,
      isLoading: loadingViewOwnerData,
    },
  ] = useViewStaffOwnerMutation();

  useEffect(() => {
    if (state) {
      viewStaffOwnerData(state);
    }
  }, [viewStaffOwnerData, state]);

  useEffect(() => {
    if (errorInViewOwner && errorViewOwner) {
      showToast({
        message: errorViewOwner?.data?.message,
        type: "error",
      });
    }

    if (successfullyViewOwner && viewOwnerData) {
      setViewOwnerData(viewOwnerData?.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewOwnerData, errorViewOwner]);

  return (
    <div className="row container_spacing">
      <div className="card card_spacing">
        <div className="page-title formTitle p-3 row align-items-center">
          <i
            className="icon fe fe-arrow-left mr-3 icon-size backnavigation-iconColor cursor-pointer"
            onClick={() => navigate("/ownerListing")}
            data-toggle="tooltip"
            title={Translate("common:backToolTip")}
          />
          {Translate("addOwner:viewOwner")}
          {viewOwnerData?.data[0].isEditable && (
            <div className="card-options">
              <i
                className={`icon-color ml-3 cursor-pointer ${"fa fa-pencil-square-o"}`}
                onClick={() =>
                  navigate("/addOwner", {
                    state: viewOwnerData?.data[0],
                  })
                }
                data-toggle="tooltip"
                title={Translate("common:editToolTip")}
              ></i>
            </div>
          )}
        </div>
        <div className="card-body">
          <div className="row imageDisplay mb-2">
            <div className="form-group col-md-4 col-sm-8">
              <Persona
                name={ownerData?.name}
                image={ownerData?.profileImageDetails?.url}
                size={96}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 12">
              <TextField
                value={
                  ownerData?.company?.length > 0
                    ? ownerData?.company?.map((item) => item.name).join(", ")
                    : ""
                }
                label={Translate("addOwner:companyDropdownPlaceholder")}
                placeholder={Translate("addOwner:companyDropdownPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.nameLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 12">
              <TextField
                value={ownerData?.name}
                label={Translate("addOwner:name")}
                placeholder={Translate("addOwner:name")}
                autoComplete={"off"}
                maxLength={validations?.nameLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 12">
              <TextField
                label={Translate("addOwner:dateOfBirth")}
                value={ownerData?.dob}
                placeholder={Translate("addOwner:dateOfBirth")}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 12">
              <TextField
                value={ownerData?.designation}
                label={Translate("addOwner:designation")}
                placeholder={Translate("addOwner:designation")}
                autoComplete={"off"}
                maxLength={validations?.designationLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 12">
              <TextField
                value={ownerData?.department}
                label={Translate("addOwner:department")}
                placeholder={Translate("addOwner:department")}
                autoComplete={"off"}
                maxLength={validations?.departmentLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>

            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={ownerData?.login?.mobileWithCountryCode}
                label={Translate("addOwner:contactNumber")}
                placeholder={Translate("addOwner:contactNumberPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.contactPhoneLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 12">
              <TextField
                value={
                  ownerData?.alternateMobile?.length > 0
                    ? ownerData?.alternateMobileWithCountryCode
                    : "NA"
                }
                label={Translate("addOwner:alternateMobile")}
                placeholder={Translate("addOwner:contactNumberPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.contactPhoneLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>

            <div className="form-group col-md-4 col-sm 12">
              <TextField
                value={
                  ownerData?.companyNumber?.length > 0
                    ? ownerData?.companyNumberWithExtension
                    : "NA"
                }
                label={Translate("addOwner:extensionNumber")}
                placeholder={Translate("addOwner:extensionNumberPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.telephoneLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 12">
              <TextField
                value={ownerData?.hrMobileWithCountryCode || "NA"}
                label={Translate("addOwner:hrContact")}
                placeholder={Translate("addOwner:hrContactPlaceholder")}
                autoComplete={"off"}
                maxLength={validations?.contactPhoneLength.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={ownerData?.reportTo?.name || ""}
                label={Translate("addOwner:reportTo")}
                placeholder={Translate("addOwner:reportTo")}
                autoComplete={"off"}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm-12">
              <TextField
                value={ownerData?.login?.email}
                label={Translate("addOwner:workEmail")}
                placeholder={Translate("addOwner:workEmailPlaceholder")}
                autoComplete={"off"}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12 col-sm 12">
              <div className="AddressLabel">
                <StyledTypography variant="subtitle1">
                  {Translate("addOwner:address")}
                </StyledTypography>
              </div>
              <TextField
                value={ownerData?.residenceAddress?.address}
                placeholder={Translate("addOwner:residenceAddressPlaceholder")}
                autoComplete={"off"}
                isLocation={true}
                locationValue={() => {}}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12 col-sm 12">
              <TextField
                value={ownerData?.residenceAddress?.flat}
                placeholder={Translate("addOwner:residenceAddressPlaceholder2")}
                autoComplete={"off"}
                maxLength={validations?.landmarkLimit.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                placeholder={Translate("addOwner:country")}
                value={ownerData?.residenceAddress?.country}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                placeholder={Translate("addOwner:state")}
                value={ownerData?.residenceAddress?.state}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={ownerData?.residenceAddress?.city}
                placeholder={Translate("addOwner:city")}
                maxLength={validations?.cityLimit.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={ownerData?.residenceAddress?.zipCode}
                maxLength={6}
                placeholder={Translate("addOwner:zipcode")}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12 col-sm 12">
              <div className="AddressLabel">
                <StyledTypography variant="subtitle1">
                  {Translate("addOwner:workAddress")}
                </StyledTypography>
              </div>
              <TextField
                value={ownerData?.workAddress?.address}
                placeholder={Translate("addOwner:workAddressPlaceholder")}
                autoComplete={"off"}
                isLocation={true}
                locationValue={() => {}}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12 col-sm 12">
              <TextField
                value={ownerData?.workAddress?.flat}
                placeholder={Translate("addOwner:workAddressPlaceholder2")}
                autoComplete={"off"}
                maxLength={validations?.landmarkLimit.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={ownerData?.workAddress?.country}
                placeholder={Translate("addOwner:country")}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={ownerData?.workAddress?.state}
                placeholder={Translate("addOwner:state")}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={ownerData?.workAddress?.city}
                placeholder={Translate("addOwner:city")}
                maxLength={validations?.cityLimit.MAX}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
            <div className="form-group col-md-4 col-sm 8">
              <TextField
                value={ownerData?.workAddress?.zipCode}
                maxLength={6}
                placeholder={Translate("addOwner:zipcode")}
                disabled={true}
                className={"form-control textFieldHeight"}
              />
            </div>
          </div>
          {ownerData?.gender && (
            <div className="row">
              <div className="form-group col-md-4 col-sm 8">
                <RadioButtons
                  radioOptions={radioOptions}
                  defaultChecked={ownerData?.gender === "MALE" ? 0 : 1}
                  label={Translate("addManager:gender")}
                  disabled
                />
              </div>
            </div>
          )}
        </div>

        {/*  <div className="row imageDisplay">
          {ownerData?.profileImageDetails?.url ? (
            <img
              src={ownerData?.profileImageDetails?.url}
              style={{
                objectFit: "contain",
                marginBottom: "10px",
                marginLeft: "20px",
                maxWidth: "150px",
                maxHeight: "150px",
              }}
              alt="Profile"
            />
          ) : undefined}
        </div> */}
        <Loader loading={loadingViewOwnerData} />
      </div>
    </div>
  );
};
export default ViewOwner;
