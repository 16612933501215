export default {
  head: "Task details",
  dueDate: "Due date",
  dueTime: "Due Time",
  status: "Status",
  priority: "Priority",
  company: "Company",
  companyName: "Company name",
  task: "Task",
  taskName: "Task name",
  description: "Description",
  assignee: "Assignee",
  assigneeName: "Assignee name",
  designation: "Designation of assignee",
  designationAssignto: "Designation",
  addComment: "Add comment",
  commentHead: "Comment",
  attachment: "Attachment",
  approve: "Approve",
  disapprove: "Disapprove",
  alert: "Are you sure you want to disapprove the task?",
  reopen: "Re-open",
  reason: "Reason for re-open the task:",
  reasonDisapprove: "Reason for disapprove the task:",
  addDetailsPlaceholder: "Add details",
  resolve: "Resolve",
  start: "Start",
  reAllocateTo: "Re-allocate to",
  comment: "Comment by owner",
  reporter: "Reporter",
  reporterName: "Reporter Name",
  designationReporter: "Designation of reporter",
  alertDelete: "Are you sure you want to delete this task?",
  alertDeleteComment: "Are you sure you want to delete this comment?",
  alertResolve: "Are you sure you want to resolve this task?",
  alertStart: "Are you sure you want to start this task?",
  alertReopen: "Are you sure you want to re-open this task?",
  date: "Date",
  time: "Time",
  completed: "Completed",
  markAsCompleted: "Mark as completed",
  pendingApproval: "Pending for approval",
  editTaskDetails: "Edit task details",
  addSubtask: "Add subtask",
  edited: "Edited",
  parentTask: "Parent task name",
  reAllocate: "Re-allocate",
  alertMarkAsCompleted: "Are you sure you want to complete the task?",
  discard: "Discard",
  alertCompleted: "Are you sure you want to save the changes?",
  resolved: "Resolved",
  relatedTask: "Related task name",
  pinTask: "Pin task",
  unpinTask: "Unpin task",
  markAsCritical: "Mark as critical",
  removeFromCritical: "Unmark as critical",
  createGroup: "Create group",
  alarm: "Alarm",
  reAssignTo: "Re-assign to",
  reminder: "Reminder",
  reminderFor: "Reminder for",
  remindTo: "Remind to",
  startDate: "Start date",
  startTime: "Start Time",
  subTaskName: "Subtask name",
  chat: "Linked with chat",
  taskHistory: "Task history",
  voiceNote: "Voice note",
  alertRemoveVoiceNote: "Are you sure you want to remove voice note?",
  rejectRequestAlert: "Are you sure you want to reject this request?",
  rejectTaskAlert: "Are you sure you want to reject this task?",
  disapproveTaskAlert: "Are you sure you want to disapprove this task?",
  rejectReasonAlert: "Reason for reject the task:",
  disapproveReasonAlert: "Reason for disapprove the task:",
  acceptButtonLabel: "Accept",
  rejectButtonLabel: "Reject",
  startSelfButtonLabel: "Start",
  startButtonLabel: "Start",
  resolveButtonLabel: "Resolve",
  markAsCompletedButtonLabel: "Mark as completed",
  approveButtonLabel: "Approve",
  disapproveButtonLabel: "Disapprove",
  addReminderButtonLabel: "Save",
  taskReminderHeading: "Task Reminder",
  saveCustomReminderButtonLabel: "Save",
  closeCustomReminderButtonLabel: "Close",
  customReminderHeading: "Custom Reminder",
  timeError: "Time is required",
  reminderForError: "Reminder for is required",
  remindToError: "Remind to is required",
  startDateError: "Start date is required",
  startTimeError: "Start time is required",
  reminderError: "Reminder is required",
  reminderTypeError: "Please select any one of the reminder",
  saveButtonLabel: "Save",
  reasonInputPlaceholder: "Add details",
  viewLinkedSubtaskHeading: "Linked subtasks",
  viewLinkedSubtaskLabel: "View linked subtask",
  viewTaskDependencyLabel: "Task dependency",
  addSubtaskBtnLabel: "Add subtask",
  reminderTimeError: "Reminder time must be a number",
  reminderTimeReqError: "Reminder time is required",
  reminderTimeMessageError: "Reminder time must be in-between",
  reminderTimeLabel: "Time",
  subtaskHead: "Subtask details",
  reminderToolTip: "Reminder",
  startDateLabel: "Start date",
  dueDateLabel: "Due date",
  assigntoMe: "Assign to me",
  reportToMe: "Report to me",
  reassign: "Re-assign to",
  saveChange: "Save changes",
};
