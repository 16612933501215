import React from "react";
import Modal from "react-bootstrap/Modal";
import { StyledTypography } from "../../common/Utils/StyledComponents";
import Translate from "../../translation/utils/translateFunction";
import { colors } from "../../common/Theme/Colors";
const AlertPermission = ({
  show = false,
  hideCancel,
  subTitle = "",
  onOk,
  onCanel,
  okTitle,
  isAlertText = false,
  title,
  link,
}) => {
  const Renderbuttons = () => {
    return (
      <>
        {!hideCancel && (
          <div className="pl-3">
            <StyledTypography
              variant="subtitle1"
              className="cursor-pointer"
              sx={{
                fontSize: "1.1rem",
                fontWeight: 500,
                color: colors.primary,
              }}
              onClick={onCanel}>
              {Translate("common:cancel")}
            </StyledTypography>
          </div>
        )}
        <div className="pl-3">
          <StyledTypography
            variant="subtitle1"
            className="cursor-pointer"
            sx={{
              fontSize: "1.1rem",
              fontWeight: 500,
              color: colors.primary,
            }}
            onClick={onOk}>
            {okTitle || Translate("common:ok")}
          </StyledTypography>
        </div>
      </>
    );
  };
  return (
    <Modal
      show={show}
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
      onHide={onCanel}>
      <Modal.Body style={{ padding: "1.5rem 2rem" }}>
        {!isAlertText && title && (
          <StyledTypography
            variant="h5"
            sx={{ fontSize: "1.1rem", fontWeight: 500, color: colors.primary }}
            className="mb-3">
            {title}
          </StyledTypography>
        )}

        <div className="mb-3">
          <StyledTypography
            variant="subtitle1"
            sx={{ fontSize: "1.1rem", fontWeight: 500, color: colors.black }}>
            {subTitle}
          </StyledTypography>

          {link && (
            <div>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <StyledTypography variant="subtitle1">
                  {Translate("common:referThis")}
                </StyledTypography>
              </a>
            </div>
          )}
        </div>
        {!isAlertText ? (
          <div className="modalStyling">
            <Renderbuttons />
          </div>
        ) : (
          <div
            className="modalStyling"
            style={{ paddingBottom: 15, paddingRight: 25 }}>
            <Renderbuttons />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default AlertPermission;
