import React from "react";
import Modal from "react-bootstrap/Modal";
import { colors } from "../../../common/Theme/Colors";
const Loader = (props) => {
  const { loading, isListLoader = false, message = "loading..." } = props;
  return isListLoader ? (
    <div>
      <div
        className="loader center"
        style={{ color: !message && colors.white }}></div>
      {message && <p className="loaderAlignment">{message}</p>}
    </div>
  ) : (
    <Modal
      show={loading}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ alignItems: "center", justifyContent: "center" }}>
      <Modal.Body>
        <div className="loader center"></div>
        <div className="loaderAlignment">{message}</div>
      </Modal.Body>
    </Modal>
  );
};
export default Loader;
