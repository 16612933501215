import React from "react";
import Modal from "react-bootstrap/Modal";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const PDFViewer = ({ show, handleClose, files, b64Array }) => {
  return (
    <div>
      <Modal
        style={{ width: "100%", paddingInline: "15%" }}
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable={true}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginLeft: "25px" }}>{files}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {b64Array?.length > 0 && (
            <DocViewer
              documents={b64Array}
              initialActiveDocument={b64Array[0]}
              pluginRenderers={DocViewerRenderers}
              style={{ minHeight: "40vh" }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {(props) => (
            <span>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default PDFViewer;
