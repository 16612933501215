import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const profileCollection = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileData: builder.query({
      query: () => ({
        url: Urls.viewProfile,
        method: Methods.get,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["profile"],
    }),
    viewCompanyDetails: builder.mutation({
      query: (bodyObj) => {
        return {
          url: Urls.companyDetails,
          method: "POST",
          body: bodyObj,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),

    getOfficeLocationListing: builder.mutation({
      query: (requestBody) => {
        return {
          url: Urls.getOfficeLocations,

          method: Methods.post,
          body: requestBody,
        };
      },
    }),
    editofficeLocation: builder.mutation({
      query: (body) => {
        return {
          url: Urls.editOfficeLocations,
          method: Methods.put,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useLazyGetProfileDataQuery,
  useGetOfficeLocationListingMutation,
  useEditofficeLocationMutation,
} = profileCollection;
