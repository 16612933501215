import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  StyledCard,
  StyledTypography,
} from "../../common/Utils/StyledComponents";
import Translate from "../../translation/utils/translateFunction";
import ListView from "../ListView";
import Searchbar from "../common/Searchbar";
import { getSubscriptionColumns } from "./components/column.data";
import { colors } from "../../common/Theme/Colors";
import { useGetSubscriptionListMutation } from "../../request/Subscription";
import { useDebouncedCallback } from "use-debounce";
import { showToast } from "../../common/Utils/ToastMessage";

const SubscriptionListing = ({ fixNavbar }) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPageNo, setLastPageNo] = useState(0);

  const [
    getSubscriptionsApi,
    {
      data: subscriptionData,
      isLoading: isSubscriptionLoading,
      isError: isSubscriptionError,
      error: subscriptionError,
    },
  ] = useGetSubscriptionListMutation();

  const requestBody = useMemo(() => {
    const payload = {
      searchText: searchValue,
      pageNo: currentPage,
    };

    return payload;
  }, [searchValue, currentPage]);

  const debouncedSearch = useDebouncedCallback(() => {
    getSubscriptionsApi(requestBody);
  }, 500);

  useEffect(() => {
    setSubscriptions([]);

    if (requestBody) {
      setIsLoading(true);

      if (requestBody?.searchText?.length) {
        debouncedSearch();
      } else {
        getSubscriptionsApi(requestBody);
      }
    }
    // eslint-disable-next-line
  }, [requestBody]);

  useEffect(() => {
    if (subscriptionData?.data?.nodes) {
      const { nodes, pageInfo } = subscriptionData.data;
      setSubscriptions(nodes);
      setLastPageNo(pageInfo.lastPageNo);
      setIsLoading(false);
    }

    if ((isSubscriptionError, subscriptionError)) {
      showToast({
        message:
          subscriptionError?.data?.message ||
          Translate("common:unknownServerError"),
        type: "error",
      });
      setIsLoading(false);
    }
  }, [subscriptionData, isSubscriptionError, subscriptionError]);

  const handleSearchText = (value) => {
    setCurrentPage(1);
    setSearchValue(value);
  };

  const CustomSrCellRender = (params) => {
    return (
      <StyledTypography component="span" sx={{ color: colors.primary_009 }}>
        {params?.api?.getRowIndexRelativeToVisibleRows(params?.row?._id) +
          1 +
          (currentPage - 1) * 10}
      </StyledTypography>
    );
  };

  const CustomActionCellRender = (params) => {
    return (
      <td>
        <i
          className="m-2 fa fa-eye cursor-pointer manageIconColor"
          onClick={() =>
            navigate("/subscription/view", {
              state: params?.row?._id,
            })
          }
          data-toggle="tooltip"
          title={Translate("common:viewToolTip")}
        ></i>
      </td>
    );
  };
  return (
    <Grid
      item
      xs={12}
      className={`section-body ${fixNavbar ? "marginTop" : ""} `}
    >
      <StyledCard variant="outlined">
        <div className="container-fluid p-4">
          <div className="tab-content">
            <div>
              <div className="card">
                <div className="card-header card-header-spacing">
                  <div className="card-options">
                    <div className="input-group">
                      <Searchbar
                        handleSearchText={handleSearchText}
                        setSearch={setSearchValue}
                      />
                    </div>

                    <div className="header-action pull-right ml-2">
                      <button
                        type="button"
                        className="btn btn-primary py-2"
                        onClick={() => navigate("/subscription/add")}
                      >
                        <i className="fe fe-plus" />
                        <StyledTypography className="mx-1" component="span">
                          {Translate("common:add")}
                        </StyledTypography>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ListView
                    columns={getSubscriptionColumns(
                      CustomSrCellRender,
                      CustomActionCellRender
                    )}
                    rows={subscriptions}
                    autoHeight
                    sortingMode="server"
                    onPaginationChange={setCurrentPage}
                    currentPage={currentPage}
                    showShimmer={isLoading || isSubscriptionLoading}
                    showLoader={isLoading}
                    totalPageCount={lastPageNo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledCard>
    </Grid>
  );
};

export default SubscriptionListing;
