/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React from "react";
import { useLocation } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";

export const Ability = () => {
  const location = useLocation();

  const abilityArray = [
    {
      label: "Dashboard",
      to: "/",
      show: true,
    },
    {
      label: "Notifications",
      to: "/notifications",
      show: true,
    },
    {
      label: "Notifications",
      to: "/notifications/view",
      show: true,
    },
    {
      to: "/client",
      show: true,
      label: "Client listing",
    },
    {
      to: "/client/add",
      show: true,
      label: "Add client",
    },
    {
      to: "/client/details/add",
      show: true,
      label: "Add client details",
    },
    {
      to: "/client/view",
      show: true,
      label: "View client",
    },
    {
      to: "/client/details/view",
      show: true,
      label: "View client details",
    },
    {
      to: "/subscription",
      show: true,
      label: "Subscription listing",
    },
    {
      to: "/subscription/add",
      show: true,
      label: "Add subscription",
    },
    {
      to: "/subscription/view",
      show: true,
      label: "View subscription",
    },
    {
      to: "/subscription/edit",
      show: true,
      label: "Edit subscription",
    },
    {
      label: "Profile",
      to: "/profile",
      show: true,
    },
    {
      label: "Change timezone",
      to: "/settings/changeTimezone",
      show: true,
    },
    {
      label: "Change password",
      to: "/settings/changePassword",
      show: true,
    },
  ];

  return (
    <AuthRoutes
      isAuthorised={abilityArray?.find((item) =>
        item?.show
          ? item?.content
            ? item?.content?.find(
                (subItem) => subItem?.to === location?.pathname
              )
            : item.to === location.pathname
          : false
      )}
    />
  );
};
