import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyGetProfileDataQuery } from "../../request/Account";
import Translate from "../../translation/utils/translateFunction";
import { FormikTextField } from "../FormikForm/component/FormikTextField";
import Loader from "../staff/Owner/LoadingComp";
import { PersonalDetailsSchema } from "./utils";
import { InitialValues } from "./constants";
import { showToast } from "../../common/Utils/ToastMessage";
import FOMImg from "../../assets/images/common/Image1 140_140.png";

const Profile = () => {
  const { fixNavbar } = useSelector((state) => state?.formanagement);
  const formikRef = useRef(null);

  const [isEditable, setIsEditable] = useState(false);
  const { validations } = useSelector((state) => state?.formanagement);

  // For Update profile data
  const [
    getProfileDetails,
    {
      data: profileData,
      isSuccess: isProfileSuccess,
      isLoading: isProfileLoading,
      isError: isProfileError,
      error: profileError,
    },
  ] = useLazyGetProfileDataQuery();

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For Get profile data
  useEffect(() => {
    if (isProfileSuccess && profileData) {
      // eslint-disable-next-line no-unused-expressions
      formikRef?.current?.setValues((prevValues) => ({
        ...prevValues,
        name: profileData?.name,
        workEmail: profileData?.email,
        contactNumber: profileData?.mobile,
        dob: profileData?.dob,
      }));
      setIsEditable(false);
    }

    if (isProfileError && profileError) {
      showToast({
        type: "error",
        message:
          profileError?.data?.message || Translate("common:unknownServerError"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileSuccess, profileData, isProfileError, profileError]);

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      enableReinitialize
      validationSchema={PersonalDetailsSchema}
    >
      {() => {
        return (
          <>
            <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
              <div className="container-fluid">
                <div className="row clearfix">
                  <div className="col-md-12">
                    <div className="card card-profile">
                      <div className="card-body text-center">
                        <img
                          className="card-profile-img rounded-circle"
                          src={FOMImg}
                          alt="ProfileImage"
                          style={{
                            cursor: "pointer",
                          }}
                        />

                        <h4 className="mb-3">{profileData?.name}</h4>
                        <div className="btn-width"></div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-md-12 mt-4"
                      style={{ padding: 0 }}
                    >
                      <div className="card">
                        <div className="card-header">
                          <h4>Personal details</h4>
                        </div>
                        <div className="card-body">
                          <div className="row clearfix">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <FormikTextField
                                  name="name"
                                  label={Translate("account:name")}
                                  placeholder={Translate("account:name")}
                                  autoComplete="off"
                                  disabled={!isEditable}
                                  maxLength={validations?.nameLength.MAX}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <FormikTextField
                                  type="email"
                                  name="workEmail"
                                  label={Translate("account:email")}
                                  placeholder={Translate("account:email")}
                                  disabled={true}
                                  autoComplete="off"
                                  maxLength={validations?.email.MAX}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                <FormikTextField
                                  type="contactNumber"
                                  name="contactNumber"
                                  label={Translate("account:contact")}
                                  placeholder={Translate("account:contact")}
                                  disabled={true}
                                  autoComplete="off"
                                  maxLength={validations?.email.MAX}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Loader loading={isProfileLoading} />
          </>
        );
      }}
    </Formik>
  );
};

export default Profile;
