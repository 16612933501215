import React, { useRef, useEffect } from "react";
import SubscriptionForm from "../components/SubscriptionForm";
import { InitialValues } from "../components/constants";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";
import { AddSubscriptionSchema } from "../components/validators";
import { useAddSubscriptionMutation } from "../../../request/Subscription";

const AddSubscription = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const [
    addSubscriptionApi,
    {
      data: subscriptionData,
      isLoading: isAddSubscriptionLoading,
      isSuccess: isAddSubscriptionSuccess,
      isError: isAddSubscriptionError,
      error: addSubscriptionError,
    },
  ] = useAddSubscriptionMutation();

  useEffect(() => {
    if (isAddSubscriptionSuccess && subscriptionData) {
      if (subscriptionData?.success) {
        showToast({ message: subscriptionData?.message, type: "success" });
        formikRef.current.resetForm();
        navigate("/subscription");
      } else {
        showToast({ message: subscriptionData?.message, type: "error" });
      }
    }

    if (isAddSubscriptionError && addSubscriptionError) {
      const subscriptionError = addSubscriptionError?.data;

      if (!subscriptionError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        subscriptionError.error.length
          ? subscriptionError.error.map((errorItem) => {
              if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                return showToast({ message: errorItem.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem.param,
                errorItem.msg
              );
            })
          : showToast({
              message:
                subscriptionError?.message ||
                Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }
    // eslint-disable-next-line
  }, [
    subscriptionData,
    isAddSubscriptionSuccess,
    isAddSubscriptionError,
    addSubscriptionError,
  ]);

  const onSubmit = (formValues) => {
    const reqObj = {
      name: formValues?.name,
      description: formValues?.description,
      noOfCompanies: formValues?.noOfCompanies,
      amount: formValues?.amount,
      noOfDays: formValues?.noOfDays,
    };

    addSubscriptionApi(reqObj);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddSubscriptionSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <SubscriptionForm
            isAdd
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isLoading={isAddSubscriptionLoading}
          />
        );
      }}
    </Formik>
  );
};

export default AddSubscription;
