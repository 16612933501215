import React, { useEffect, useRef } from "react";
import SubscriptionForm from "../components/SubscriptionForm";
import { InitialValues } from "../components/constants";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import { useGetSubscriptionDetailsMutation } from "../../../request/Subscription";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";

const ViewSubscription = () => {
  const formikRef = useRef(null);
  const { state } = useLocation();

  const [
    getSubscriptionDetails,
    { data: subscriptionData, isSuccess, isLoading, isError, error },
  ] = useGetSubscriptionDetailsMutation();

  useEffect(() => {
    if (state) {
      getSubscriptionDetails(state);
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (isError && error) {
      showToast({
        message: error?.data?.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }

    if (isSuccess && subscriptionData) {
      const { data } = subscriptionData;
      // update formik values
      formikRef.current.setFieldValue("name", data?.name);
      formikRef.current.setFieldValue("description", data?.description);
      formikRef.current.setFieldValue("noOfDays", data?.noOfDays);
      formikRef.current.setFieldValue("noOfCompanies", data?.noOfCompanies);
      formikRef.current.setFieldValue("amount", data?.amount);
    }
  }, [isError, error, isSuccess, subscriptionData]);

  return (
    <Formik initialValues={InitialValues} innerRef={formikRef}>
      {({ values, setFieldValue }) => {
        return (
          <SubscriptionForm
            isView
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            isLoading={isLoading}
            subscriptionData={subscriptionData?.data}
          />
        );
      }}
    </Formik>
  );
};

export default ViewSubscription;
