import { Methods, Urls } from "../../common/Utils/ApiConstants";
import { CreateApi } from "../CreateApi";

const subscriptionClient = CreateApi.injectEndpoints({
  endpoints: (builder) => ({
    addSubscription: builder.mutation({
      query: (body) => {
        return {
          url: Urls.addSubscription,
          method: Methods.post,
          body: body,
        };
      },
    }),
    getSubscriptionDetails: builder.mutation({
      query: (id) => {
        return {
          url: `${Urls.getSubscriptionDetails}${id}`,
          method: Methods.get,
        };
      },
    }),
    getSubscriptionList: builder.mutation({
      query: (body) => {
        return {
          url: Urls.getSubscriptionList,
          method: Methods.post,
          body: body,
        };
      },
    }),
    editSubscription: builder.mutation({
      query: (body) => {
        return {
          url: Urls.editSubscription,
          method: Methods.put,
          body: body,
        };
      },
    }),
  }),
});

export const {
  useAddSubscriptionMutation,
  useGetSubscriptionDetailsMutation,
  useGetSubscriptionListMutation,
  useEditSubscriptionMutation,
} = subscriptionClient;
