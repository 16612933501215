import {
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useField } from "formik";
import React, { memo } from "react";
import { colors } from "../../../common/Theme/Colors";
import {
  FontFamily,
  StyledTypography,
  ErroLabelStyle
} from "../../../common/Utils/StyledComponents";
import IconifyIcon from "../../Icon";

const FormikMultiSelectDropDown = ({
  name,
  label,
  required,
  disabled,
  options,
  onSelect,
  placeholder,
  value,
  // Added one prop to get the value of all selected companies
  onSelectChange,
}) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = { ...meta };
  const { value: fieldValue = value } = field;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    label !== value &&
      helpers.setValue(value === "string" ? value.split(",") : value);
    onSelectChange && onSelectChange(value);
  };

  const getLabelByValue = options.reduce(
    (acc, option) => ({ ...acc, [option?.value]: option?.label }),
    {}
  );

  return (
    <FormControl fullWidth variant="outlined">
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <Select
        id="demo-multiple-name"
        multiple
        value={fieldValue}
        onChange={handleChange}
        size="small"
        error={touched && !!error}
        disabled={disabled}
        style={{
          background: disabled ? "#e5e5e58f" : "",
          color: !fieldValue && colors.grey_003,
        }}
        sx={{ fontFamily: FontFamily }}
        endAdornment={
          !disabled &&
          !required &&
          fieldValue?.length > 0 && (
            <IconButton
              size="small"
              sx={{ marginRight: 1 }}
              onClick={() => {
                onSelect && onSelect([]);
                helpers.setValue([]);
              }}
            >
              <IconifyIcon icon="ion:close-outline" fontSize={25} />
            </IconButton>
          )
        }
        displayEmpty
        renderValue={
          fieldValue && fieldValue?.length
            ? (selected) => (
                <Tooltip
                  title={selected
                    .map((value) => getLabelByValue[value])
                    .join(", ")}
                >
                  <Typography noWrap sx={{ fontFamily: "inherit" }}>
                    {selected
                      .slice(0, 4)
                      .map((value) => getLabelByValue[value])
                      .join(", ")}
                    {selected.length > 4 && "..."}
                  </Typography>
                </Tooltip>
              )
            : () => label || placeholder
        }
      >
        {options?.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            onClick={() => onSelect?.(value, label)}
            style={{ paddingLeft: 10, paddingRight: 10 }}
          >
            {label}
          </MenuItem>
        ))}
      </Select>

      {touched && error && (
        <StyledTypography sx={ErroLabelStyle} component="span">
          {error}
        </StyledTypography>
      )}
    </FormControl>
  );
};

export default memo(FormikMultiSelectDropDown);
