import * as yup from "yup";
import Translate from "../../../translation/utils/translateFunction";

export const AddSubscriptionSchema = () => {
  const schema = yup.object().shape({
    name: yup.string().trim().required(Translate("subscription:nameError")),
    noOfDays: yup
      .string()
      .trim()
      .required(Translate("subscription:noOfDaysError")),
    noOfCompanies: yup
      .number()
      .required(Translate("subscription:noOfCompaniesError")),
    amount: yup.number().required(Translate("subscription:amountError")),
  });
  return schema;
};
