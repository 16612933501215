import * as yup from "yup";

export const getFormSchema = () => {
  const schema = yup.object().shape({
    company: yup.string().trim().required("Name is required"),
    website: yup.string().trim().required("Website is required"),
    contact: yup.string().trim().required("Contact is required"),
  });

  return schema;
};
