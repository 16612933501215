export default {
  contactRepository: 'Contact repository',
  documentRepository: 'Document repository',
  report: 'Report',
  taskReport: 'Task report',
  performanceReport: 'Performance report',
  workloadReport: 'Workload report',
  staffReport: 'Staff report',
  vendorsSuppliers: 'Vendors/suppliers',
  aboutUs: 'About us',
  privacy: 'Privacy',
  termsConditions: 'Terms & condition',
  logout: 'Logout',
};
