export default {
  addVendorSupplier: 'Add vendor/supplier',
  addEmployee: 'Add employee',
  employee: 'Employee',
  vendorSupplier: 'Vendor/supplier',
  managersAssignTo1: 'Name or email of employee',
  managersAssignTo2: 'Name or email of vendor',
  managersAssignTo3: 'Name or email',
  addVendor: 'Add vendor',
  companyName: 'Vendor company name',
  vendorName: 'Vendor name',
  address: 'Company address',
  email: 'Email address',
  nameOfEmployee: 'Name of employee',
  employeeName: 'Employee name',
  emailPlaceholder: 'name@email.com',
  numberPlaceholder: '+00 0000 0000 00',
  nameCompany: 'Company name',
  companyPlaceholder: 'Company name is required',
  upcomingDeadlines: 'Upcoming deadlines',
  dob: 'Date of birth',
  dobError: 'Date of birth is required',
  gender: 'Gender',
};
