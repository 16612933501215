export default {
  forgotPassword: "Forgot password",
  titleText_1: "Provide your email address to reset your password.",
  email: "Email address",
  emailPlaceholder: "Enter your email",
  next: "Next",
  verifyOTP: "Verify OTP",
  titleText_2: "Enter the OTP sent to your email address",
  titleText_3: "Enter the OTP has sent to ",
  titleText_4: ". Verify OTP to login",
  receivedOTP: "Didn’t received OTP?",
  resendOTP: "RESEND OTP",
  verify: "Verify",
  setPassword: "Set password",
  newPassword: "Enter new password",
  confirmPassword: "Confirm new password",
  save: "Save",
  passwordError: "New password is required",
  passwordError1: "New password is not valid",
  beforUnloadTitle: "Are you sure, you want to leave this page?",
  leave: "leave",
};
