import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NotificationTitleBody from "./common/Notification";
import { colors } from "./common/Theme/Colors";
import { StyledTypography } from "./common/Utils/StyledComponents";
import { showToast } from "./common/Utils/ToastMessage";
import AlertPermission from "./components/AlertPermission";
import { Ability } from "./components/Shared/Ability";
import { _getToken, onMessageListener } from "./firebase";
import { setFcmTokenAction } from "./store/Reducer";
import Translate from "./translation/utils/translateFunction";
import UnAuthRoutes from "./components/Shared/UnAuthRoutes";

const App = () => {
  const {
    isDarkMode,
    isBoxLayout,
    isDarkSidebar,
    isIconColor,
    isGradientColor,
    isRtl,
    isFont,
    userData,
    fcmToken: fcmTokenStorage,
  } = useSelector((state) => state.formanagement);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isTokenFound, setTokenFound] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [fcmToken, setFcmToken] = useState("");
  const [notification, setNotification] = useState(null);
  const [showNotificationPopup, setShowNotificationPermission] =
    useState(false);
  useEffect(() => {
    _getToken(setTokenFound, setFcmToken);
  }, []);
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setIsMobileView(true);
      navigate("/");
    } else {
      setIsMobileView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);
  useEffect(() => {
    if (Notification.permission !== "granted") {
      setShowNotificationPermission(true);
      dispatch(setFcmTokenAction(""));
    }
    if (fcmToken.length) {
      dispatch(setFcmTokenAction(fcmToken));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcmToken, fcmTokenStorage]);

  onMessageListener()
    .then((payload) => {
      setNotification(payload?.data);
      // setShow(true);
      // setNotification({
      //   title: payload?.data?.title,
      //   body: payload?.data?.body,
      // });
    })
    .catch((err) => console.log("failed: ", err));

    useEffect(() => {
      if (notification) {
        showToast({
          message: (
            <NotificationTitleBody
              title={notification?.title}
              body={notification?.body}
              detail={notification?.detail}
            />
          ),
          type: "success",
        });
      }
    }, [notification]);
  return (
    <div
      className={`${isDarkMode ? "dark-mode" : ""}${
        isDarkSidebar ? "sidebar_dark" : ""
      } ${isIconColor ? "iconcolor" : ""} ${
        isGradientColor ? "gradient" : ""
      } ${isRtl ? "rtl" : ""} ${isFont ? isFont : ""}${
        isBoxLayout ? "boxlayout" : ""
      }`}
    >
      {userData && userData?.accessToken ? <Ability /> : <UnAuthRoutes />}
      <ToastContainer hideProgressBar rtl={isRtl} autoClose={3000} />
     {/*  <AlertPermission
        subTitle={Translate("common:notificationAlert")}
        link={
          "https://support.google.com/chrome/answer/3220216?sjid=7679138954814274276-AP"
        }
        hideCancel
        show={showNotificationPopup}
        onOk={() => setShowNotificationPermission(false)}
      /> */}
      <Modal
        show={isMobileView}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onBackdropClick={() => {}}
        scrollable={false}
        className="col-span-3 col-md-12"
      >
        <Modal.Body>
          <StyledTypography variant="h6" color={colors.primary} p={2}>
            Sorry, this website is only available on desktop devices.
          </StyledTypography>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default App;
