import Translate from "../../../translation/utils/translateFunction";

export const getSubscriptionColumns = (
  CustomSrCellRender,
  CustomActionCellRender
) => {
  const columns = [
    {
      field: "lineNo",
      headerName: Translate("common:srNoTitle"),
      align: "center",
      headerAlign: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: CustomSrCellRender,
    },
    {
      field: "name",
      headerName: Translate("subscription:name"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.name,
    },
    {
      field: "noOfDays",
      headerName: Translate("subscription:noOfDays"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.noOfDays,
    },
    {
      field: "amount",
      headerName: Translate("subscription:amount"),
      flex: 0.5,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params) => params.row?.amount,
    },
    {
      field: "Action",
      headerName: Translate("common:actionTitle"),
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: CustomActionCellRender,
    },
  ];
  return columns;
};
