export default {
  ok: "Ok",
  cancel: "Cancel",
  save: "Save",
  addMore: "Add More",
  invite: "Invite",
  addButton: "Add",
  share: "Share",
  yes: "Yes",
  apply: "Apply",
  send: "Send",
  saveChanges: "Save changes",
  done: "Done",
  confirm: "Confirm",
  add: "Add",
  errorMessage: "No comments found",
  edit: "Edit",
  delete: "Delete",
  search: "Search",
  searchTask: "Search task",
  selectAll: "Select all",
  takePhoto: "Take a photo",
  uploadGallery: "Upload from gallery",
  copy_Paste: "Copy/paste text",
  accessLogs: "Access logs",
  performance: "Performance",
  rate: "Rate",
  redZone: "Red zone",
  greenZone: "Green zone",
  calendar: "Calendar",
  inbox: "Inbox",
  home: "Home",
  message: "Message",
  manageTask: "Manage Task",
  alert: "Alert",
  imagePreviewAlert: "Only 1 file is allowed.",
  notificationAlert:
    "To receive realtime updates from other users, please enable notification permission. To enable it please refer below link.",
  referThis: "refer this",
  allCompanySelect: "All company selected",
  selectCompany: "Select company",
  unAuthTitle: "Unauthorized Access",
  unAuthsubtitle: "You are not authorized to access this page.",
  unAuthBtn: "Take me home",
  editToolTip: "Edit",
  backToolTip: "Go back",
  viewToolTip: "View",
  deleteToolTip: "Delete",
  searchPlaceholder: "Search something...",
  close: "Close",
  fileAddLimitMsg: "Only 1 file is allowed.",
  dropZoneFileSizeMsg: "You cannot add file size more than 50 MB.",
  fileAddLimitMsg10: "Maximum 10 files are allowed.",
  invalidFileMsg: "Invalid file format",
  unknownServerError: "Something went wrong!",
  continue: "Continue",
  showFilterToolTip: "Show filter",
  clearFilterToolTip: "Clear filter",
  selectCompanyMessage: "Please select at least one company",
  dateRangePlaceholder: "From date - To date",
  removeToolTip: "Remove",
  forManagemnet: "For.Management",
  srNoTitle: "Sr.no",
  actionTitle: "Action",
  nextToolTip: "Next",
  shareToolTip: "Share",
  loadMoreBtnLabel: "Load more",
  apiFailedMessage: "Failed to fetch the data",
  sortByTitle: "Sort by",
  clearAllTitle: "Clear all",
  required: "Required field",
  noDataFoundMessage: "No data found",
  welcome: "Welcome",
  english: "English",
  language: "Language",
  deleteProfileAlert: "Are you sure you want to delete this profile image?",
  account: "Account",
  logOut: "Log-out",
  personalAssistant: "Personal assistant",
  generalManager: "General manager",
  importLabel: "Import",
  uploadFileLabel: "Upload file",
};
