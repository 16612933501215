import React, { useEffect, useRef } from "react";
import ClientDetailForm from "../Components/ClientDetailForm";
import { ClientDetailsInitialValues as InitialValues } from "../Components/constants";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AddClientDetailSchema,
  validateClientDetailFields,
} from "../Components/validators";
import { useAddClientDetailsMutation } from "../../../request/Client";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";

const AddClientDetails = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [
    addClientDetailsApi,
    { data: clientData, isSuccess: isAddSuccess, isLoading, isError, error },
  ] = useAddClientDetailsMutation();

  useEffect(() => {
    if (isAddSuccess && clientData) {
      if (clientData?.success) {
        showToast({ message: clientData?.message, type: "success" });
        formikRef.current.resetForm();
        navigate("/client");
      } else {
        showToast({ message: clientData?.message, type: "error" });
      }
    }

    if (isError && error) {
      const clientError = error?.data;

      if (!clientError) {
        showToast({
          message: Translate("common:unknownServerError"),
          type: "error",
        });
      } else {
        clientError?.error?.length
          ? clientError.error.map((errorItem) => {
              if (!InitialValues.hasOwnProperty(errorItem?.param)) {
                const tempParam = validateClientDetailFields(errorItem?.param);

                if (tempParam) {
                  return formikRef.current.setFieldError(
                    tempParam,
                    errorItem?.msg
                  );
                }

                return showToast({ message: errorItem?.msg, type: "error" });
              }

              return formikRef.current.setFieldError(
                errorItem?.param,
                errorItem?.msg
              );
            })
          : showToast({
              message:
                clientError?.message || Translate("common:unknownServerError"),
              type: "error",
            });
      }
    }
    // eslint-disable-next-line
  }, [isAddSuccess, clientData, isError, error]);

  const onSubmit = (formValues) => {
    if (!state) {
      showToast({
        message: Translate("client:clientIdInvalidError"),
        type: "error",
      });

      return;
    }

    const reqBody = {
      clientId: state,
      s3Details: {
        accessKey: formValues?.accessKey,
        bucketName: formValues?.bucketName,
        region: formValues?.region,
        secretAccessKey: formValues?.secretAccessKey,
      },
      dbDetails: {
        dbName: formValues?.dbName,
        userName: formValues?.userName,
        password: formValues?.password,
        port: formValues?.port,
        loginDb: formValues?.loginDb,
        uri: formValues?.uri,
      },
    };

    addClientDetailsApi(reqBody);
  };

  return (
    <Formik
      initialValues={InitialValues}
      innerRef={formikRef}
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={AddClientDetailSchema}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <ClientDetailForm
            isAdd
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      }}
    </Formik>
  );
};

export default AddClientDetails;
