export default {
  ok: "OK",
  cancel: "Cancel",
  save: "Save",
  addMore: "Add More",
  calendar: "Calendar",
  inbox: "Inbox",
  home: "Home",
  message: "Message",
  manageTask: "Manage Task",
};
