export default {
  todaysEvents: 'Today’s events',
  inProgressTasks: 'In-progress task',
  events: 'Events',
  inProgressTaskHead: 'Inprogress task',
  addOwner: 'Add owner',
  addManager: 'Add manager',
  addCompany: 'Add company',
  selectLocation: 'Select location',
  addTask: 'Add task',
  addSubTask: 'Add subtask',
  eventDetails: 'Event details',
  taskDetails: 'Task details',
  taskProgress: 'Task progress',
  assigned: 'Assigned',
  complete: 'Completed',
  inProgress: 'In-progress',
  resolved: 'Resolved',
  reOpened: 'Re-open',
  overdue: 'Overdue',
  task: 'Task',
  subTask: 'Subtask',
  alertEmployee: 'Are you sure want to delete this employee?',
  alertVendor: 'Are you sure want to delete this vendor?',
  custom: 'Custom',
  dropdownPlaceholder: 'Select filter',
  graphStyle: 'Graph style',
  colour: 'Colour',
  addPersonalAssistant: "Add personal assistant"
};
