import React, { useEffect, useRef } from "react";
import ClientDetailForm from "../Components/ClientDetailForm";
import { ClientDetailsInitialValues as InitialValues } from "../Components/constants";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import { useGetClientDetailsDataMutation } from "../../../request/Client";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";

const ViewClientDetails = () => {
  const formikRef = useRef(null);
  const { state } = useLocation();

  const [
    getClientDetails,
    { data: clientData, isSuccess, isLoading, isError, error },
  ] = useGetClientDetailsDataMutation();

  useEffect(() => {
    if (state) {
      getClientDetails(state);
    }
  }, [state]);

  useEffect(() => {
    if (isError && error) {
      showToast({
        message: error?.data?.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }

    if (isSuccess && clientData) {
      const { s3Details, dbDetails } = clientData?.data;

      // update formik values
      formikRef.current.setFieldValue("accessKey", s3Details?.accessKey);
      formikRef.current.setFieldValue("bucketName", s3Details?.bucketName);
      formikRef.current.setFieldValue("region", s3Details?.region);
      formikRef.current.setFieldValue(
        "secretAccessKey",
        s3Details?.secretAccessKey
      );
      formikRef.current.setFieldValue("dbName", dbDetails?.dbName);
      formikRef.current.setFieldValue("userName", dbDetails?.userName);
      formikRef.current.setFieldValue("password", dbDetails?.password);
      formikRef.current.setFieldValue("port", dbDetails?.port);
      formikRef.current.setFieldValue("loginDb", dbDetails?.loginDb);
      formikRef.current.setFieldValue("uri", dbDetails?.uri);
    }
  }, [isError, error, isSuccess, clientData]);

  return (
    <Formik initialValues={InitialValues} innerRef={formikRef} validateOnMount>
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <ClientDetailForm
            isView
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      }}
    </Formik>
  );
};

export default ViewClientDetails;
