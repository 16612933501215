import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import React from "react";

const ListShimmer = () => {
  return (
    <Stack style={{ padding: 15 }} spacing={1}>
      <Skeleton variant="rectangular" width={"100%"} height={40} />
      <Stack style={{ marginTop: 15, marginBottom: 15 }}>
        {[...Array(12)].map((item, index) => (
          <Stack key={index} style={{ flexDirection: "row", marginTop: 15 }}>
            <Skeleton variant="rectangular" width={"10%"} height={20} />
            <Skeleton
              style={{ marginLeft: 10 }}
              variant="rectangular"
              width={"10%"}
              height={20}
            />
            <Skeleton
              style={{ marginLeft: 10 }}
              variant="rectangular"
              width={"10%"}
              height={20}
            />
            <Skeleton
              style={{ marginLeft: 10 }}
              variant="rectangular"
              width={"10%"}
              height={20}
            />
            <Skeleton
              style={{ marginLeft: 10 }}
              variant="rectangular"
              width={"10%"}
              height={20}
            />
            <Skeleton
              style={{ marginLeft: 10 }}
              variant="rectangular"
              width={"10%"}
              height={20}
            />
            <Skeleton
              style={{ marginLeft: 10 }}
              variant="rectangular"
              width={"10%"}
              height={20}
            />
            <Skeleton
              style={{ marginLeft: 10 }}
              variant="rectangular"
              width={"10%"}
              height={20}
            />
            <Skeleton
              style={{ marginLeft: 10 }}
              variant="rectangular"
              width={"15%"}
              height={20}
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
export default ListShimmer;
