import { Stack } from "@mui/material";
import React from "react";
import DateTimePicker from "react-datetime-picker";
import { StyledTypography } from "../../common/Utils/StyledComponents";
const Datepicker = ({
  className = "form-control",
  dayPlaceholder = "DD",
  monthPlaceholder = "MM",
  yearPlaceholder = "YYYY",
  hourPlaceholder = "HH",
  minutePlaceholder = "MM",
  format = "yyyy-MM-dd",
  onChange,
  maxDate = new Date("9999-12-31"),
  minDate,
  containerStyle = "",
  label,
  selected,
  defaultDate,
  clearIcon = null,
  value,
  touched,
  error,
  RenderRightContainer,
  onShow,
  show,
  required,
  isRowError = false,
  disabled,
  ...props
}) => {
  const handleChange = (date) => {
    onChange(date);
  };
  return (
    <Stack>
      <StyledTypography component="span" className="labelStyling">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <DateTimePicker
        {...props}
        className={className}
        dayPlaceholder={dayPlaceholder}
        yearPlaceholder={yearPlaceholder}
        monthPlaceholder={monthPlaceholder}
        hourPlaceholder={hourPlaceholder}
        minutePlaceholder={minutePlaceholder}
        format={format}
        value={value}
        onChange={handleChange}
        maxDate={maxDate}
        clearIcon={clearIcon}
        minDate={minDate}
        isClockOpen={show}
        disableClock={disabled}
        disabled={disabled}
      />
      {RenderRightContainer && (
        <div
          className={`iconStyling ${
            (touched && error) || isRowError
              ? "rightIconStyleError"
              : "rightIconStyle"
          }`}
          onClick={() => onShow?.(!show)}>
          {RenderRightContainer}
        </div>
      )}
      {touched && error && (
        <StyledTypography sx={{ color: "red", fontSize: 11 }} component="span">
          {error}
        </StyledTypography>
      )}
    </Stack>
  );
};

export default Datepicker;
