export default {
  skip: "Skip",
  walkthrough: "Lorem Ipsum content creator, lorem ipsum",
  enablePermission: "Enable permission",
  contacts: "Contacts",
  storage: "Storage",
  camera: "Camera",
  gallery: "Gallery",
  text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id purus massa id aenean quisque placerat id etiam. Maecenas aliquet maecenas ac nunc, vulputate faucibus risus ipsum cursus. Bibendum libero vitae praesent neque, ac phasellus. Maecenas diam sem pretium eget sollicitudin.",
  allow: "Continue",
  welcome: "Welcome",
  signIn: "Sign In",
  email: "Email",
  emailPlaceholder: "Enter your email",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  forgotPassword: "Forgot password?",
  login: "Login",
  emailError: "Email is required",
  emailError1: "Email is not valid",
  emailError2: "Invalid credentials",
  mobileError1: "Mobile no. is required",
  mobileError2: "Mobile no. is not valid",
  passwordError: "Password is required",
  passwordError1: "Password is not valid",
  termsAndConditions1: "By clicking Login you agree to our",
  termsAndConditions2: "Terms of service",
  termsAndConditions3: "and",
  termsAndConditions4: "Privacy policy",
  termsAndConditions5: "By clicking Proceed you agree to our",
  or: "OR",
  loginWithOTP: "Login with OTP",
  selectLanguage: "Select language",
  proceed: "Proceed",
  mobileNo: "Mobile no.",
  mobileNoPlaceholder: "Enter your mobile no.",
  loginTo: "Welcome",
  otp: "Login with OTP",
  emailLogin: "Login with email",
  consentTitle: "You are already logged in!",
  consentDesc1: "You have already using this app on device",
  consentDesc2:
    ", would you like to stay logged in with same device or continue with this device? If you choose to continue you will be logged out from the other device.",
};
