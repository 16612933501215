import { useField } from "formik";
import React from "react";
import Datepicker from "../../common/datepicker";

const FormikDatePicker = ({ name, onChange, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = { ...meta };

  const { value } = field;
  const dateValue = props.isprofile ? new Date(value) : value;
  return (
    <Datepicker
      {...props}
      value={dateValue}
      className={
        touched && error
          ? `form-control dateFieldHeight paddingStyle ${
              props.disabled ? "disableStyling dateDisable" : ""
            } is-invalid`
          : `form-control dateFieldHeight paddingStyle ${
              props.disabled ? "disableStyling dateDisable" : ""
            }`
      }
      isError={touched && error !== undefined}
      touched={touched}
      error={error}
      disable={props?.disabled}
      onChange={(textValue) => {
        if (textValue) {
          helpers.setValue(textValue);
          onChange(textValue);
        } else {
          helpers.setValue("");
          onChange("");
        }
      }}
      isRowError={props?.isRowError}
    />
  );
};

export default FormikDatePicker;
