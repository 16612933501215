export const InitialValues = {
  name: "",
  website: "",
  contact: "",
  countryCode: "+91",
  officeTelephone: "",
  address: "",
  landmark: "",
  country: "",
  state: "",
  city: "",
  zipcode: "",
  lat: "",
  lng: "",
  mobileShortCode: "IN",
};

export const companyUrl = [
  "www.flipkart.com",
  "www.google.com",
  "www.amazon.in",
  "www.Myntra.com",
];
