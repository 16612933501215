import React from "react";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { colors } from "../../../../common/Theme/Colors";

const CountrySelect = ({
  value,
  className,
  onChange,
  label,
  flags,
  shortCodeChange,
  countryCode,
  disabled,
  ...props
}) => {
  return (
    <select
      {...props}
      flags={flags}
      disabled={disabled}
      value={countryCode || value}
      style={{
        color: colors.black_002,
        fontWeight: "500",
        background: disabled ? "#e5e5e58f" : "",
      }}
      className={className}
      onChange={(countrySelect) => {
        shortCodeChange(countrySelect?.target?.value);
        onChange(`+${getCountryCallingCode(countrySelect.target.value)}`);
      }}
    >
      {getCountries().map((country) => (
        <option key={country} value={country}>
          {`+${getCountryCallingCode(country)}`}
        </option>
      ))}
    </select>
  );
};

export default CountrySelect;
