import React, { useEffect, useRef } from "react";
import ClientForm from "../Components/ClientForm";
import { InitialValues } from "../Components/constants";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import { useGetClientDataMutation } from "../../../request/Client";
import { showToast } from "../../../common/Utils/ToastMessage";
import Translate from "../../../translation/utils/translateFunction";
import { formatDateYYYYMMDD } from "../../../common/Utils/DateFormater";

const ViewClient = () => {
  const formikRef = useRef(null);
  const { state } = useLocation();

  const [
    getClientData,
    { data: clientData, isSuccess, isLoading, isError, error },
  ] = useGetClientDataMutation();

  useEffect(() => {
    if (state) {
      getClientData(state);
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (isError && error) {
      showToast({
        message: error?.data?.message || Translate("common:unknownServerError"),
        type: "error",
      });
    }

    if (isSuccess && clientData) {
      const { data } = clientData;
      // update formik values
      formikRef.current.setFieldValue("name", data?.name);
      formikRef.current.setFieldValue("designation", data?.designation);
      formikRef.current.setFieldValue("mobile", data?.mobileWithCountryCode);
      formikRef.current.setFieldValue("email", data?.email);
      formikRef.current.setFieldValue("resFlat", data?.residenceAddress.flat);
      formikRef.current.setFieldValue("resState", data?.residenceAddress.state);
      formikRef.current.setFieldValue(
        "resCountry",
        data?.residenceAddress.country
      );
      formikRef.current.setFieldValue("resCity", data?.residenceAddress.city);
      formikRef.current.setFieldValue(
        "resZipCode",
        data?.residenceAddress.zipCode
      );
      formikRef.current.setFieldValue(
        "resAddress",
        data?.residenceAddress.address
      );
      formikRef.current.setFieldValue(
        "resLat",
        data?.residenceAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "resLng",
        data?.residenceAddress.latlong.longitude
      );
      formikRef.current.setFieldValue(
        "companyName",
        data?.companyDetails?.companyName
      );
      formikRef.current.setFieldValue("website", data?.companyDetails?.website);
      formikRef.current.setFieldValue(
        "companyNumber",
        data?.companyDetails?.companyNumber
      );
      formikRef.current.setFieldValue(
        "companyExtension",
        data?.companyDetails?.companyExtension
      );
      formikRef.current.setFieldValue(
        "hrMobile",
        data?.hrMobileWithCountryCode
      );
      formikRef.current.setFieldValue(
        "companyFlat",
        data?.companyId?.officeAddress.flat
      );
      formikRef.current.setFieldValue(
        "companyState",
        data?.companyId?.officeAddress.state
      );
      formikRef.current.setFieldValue(
        "companyCountry",
        data?.companyId?.officeAddress.country
      );
      formikRef.current.setFieldValue(
        "companyCity",
        data?.companyId?.officeAddress.city
      );
      formikRef.current.setFieldValue(
        "companyZipCode",
        data?.companyId?.officeAddress.zipcode
      );
      formikRef.current.setFieldValue(
        "companyAddress",
        data?.companyId?.officeAddress.address
      );
      formikRef.current.setFieldValue(
        "companyLat",
        data?.companyId?.officeAddress.latlong.latitude
      );
      formikRef.current.setFieldValue(
        "companyLng",
        data?.companyId?.officeAddress.latlong.longitude
      );
      formikRef.current.setFieldValue(
        "startDate",
        new Date(formatDateYYYYMMDD(data?.startDate))
      );
      formikRef.current.setFieldValue(
        "subscriptionType",
        data?.subscriptionId?._id
      );

      const domains = data?.companyDetails?.domains
        .map((item) => item)
        .join(", ");

      formikRef.current.setFieldValue("domainUrl", domains);
    }
  }, [isError, error, isSuccess, clientData]);

  return (
    <Formik initialValues={InitialValues} innerRef={formikRef} validateOnMount>
      {({ values, setFieldValue, handleSubmit }) => {
        return (
          <ClientForm
            isView
            formikRef={formikRef}
            values={values}
            setFieldValue={setFieldValue}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      }}
    </Formik>
  );
};

export default ViewClient;
