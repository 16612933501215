import React, { memo, useCallback, useEffect, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import Translate from "../../translation/utils/translateFunction";
import PDFViewer from "../common/PdfViewer";

const CommonImage = ({
  files = [],
  removeFile,
  images,
  icon,
  disabled,
  commentIcon = false,
  containerStyle,
  isCompany = false,
  disableViewer,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [isCompanyImage, setIsCompanyImage] = useState();

  useEffect(() => {
    if (isCompany) {
      if (files[0].path) {
        files.map((file) => setIsCompanyImage(file?.type?.split("/")[1]));
      } else {
        const url = new URL(files[0]);
        const path = url.pathname;
        setIsCompanyImage(
          path.substring(path.lastIndexOf(".") + 1).toLocaleLowerCase()
        );
      }
    }
    // eslint-disable-next-line
  }, [files]);

  const [b64Array, setb64] = useState();
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const openPdf = (file) => {
    setShow(true);
    setb64([
      {
        uri: file?.b64 || file?.url,
        fileName: file?.taskFileName || file?.path,
        fileType: file?.type,
        ...file,
      },
    ]);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const validateDocument = (file) => {
    const isDoc = file?.type.startsWith("application/");

    if (!isDoc) return false;

    const docExt = file?.taskFileExt
      ? file?.taskFileExt
      : file?.path?.split(".").pop().toLocaleLowerCase();

    return ["xls", "xlsx", "pdf"].includes(docExt);
  };

  const storedFiles = () => {
    return files.map((file, index) => {
      const imageExtensions = ["png", "jpg", "jpeg", "gif", "heic", "heif"];
      const fileExtension = file?.type?.split("/")[1];

      if (files.length <= 10) {
        return (
          <div
            key={file?.name || file?.url || index}
            style={{ marginRight: "10px" }}
          >
            <div
              style={containerStyle}
              onClick={() => {
                if (validateDocument(file)) {
                  openPdf(file);
                } else {
                  openImageViewer(index);
                }
              }}
              className={`${
                disabled ? "viewDocumentAttchment" : "heightImageli mb-2"
              } cursor-pointer`}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {file.type === "application/pdf" ? (
                  <i
                    className={
                      disabled
                        ? "fa fa-file-pdf-o  imageColor cursor-pointer"
                        : "fa fa-file-pdf-o imageIconColor cursor-pointer"
                    }
                  />
                ) : commentIcon ? (
                  <i className="fe fe-paperclip EyeIconColor pr-2 cursor-pointer" />
                ) : (
                  <i
                    className={
                      disabled
                        ? "fa fa-photo imageColor cursor-pointer"
                        : "fa fa-photo imageIconColor cursor-pointer"
                    }
                  />
                )}

                {isCompany === false ? (
                  imageExtensions?.includes(fileExtension) ? (
                    <span className={disabled ? "pathName" : "fileNameColor"}>
                      {`${Translate("addOwner:image")}.${fileExtension}`}
                    </span>
                  ) : (
                    <span className={disabled ? "pathName" : "fileNameColor"}>
                      {`${Translate("addOwner:file")}.${fileExtension}`}
                    </span>
                  )
                ) : (
                  <span className={disabled ? "pathName" : "fileNameColor"}>
                    {`${Translate("addOwner:image")}.${isCompanyImage}`}
                  </span>
                )}
              </div>
              {icon ? (
                <i className="fe fe-eye icon EyeIconColor cursor-pointer"></i>
              ) : (
                <i
                  className="fe fe-x imageIconColor cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(file);
                  }}
                />
              )}
            </div>
          </div>
        );
      }
    });
  };

  return (
    <>
      {storedFiles()}
      <div style={{ zIndex: 1100 }}>
        {isViewerOpen && !disableViewer && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            closeOnClickOutside
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
          />
        )}
      </div>
      <PDFViewer
        b64Array={b64Array}
        files={b64Array?.length && b64Array[0]?.fileName}
        handleClose={handleClose}
        show={show}
      />
    </>
  );
};
export default memo(CommonImage);
