export const Methods = {
  post: "POST",
  get: "GET",
  delete: "DELETE",
  put: "PUT",
};

export const Urls = {
  login: "superclient/login",
  loginGetOtp: "users/resendmobileotp/",
  verifyOtp: "users/verifyemailotp",
  refreshToken: "users/refreshtoken",
  masterDefault: "master/defaultvalues",
  timeZone: "/master/timezonelist",
  logOut: "users/logout/",

  getCompanyList: "company/companylist",
  getCompanyUrl: "company/companyUrl",
  getCompanyData: "company/add",
  getStaffOwnerList: "users/owner/list",
  viewProfile: "/superclient/profile",
  companyDetails: "company/companydetails",

  viewStaffOwner: "/users/owner/view/",
  editStaffOwner: "/users/owner/edit/",
  addStaffOwner: "users/owner/add",
  deleteStaffOwner: "/users/owner/delete/",

  getOfficeLocations: "company/officeLocations",
  editOfficeLocations: "company/edit",
  addClient: "client/add",
  getClients: "superclient/client/list",
  addClientDetails: "superclient/clientdetails/add",
  updateClientStatus: "superclient/client/changestatus",
  importCompanyData: "superclient/company/import",
  importUserData: "superclient/users/import",
  getCompanyListByClient: "superclient/client/companyList/",
  getClientData: "client/view/",
  getClientDetailsData: "superclient/clientdetails/view/",
  changePassword: "superclient/changepassword",
  addSubscription: "subscription/add",
  getSubscriptionDetails: "subscription/view/",
  getSubscriptionList: "subscription/list",
  editSubscription: "subscription/edit",
  getSubscriptionTypes: "subscription/dropdown",
};

const OWNER = "Owner",
  GENERAL_MANAGER = "GM",
  MANAGER = "Manager",
  EMPLOYEE = "Employee",
  VENDOR = "Vendor",
  PA = "Personal assistant",
  GM = "General manager",
  SELF = "Self";

export const userType = {
  Owner: OWNER,
  GeneralManager: GENERAL_MANAGER,
  Manager: MANAGER,
  Employee: EMPLOYEE,
  Vendor: VENDOR,
};

export const userTypes = [OWNER, GENERAL_MANAGER, MANAGER, EMPLOYEE, VENDOR];

export const eventActionType = {
  CREATE_EXPIRY: "Create-expiry",
  SEND_MESSAGE: "Send-Message",
  SHARE_CONTACT: "Contact-Share",
  ADD_GROUP_MEMBER: "Add-Member",
  REMOVE_GROUP_MEMBER: "Remove-Member",
  SHARE_DOCUMENT: "Document-Share",
  TASK_REMINDER: "Task-reminder",
  VOICE_NOTE_SHARE: "VoiceNote-Share",
  CONTACT_EDITED: "Contact-Edited",
};

// for filter
export const userTypeOptions = {
  SELF,
  OWNER,
  PA,
  GM,
  MANAGER,
  EMPLOYEE,
  VENDOR,
};

export const Regex = {
  passwordRegexWithoutSequence:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})/,
  mentionWithDelimiter: /(@\[([A-Za-z0-9 ]+)\]\((\w+)\))/g,
  mentionNoDelimiter: /@\[([A-Za-z0-9 ]+)\]\((\w+)\)/g,
};

export const countryCode = {
  JORDAN: "962",
  INDIA: "91",
};

export const countryShortCode = {
  JORDAN: "JO",
  INDIA: "IN",
};
