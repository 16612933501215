import React, { useRef } from "react";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import { useField } from "formik";
import { colors } from "../../../common/Theme/Colors";
import {
  FontFamily,
  StyledTypography,
  ErroLabelStyle
} from "../../../common/Utils/StyledComponents";
import IconifyIcon from "../../Icon";
import Translate from "../../../translation/utils/translateFunction";
import { useSelector } from "react-redux";

export const FormikDropdownPicker = ({
  name,
  label,
  required,
  disabled,
  displayEmpty,
  options,
  onSelect,
  placeholder,
  value,
  selectedValue,
  showCloseIcon = true,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = { ...meta };
  // eslint-disable-next-line no-unused-vars
  const { value: fieldValue = value } = field;
  const selectRef = useRef(null);
  const { isRtl } = useSelector((state) => state.formanagement);
  return (
    <FormControl fullWidth variant="outlined">
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <Select
        {...field}
        {...props}
        ref={selectRef}
        name={name}
        size="small"
        error={touched && !!error}
        disabled={disabled}
        style={{
          background: disabled ? "#e5e5e58f" : "",
          color: !fieldValue && colors.grey_003,
          whiteSpace: "normal",
        }}
        value={fieldValue}
        onChange={(e) => {
          label !== e.target.value && helpers.setValue(e.target.value);
        }}
        sx={{ fontFamily: FontFamily }}
        endAdornment={
          !disabled &&
          !required &&
          fieldValue &&
          showCloseIcon && (
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                right: !isRtl && "24px",
                left: isRtl && "10px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 9,
              }}
              onClick={() => {
                onSelect && onSelect("");
                helpers.setValue("");
              }}>
              <IconifyIcon icon="ion:close-outline" fontSize={25} />
            </IconButton>
          )
        }
        displayEmpty
        renderValue={!!fieldValue ? undefined : () => placeholder || label}>
        {options?.length ? (
          options?.map(({ value, label }) => (
            <MenuItem
              key={value}
              value={value}
              onClick={() => onSelect?.(value, label)}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                whiteSpace: "unset",
                wordBreak: "break-all",
                textOverflow: "ellipsis",
                fontFamily: FontFamily,
              }}>
              {label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{Translate("common:noDataFoundMessage")}</MenuItem>
        )}
      </Select>

      {touched && error && (
        <StyledTypography sx={ErroLabelStyle} component="span">
          {error}
        </StyledTypography>
      )}
    </FormControl>
  );
};
