import React, { useState, useRef } from "react";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/material";
import {
  StyledTextField,
  StyledTypography,
} from "../../../common/Utils/StyledComponents";
import IconifyIcon from "../../Icon";
import { useField } from "formik";
import { disableColor } from "./FormikNumberField";

const FormikAddTagInput = ({
  name,
  label,
  required,
  disabled,
  maxLength,
  placeholder,
  onAdd,
}) => {
  const inputRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [field, meta, helpers] = useField(name);
  const { error, touched } = { ...meta };

  const handleAddTag = (event) => {
    if (event.key === "Enter" && event.target.value.trim()) {
      updateData(event.target.value);
      event.target.value = "";
    }
  };

  const handleDeleteTag = (tag) => {
    const removedTags = tags.filter((t) => t !== tag);
    onAdd(removedTags);
    setTags(removedTags);
  };

  const handleAddTagByClick = () => {
    const inputValue = inputRef?.current?.value?.trim();
    inputValue && updateData(inputValue);
  };

  const updateData = (val) => {
    const temptags = [...tags, val];
    onAdd(temptags);
    setTags(temptags);
    helpers.setValue("");
  };

  return (
    <Stack>
      <StyledTypography className="labelStyling" component="span">
        {label}
        {required && (
          <StyledTypography sx={{ color: "red" }} component="span">
            {" *"}
          </StyledTypography>
        )}
      </StyledTypography>
      <StyledTextField
        {...field}
        inputRef={inputRef}
        sx={disabled ? disableColor : null}
        inputProps={{
          style: {
            fontFamily: "Montserrat,sans-serif",
          },
          maxLength: maxLength,
        }}
        onKeyDown={handleAddTag}
        disabled={disabled}
        placeholder={placeholder}
        variant="outlined"
        size="small"
        autoComplete="off"
        error={touched && error !== undefined}
        InputProps={{
          endAdornment: !disabled && (
            <IconButton onClick={handleAddTagByClick}>
              <IconifyIcon icon="material-symbols:add" />
            </IconButton>
          ),
        }}
      />
      {tags?.length > 0 && (
        <Stack
          direction="row"
          spacing={1}
          mt={1}
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="center"
        >
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleDeleteTag(tag)}
            ></Chip>
          ))}
        </Stack>
      )}
      {touched && error && (
        <StyledTypography sx={{ color: "red", fontSize: 11 }} component="span">
          {error}
        </StyledTypography>
      )}
    </Stack>
  );
};

export default FormikAddTagInput;
